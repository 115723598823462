import APIService from './APIService'
import Configuration from './ConfigurationService'

class UserProfileService {
  constructor () {
    this.instance = APIService.create(Configuration.UserProfileApiUrl)
  }

  promise = null;

  getIdentities () {
    if (this.promise) {
      return this.promise
    }

    this.promise = this.instance.get('api/v3/userprofile/identities').then((response) => response.data)
    return this.promise
  }
}

export let userProfileService = new UserProfileService()
