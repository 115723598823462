import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDataGrid from 'react-data-grid'
import { studentService } from '../../../../../../Services/StudentService'
import LoDash from 'lodash'

class StudentGrid extends Component {
  constructor (props) {
    super(props)

    this._columns = this._columns = [
      {
        key: 'name',
        name: 'Name'
      },
      {
        key: 'currentYearGroup',
        name: 'Year',
        sortable: true
      },
      {
        key: 'currentRegGroup',
        name: 'Reg',
        sortable: true
      }
    ]

    this.selectedStudents = props.selectedStudents || []
    this._onRowClick = this._onRowClick.bind(this)
  }

    state = {
      selectedIndexes: [],
      students: []
    }

    async componentDidMount () {
      let students = await studentService.all()

      students = students.hwSelect(x => {
        return {
          id: x.Id,
          name: x.DisplayForename + ' ' + x.DisplaySurname,
          surnameForOrdering: x.DisplaySurname,
          forenameForOrdering: x.DisplayForename,
          currentYearGroup: x.CurrentYearGroup,
          currentRegGroup: x.CurrentRegGroup
        }
      })

      students = LoDash.orderBy(students, ['surnameForOrdering', 'forenameForOrdering'])

      this.setState({ students: students, allStudents: students })

      this._setSelectedIndexesFromSelectedStudents(this.selectedStudents, students)

      let areAllStudentsSelected = ((this.state.selectedIndexes.length === this.state.students.length) && this.state.selectedIndexes.length > 0)
      if (areAllStudentsSelected) {
        // Not a great solution, however its difficult to do in any other way
        let allCheck = document.getElementById('select-all-checkbox')
        if (allCheck) {
          allCheck.checked = true
        }
      }
    }

    componentWillReceiveProps (props) {
      if (props.selectedStudents.length === 0) {
        this.selectedStudents = []
        this.setState({ selectedIndexes: [] })
      }

      if (props.searchFilter !== this.state.searchFilter) {
        this._filterStudentsBySearchFilter(props.searchFilter)
      }
    }

    _filterStudentsBySearchFilter = (searchFilter) => {
      if (searchFilter == null) {
        this.setState({ students: this.state.allStudents })
        return
      }
      let filteredStudents = this.state.allStudents.hwWhere((x) => {
        let regGroup = x.currentRegGroup || ''
        return x.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1 || regGroup.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
      })
      this.setState({ students: filteredStudents })
      this._setSelectedIndexesFromSelectedStudents(this.selectedStudents, filteredStudents)
    };

    _triggerSelectionChange = () => {
      this.props.onSelectionChanged(this.selectedStudents)
    }

    _rowGetter = (i) => {
      return this.state.students[i]
    };

    _onRowClick = (rowIdx) => {
      let row = this.state.students[rowIdx]
      if (!row) {
        return
      }

      if (this.selectedStudents.hwContains(row.id)) {
        this.selectedStudents.hwRemove(row.id)
      } else {
        this.selectedStudents.push(row.id)
      }
      this._setSelectedIndexesFromSelectedStudents(this.selectedStudents, this.state.students)
      this._triggerSelectionChange()
    }
    _onRowsSelected = (rows) => {
      for (let i = 0; i < rows.length; i++) {
        this.selectedStudents.push(this.state.students[rows[i].rowIdx].id)
      }
      this._setSelectedIndexesFromSelectedStudents(this.selectedStudents, this.state.students)
      this._triggerSelectionChange()
    };

    _onRowsDeselected = (rows) => {
      for (let i = 0; i < rows.length; i++) {
        this.selectedStudents.hwRemove(this.state.students[rows[i].rowIdx].id)
      }
      this._setSelectedIndexesFromSelectedStudents(this.selectedStudents, this.state.students)
      this._triggerSelectionChange()
    };

    _setSelectedIndexesFromSelectedStudents (selectedStudents, filteredStudents) {
      let selectedIndexes = []
      for (let i = 0; i < filteredStudents.length; i++) {
        let student = filteredStudents[i]
        if (selectedStudents.hwContains(student.id)) {
          selectedIndexes.push(i)
        }
      }

      this.setState({ selectedIndexes: selectedIndexes })
    }

    _handleGridSort = (sortColumn, sortDirection) => {
      const comparer = (a, b) => {
        // use name as a secondary sort
        let aName = a['name']
        let bName = b['name']

        a = a[sortColumn]
        b = b[sortColumn]

        if (a === null && b !== null) {
          return 1
        } else if (b === null && a !== null) {
          return -1
        } else if (a === null && b === null) {
          return (aName > bName) ? 1 : -1
        }

        a = isNaN(a) ? (a || '').padStart(10, '0') : Number(a)
        b = isNaN(b) ? (b || '').padStart(10, '0') : Number(b)

        let r = (a > b) ? 1 : -1
        if (a === b) {
          return (aName > bName) ? 1 : -1
        }

        return sortDirection === 'DESC' ? r * -1 : r
      }

      const rows = sortDirection === 'NONE' ? this.state.students.slice(0) : this.state.students.sort(comparer)
      this.setState({ rows })
      this._setSelectedIndexesFromSelectedStudents(this.selectedStudents, rows)
    }

    render () {
      if (this.state.allStudents && this.state.allStudents.length) {
        return (
            <div className="student-selector-popout-grid-container">
                <ReactDataGrid
                    columns={this._columns}
                    rowGetter={this._rowGetter}
                    rowsCount={this.state.students.length}
                    headerRowHeight={30}
                    minHeight={260}
                    rowHeight={26}
                    onGridSort={this._handleGridSort}
                    onRowClick={this._onRowClick}
                    rowSelection={{
                    showCheckbox: true,
                    enableShiftSelect: true,
                    onRowsSelected: this._onRowsSelected,
                    onRowsDeselected: this._onRowsDeselected,
                    selectBy: {
                      indexes: this.state.selectedIndexes
                    }
                  }}
                />
            </div>
        )
      }

      return null
    }
}

PropTypes.props = {
  selectedStudents: PropTypes.array,
  onSelectionChanged: PropTypes.func,
  searchFilter: PropTypes.string
}

export default StudentGrid
