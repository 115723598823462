import React, { Component } from 'react'
import PropTypes from 'prop-types'

import '../homework-widget.scss'
import Dropster from '../../../Common/Dropster'
import { HomeworkDashboardService } from '../../../../Services/Homework/Bundle'
import { HomeworkTotalsDimensions, DashboardTimeFrame } from '../../../../Services/Homework/Enums'

import { DropsterDimensions, DropsterTimeframes, Chart, Loading, Error, ExpandButton, ChartHelp } from '../widgetComponents'
import ExportButton from '../ExportButton'

class HandInStatusByDimension extends Component {
    homeworkDashboardService = new HomeworkDashboardService()

    state = {
        options: {
            credits: {
              enabled: false
            },
            chart: {
                height: '400px',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'bar'
            },
            colors: [
                '#90ED7D',
                '#f7a35c',
                '#f45b5b',
                '#7cb5ec'
                ],
            title: {
                 text: null
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                title: '',
                allowDecimals: false

            },
            tooltip: {
                 headerFormat: '<b>{point.key}</b> <br/>',
                 pointFormat: '<b>{point.percentage:.1f}%</b> <br/> <b>{point.y}</b> in total'
            },
            legend: {
                reversed: false
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: '',
                data: null
            }]

        },
        isLoading: true,
        showChart: false,
        hasErrored: false,
        isExpanded: false,
        total: 0
    }

currentTimeFrame = DashboardTimeFrame.THIS_TERM
currentDimension = HomeworkTotalsDimensions.SUBJECT

  componentDidMount () {
    this._getData(this.currentTimeFrame, this.currentDimension)
  }
  componentWillUnmount () {
    this.homeworkDashboardService.cancelAll()
  }

  _getData (timeframe, dimension) {
      this.setState({
        isLoading: true,
        showChart: false,
        hasErrored: false
      })

    this.homeworkDashboardService.getHandInStatusByDimension(timeframe, dimension).then(data => {
        let options = this.state.options

        let handedInSeries = []
        let handedInLateSeries = []
        let overDueSeries = []
        let dueSeries = []

        let dimensions = []
        for (let i = 0; i < data.length; i++) {
            (function () {
                let thing = i
                let item = data[thing]

                dimensions.push(item.Name || 'Unknown')
                handedInSeries.push(item.HandedInOnTime)
                handedInLateSeries.push(item.HandedInLate)
                overDueSeries.push(item.Overdue)
                dueSeries.push(item.Due)
            })()
        }

        this.setState({
            chartData: data
        })

        options.xAxis.categories = dimensions

        options.series = [
            {
                name: 'Handed In',
                data: handedInSeries
            },
            {
                name: 'Handed In Late',
                data: handedInLateSeries
            },
            {
                name: 'Not handed in',
                data: overDueSeries
            },
            {
                name: 'Due',
                data: dueSeries
            }
        ]

        this.setState({ options: options, showChart: true, isLoading: false, hasErrored: false })
    }, () => {
      this.setState({ showChart: false, isLoading: false, hasErrored: true })
    })
  }

  _dimensionChange = (dimension) => {
    this.currentDimension = dimension
    this._getData(this.currentTimeFrame, this.currentDimension)
  }

  _timeframeChange = (timeframe) => {
    this.currentTimeFrame = timeframe
    this._getData(this.currentTimeFrame, this.currentDimension)
  }

  _toggleExpand = () => {
      let newIsExpanded = !this.state.isExpanded

      let newOptions = this.state.options
      newOptions.chart.height = newIsExpanded ? '800px' : '400px;'
      this.setState({
          isExpanded: newIsExpanded,
          options: newOptions
      })
      this._getData(this.currentTimeFrame, this.currentDimension)
  }

_helpText () {
 return (
     <div>
         Analyses the status of homework where the "available from" date falls within the selected timeframe. <br />
         <ul>
             <li><b>Handed In:</b> Handed in on or before the due date. </li>
             <li><b>Handed In Late:</b> Handed in after the due date.</li>
             <li><b>Not Handed In:</b> Not handed in and the due date has passed.</li>
             <li><b>Due:</b> Currently available and has not been handed in.</li>
         </ul>
     </div>
 )
}

  render () {
     return (
         <div className="widget-container">
             <div className={this.state.isExpanded ? 'widget expanded' : 'widget'} >
                 <div className="widget-header">
                     <div className="widget-drops">
                         <div className="widget-header-cell widget-title">
                             Hand In Status
                             <ChartHelp helpText={this._helpText} />
                             <ExportButton filename="HandInStatus" data={this.state.chartData} timeframe={this.currentTimeFrame} dimension={this.currentDimension} disabled={(this.state.isLoading || this.state.hasErrored)} />
                         </div>
                         <div className="widget-header-cell widget-drop">
                             <Dropster options={DropsterDimensions} narativeText="Slice by" onChange={this._dimensionChange} />
                         </div>
                         <div className="widget-header-cell widget-drop">
                             <Dropster options={DropsterTimeframes} narativeText="Timeframe" onChange={this._timeframeChange} />
                         </div>
                     </div>
                 </div>
                 <div className="widget-content">
                     <Chart options={this.state.options} showChart={this.state.showChart} />
                     <Loading isLoading={this.state.isLoading} />
                     <Error hasErrored={this.state.hasErrored}/>
                     <ExpandButton isExpanded={this.state.isExpanded} toggleExpand={() => { this._toggleExpand() }} />
                 </div>
             </div>
         </div>
      )
    }
}

HandInStatusByDimension.propTypes = {
  fullReportClick: PropTypes.func.isRequired,
  showFullReport: PropTypes.bool.isRequired
}

export default HandInStatusByDimension
