import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'

import './export-button.scss'

class ExportButton extends Component {
  download = (e) => {
    e.preventDefault()

    if (!this.props.data || this.props.data.length < 1) {
      return
    }

    let filename = this.buildFilename()
    let csvData = this.arrayToCSV(this.props.data)

    if (navigator.msSaveBlob) {
      // ie10+ has its own mechanism for saving blobs
      navigator.msSaveBlob(new Blob([csvData], { type: 'text/csv;charset=utf-8;' }), filename)
    } else {
      this.createElement(filename, csvData)
    }
  }

  buildFilename = function () {
    let filename = this.props.prefixDate ? `${Moment().format('YYYY-MM-DD')}_` : ''
    filename += this.props.filename
    filename += this.props.timeframe ? `_${this.props.timeframe}` : ''
    filename += this.props.dimension ? `_${this.props.dimension}` : ''
    filename += '.csv'

    return filename
  }

  createElement = function (filename, text) {
    var element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', filename)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  arrayToCSV = function (objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
    let str = `${Object.keys(array[0]).map(value => `"${value}"`).join()}` + '\r\n'

    return array.reduce((accumulator, next) => {
      accumulator += `${Object.values(next).map(value => {
          if (isNaN(value)) {
            // eslint-disable-next-line no-useless-escape
            return `"${(value + '').replace(/\"/g, '""')}"`
          }
          return value
        }).join()}` + '\r\n'
        return accumulator
    }, str)
  }

  render () {
      return (
          <a className="btn-export" title="Download" onClick={(e) => this.download(e)} disabled={this.props.disabled || !this.props.data || this.props.data.length < 1}>
              <i className="fa fa-download" aria-hidden="true" />
          </a>
      )
    }
}

ExportButton.propTypes = {
    data: PropTypes.array,
    dimension: PropTypes.string,
    disabled: PropTypes.bool,
    filename: PropTypes.string.isRequired,
    prefixDate: PropTypes.bool,
    timeframe: PropTypes.string
}

ExportButton.defaultProps = {
  prefixDate: true
}

export default ExportButton
