import APIService from './APIService'
import Configuration from './ConfigurationService'

class PersonService {
  constructor () {
    this.instance = APIService.create(Configuration.PeopleApiUrl)
  }

  getSingle (id) {
    return this.instance.get('v2/Person/Single/' + id).then((response) => response.data)
  }
}

export let personService = new PersonService()
