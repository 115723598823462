import React, { Component } from 'react'
import './homework-teacher-view.scss'
import HomeworkStudentList from './HomeworkStudentList'
import HomeworkMarkActions from './HomeworkMarkActions'
import HomeworkHeaderActions from './HomeworkHeaderActions'
import HomeworkView from './HomeworkView'
import HomeworkTeacherViewStore from '../../../Stores/HomeworkTeacherViewStore'
import { HomeworkTeacherViewService } from '../../../Services/Homework/Bundle'
import Lodash from 'lodash'

class HomeworkTeacherView extends Component {
    state = {
      isFullScreenMode: false,
      activeStudent: null,
      activeStudentHomework: null,
      activeView: null,
      isLoading: false,
      isWaiting: false
    }

    _expand = function () {
      this.setState({
        isFullScreenMode: !this.state.isFullScreenMode
      })
    }

    componentWillMount () {
      if (this.props.match.params.homeworkId) {
        HomeworkTeacherViewStore.dispatch({
          type: 'SET_CURRENT_HOMEWORK',
          homeworkId: this.props.match.params.homeworkId
        })
      }
    }

    componentDidMount () {
      let teacherViewService = new HomeworkTeacherViewService()

      let debounced = Lodash.debounce(() => {
        let currentState = HomeworkTeacherViewStore.getState()
        if (currentState.activeStudent !== null) {
          this.setState({
            isLoading: true,
            isWaiting: false
          })
          teacherViewService.GetStudentHomework(currentState.activeStudent.StudentId, currentState.homeworkId).then((studentHomework) => {
            this.setState({
              isLoading: false
            })

            let currentState = HomeworkTeacherViewStore.getState()
            if (!currentState.activeStudentHomework ||
                        (currentState.activeStudentHomework.HomeworkStudentLinkId !== studentHomework.HomeworkStudentLinkId)) {
              HomeworkTeacherViewStore.dispatch({
                type: 'SET_ACTIVE_STUDENT_HOMEWORK',
                activeStudentHomework: studentHomework
              })
              this.setState({
                activeStudentHomework: studentHomework
              })
            }
          })
        }
      }, 500)

      HomeworkTeacherViewStore.subscribe(() => {
        let currentState = HomeworkTeacherViewStore.getState()

        this.setState({
          activeStudent: currentState.activeStudent,
          activeView: currentState.activeView,
          activeStudentHomework: currentState.activeStudentHomework
        })

        let newStudentIsDifferent = (this.state.activeStudent !== currentState.activeStudent)
        let studentIsSelectedButHomeworkNotLoaded = (currentState.activeStudent && !currentState.activeStudentHomework)

        if (newStudentIsDifferent || studentIsSelectedButHomeworkNotLoaded) {
          this.setState({
            isWaiting: true
          })
          debounced()
        }
      })
    }

    render () {
      return (
          <div id="homework-teacher-view">
              <div className={this.state.isFullScreenMode ? 'homework-teacher-expandable-viewer-container expanded' : 'homework-teacher-expandable-viewer-container'}>
                  <div className="homework-teacher-expandable-viewer">
                      <div className="row">
                          <div className="homework-teacher-expandable-viewer-left-column">
                              <HomeworkStudentList initalStudentId={this.props.match.params.selectedStudentId} />
                          </div>
                          <div className="homework-teacher-expandable-viewer-pane">
                              <div className="flex-box-columns">

                                  <div className="flex-box-header">
                                      <HomeworkHeaderActions expand={() => this._expand()} />
                                  </div>

                                  <div className="flex-box-content">
                                      <HomeworkMarkActions />

                                      <div style={{ visibility: (!this.state.isWaiting && !this.state.isLoading) ? 'visible' : 'hidden', height: '100%' }}>
                                          <HomeworkView />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              {this.state.isWaiting &&
              <div>Waiting ...</div>
              }

              {this.state.isLoading &&
              <div>Loading ... </div>
              }
          </div>
      )
    }
}

export default HomeworkTeacherView
