import React, { Component } from 'react'
import SimsIcon from './simsicon.png'
import { userContextService } from '../../../../Services/UserContextService'
import { roleService } from '../../../../Services/RoleService'

const styleMargin = { 'marginTop': '-4px' }
const portalRole = 1
const parentAndAttendanceOfficerRole = 3
const parentProduct = 'parent'
const studentProduct = 'student'
const experimentalfeaturesProduct = 'experimentalfeatures'
const extracurricularProduct = 'extracurricular'
const standalonehomeworkProduct = 'standalonehomework'
const engagementProduct = 'sims-engagement'
const schoolAdminProduct = 'schooladmin'

class Logo extends Component {
  state = {
    headerText: ''
  };

  componentDidMount () {
    this.setHeaderText()
  }

  setHeaderText () {
    let roleServiceProductListPromise = roleService.getProductList()
    let userContextServicePromise = userContextService.get()
    Promise.all([userContextServicePromise, roleServiceProductListPromise]).then(result => {
      let userContext = result[0]
      let roleList = result[1]
      let headerText = 'Engagement'
      let parentstudentEngagementEnabled = userContext.products.hwAny((p) => p.Name === parentProduct) || userContext.products.hwAny((p) => p.Name === studentProduct)
      let experimentalFeaturesEnabled = userContext.products.hwAny((p) => p.Name === experimentalfeaturesProduct)
      let activitiesEnabled = userContext.products.hwAny((p) => p.Name === extracurricularProduct)
      let standaloneHomeworkEnabled = userContext.products.hwAny((p) => p.Name === standalonehomeworkProduct)
      let hasEngagementRole = parentstudentEngagementEnabled ? roleList.hwAny(p => p.ProductCode === engagementProduct && (p.Roles === portalRole || p.Roles === parentAndAttendanceOfficerRole)) : false
      let hasStandaloneHomeworkRole = standaloneHomeworkEnabled ? roleList.hwAny(p => p.ProductCode === standalonehomeworkProduct && p.Roles === portalRole) : false
      let hasSchoolAdminRole = roleList.hwAny((p) => p.ProductCode === schoolAdminProduct && p.Roles === portalRole)
      if (hasEngagementRole || experimentalFeaturesEnabled || activitiesEnabled || parentstudentEngagementEnabled) {
        headerText = 'Engagement'
      } else if (hasStandaloneHomeworkRole || standaloneHomeworkEnabled) {
        headerText = 'Homework'
      }
       this.setState({ headerText: headerText })
    })
  }

  render () {
    document.title = 'SIMS ' + this.state.headerText
    return (
        <div className="mobile-navbar-title">
            <img src={SimsIcon} alt="SIMS logo" style={styleMargin}/>
            <span className="nav-product-title hidden-xs hidden-sm">{this.state.headerText}</span>
        </div>
    )
  }
}

export default Logo
