import RouteStore from '../Stores/RouteStore'
import { createHashHistory } from 'history'
import Configuration from './ConfigurationService'

let pathToRegexp = require('path-to-regexp')
let activiesUrl = Configuration.ActivitiesUrl

let foreachRouteItem = function (routeConfig, func) {
  routeConfig.forEach(outerRoute => {
    func(outerRoute)

    if (outerRoute.children) {
      outerRoute.children.forEach(innerRoute => {
        func(innerRoute, outerRoute)
      })
    }
  })
}

class RouteConfiguation {
    currentRoute ={
      path: '/'
    }

    /*
        All pages with push you to OAuth, unless you put requiresAuthorise:false
        !compiledRegex is a required property!
    */

    // http://adilapapaya.com/docs/path-to-regexp/#livedemo
    routeConfiguration = [
      {
        title: 'Unauthorised',
        path: '/unauthorised',
        compiledRegex: null,
        showInMenu: false,
        requiresAuthorise: false
      },
      {
        title: 'SignedOut',
        path: '/signedout',
        compiledRegex: null,
        showInMenu: false,
        requiresAuthorise: false
      },
      {
        title: 'Messages',
        icon: 'fa fa-envelope',
        path: '/messages',
        compiledRegex: null,
        showInMenu: true,
        requiresAuthorise: true
      },
      {
        title: 'Homework',
        icon: 'fa fa-file-text',
        path: '/homework/:subpath?/:subpath2?/:subpath3?',
        compiledRegex: null,
        showInMenu: true,
        requiresAuthorise: true,
        children: [
          {
            title: 'Dashboard',
            icon: 'fa fa-pie-chart',
            path: '/homework/dashboard',
           // path: '/homework/dashboard/:subpath?/:subpath2?/:subpath3?/:subpath4?', // Subpaths are added for the various reports to come
            compiledRegex: null,
            showInMenu: true,
            requiresAuthorise: true,
            directLink: '/homework/dashboard'
          },
          {
            title: 'All Homework',
            icon: 'fa fa-list-ul',
            path: '/homework/all-homework',
          //  path: '/homework/all-homework/:subpath?/:subpath2?/:subpath3?/:subpath4?',
            compiledRegex: null,
            showInMenu: true,
            requiresAuthorise: true,
            directLink: '/homework/all-homework'
          },
          {
            title: 'Create',
            icon: 'fa fa-plus',
            path: '/homework/create',
            compiledRegex: null,
            showInMenu: true,
            requiresAuthorise: true
          },
          {
            title: 'Edit',
            icon: 'fa fa-plus',
            path: '/homework/edit/:homeworkId',
            compiledRegex: null,
            showInMenu: false,
            requiresAuthorise: true
          },
          {
            title: 'Submissions',
            titleFromQueryStringParameter: 'homeworktitle',
            icon: 'fas fa-users',
            path: '/homework/teacherview/:homeworkId',
            compiledRegex: null,
            showInMenu: false,
            requiresAuthorise: true

          },
          {
            title: 'Help',
            icon: 'fa fa-question-circle',
            path: Configuration.simsHomeworkHelpLink,
            useUrl: true,
            compiledRegex: null,
            showInMenu: true,
            requiresAuthorise: false,
            target: '_blank'
          }
        ]
      },
      {
        title: 'Activities',
        icon: 'icon icon-activities',
        path: this.getActivitiesURL(),
        useUrl: true,
        compiledRegex: null,
        showInMenu: true,
        target: '_blank',
        requiresAuthorise: false
      },
      {
        title: 'Sickness',
        icon: 'fa fa-medkit',
        path: '/sickness',
        compiledRegex: null,
        showInMenu: true,
        requiresAuthorise: true
      }
    ];

    constructor () {
      // compile regexes
      foreachRouteItem(this.routeConfiguration, function (routeItem) {
        routeItem.compiledRegex = pathToRegexp(routeItem.path)
      })

      const history = createHashHistory()
      history.listen((location) => {
        this._setRoute(location.pathname)
      })

      this._setRoute(history.location.pathname)
    }

    getActivitiesURL () {
      return activiesUrl
    }

    _setRoute (path) {
      RouteStore.dispatch({
        type: 'SET_NEWPATH',
        path: path
      })
    }

    _getParameterByName (name, url) {
      if (!url) url = window.location.href
      // eslint-disable-next-line no-useless-escape
      name = name.replace(/[\[\]]/g, '\\$&')
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
      var results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ''
      return decodeURIComponent(results[2].replace(/\+/g, ' '))
    }

    redirectToRoute (path) {
      window.location = '/#' + path
    }

    redirectToUrl (path) {
      window.location = path
    }

    getCurrentPageInfo () {
      let outerMatch = null
      let innerMatch = null
      let currentPath = RouteStore.getState().path

      this.routeConfiguration.forEach(outerRoute => {
        if (outerRoute.compiledRegex.exec(currentPath)) {
          outerMatch = outerRoute
        }

        if (outerRoute.children) {
          outerRoute.children.forEach(innerRoute => {
            if (innerRoute.compiledRegex.exec(currentPath)) {
              innerMatch = innerRoute
            }
          })
        }
      })

      outerMatch = outerMatch || { path: '/', title: 'Home' }
      innerMatch = innerMatch || null
      let returnObject = {
        path: currentPath,
        outerRoutePath: outerMatch.path,
        outerRouteTitle: outerMatch.title,
        requiresAuthorise: (outerMatch.requiresAuthorise !== false) && (!innerMatch || innerMatch.requiresAuthorise !== false)
      }

      if (innerMatch) {
        if (innerMatch.titleFromQueryStringParameter) {
          let parameter = this._getParameterByName(innerMatch.titleFromQueryStringParameter)
          if (parameter) {
            return {
              ...returnObject,
              innerRoutePath: innerMatch.path,
              innerRouteTitle: parameter
            }
          }
        }

        return {
          ...returnObject,
          innerRoutePath: innerMatch.path,
          innerRouteTitle: innerMatch.title
        }
      }

      return returnObject
    }

    subscribeToCurrentPathChange (func) {
      RouteStore.subscribe(() => {
        func(this.getCurrentPageInfo())
      })
    }
    getConfiguration () {
      return this.routeConfiguration
    }
    getMenuConfiguration () {
      let outerElements = this.routeConfiguration.hwWhere((item) => {
        return item.showInMenu
      })

      return outerElements.hwSelect((item) => {
        let clone = { ...item }
        if (clone.children && clone.children.length) {
          clone.children = clone.children.hwWhere((childItem) => {
            return childItem.showInMenu !== false
          })
        }
        return clone
      })
    }
}

export let routeConfiguration = new RouteConfiguation()
