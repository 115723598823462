import React, { Component } from 'react'
import './homework-hand-in-action.scss'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FormatDateTerseWithOn } from '../../Common/Utils'
import onClickOutside from 'react-onclickoutside'
import Moment from 'moment'

class HandInActionButton extends Component {
    state = {
      isCalendarOpen: false,
      date: new Date()
    }

    _toggleCalendar = () => {
      this.setState({
        isCalendarOpen: !this.state.isCalendarOpen
      })
    }

    _setDate = (date) => {
      this.setState({
        date: Moment(date),
        isCalendarOpen: false
      })
    }

    handleClickOutside = () => {
      this.setState({
        isCalendarOpen: false
      })
    };

    render () {
      return (
          <div id="hand-in-action-button">
              <div className="btn-group action-button">
                  <button type="button" className="btn btn-default btn-sm hand-in-button" onClick={() => { this.props.handedIn(this.state.date) }}>Hand In {FormatDateTerseWithOn(this.state.date)}</button>
                  <button type="button" className="btn btn-default dropdown-toggle btn-sm" onClick={() => { this._toggleCalendar() }}>
                      <span className="caret" />
                      <span className="sr-only">Toggle Dropdown</span>
                  </button>
                  {this.state.isCalendarOpen &&
                  <div className="hand-in-calendar">
                      <ReactDatePicker
                          inline
                          onChange={(d) => { this._setDate(d) }}
                      />
                  </div>
                  }
              </div>
          </div>
      )
    }
}

HandInActionButton.propTypes = {
  handedIn: PropTypes.func.isRequired
}

export default onClickOutside(HandInActionButton)
