import React, { Component } from 'react'
import { groupService } from '../../../Services/GroupService'

class HomeworkReports extends Component {
  constructor (props) {
    super(props)
    this.groupsMap = groupService.getAllGroupsMap()
  }

    state ={
      groupName: null
    }

    groupsMap = null;

    componentDidMount () {
      this.groupsMap.then(map => {
        this.setState({ groupName: map.get('fad4bb9e-5b51-4f8c-88f6-7a7db28b6aa7') })
      })
    }

    render () {
      return (
          <div>this is the homework reports page, GroupName:{this.state.groupName}</div>
      )
    }
}

export default HomeworkReports
