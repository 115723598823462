import React, { Component } from 'react'
import './homework-header-actions.scss'
import PropTypes from 'prop-types'
import HomeworkTeacherViewStore from '../../../../Stores/HomeworkTeacherViewStore'
import Moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import HandInActionButton from '../HandInActionButton'
import HomeworkTeacherViewService from '../../../../Services/Homework/HomeworkTeacherViewService'
import { StudentHomeworkStatus } from '../../../../Services/Homework/Enums'

class HomeworkHeaderActions extends Component {
    state = {
      isFullScreenMode: false,
      showGradeAndComment: false,
      today: Moment(),
      nextEnabled: false,
      previousEnabled: false,
      activeStudent: null,
      homeworkId: null
    }

    componentDidMount () {
      HomeworkTeacherViewStore.subscribe(() => {
        let currentState = HomeworkTeacherViewStore.getState()
        this.setState({
          nextEnabled: this._teacherViewService.CanIncrementStudent(1),
          previousEnabled: this._teacherViewService.CanIncrementStudent(-1),
          activeStudent: currentState.activeStudent,
          homeworkId: currentState.homeworkId,
          showGradeAndComment: currentState.showGradeAndComment
        })
      })
    }

    _teacherViewService = new HomeworkTeacherViewService();

    _expand = () => {
      this.setState({
        isFullScreenMode: !this.state.isFullScreenMode
      })

      HomeworkTeacherViewStore.dispatch({
        type: 'EXPAND_VIEW',
        isFullScreenMode: !this.state.isFullScreenMode
      })

      this.props.expand()
    }

    _showGradeAndComment = () => {
      HomeworkTeacherViewStore.dispatch({
        type: 'SHOW_GRADE_AND_COMMENT',
        showGradeAndComment: !this.state.showGradeAndComment
      })
    }

    _next = () => {
      this._teacherViewService.IncrementToStudent(1)
    }

    _previous = () => {
      this._teacherViewService.IncrementToStudent(-1)
    }

    _updateHandInStatus (date, studentHomeworkStatus) {
      let activeStudent = this.state.activeStudent

      this._teacherViewService.IncrementToStudent(1, studentHomeworkStatus)

      activeStudent.Status = studentHomeworkStatus

      // Assume success (fire and forget)
      this._teacherViewService.handInHomework(this.state.homeworkId, activeStudent, date)
    }

    _handInHomework (date) {
      this._updateHandInStatus(date, StudentHomeworkStatus.HANDED_IN)
    }

    _clearHandedInStatus () {
      this._updateHandInStatus(null, StudentHomeworkStatus.NOT_HANDED_IN)
    }

    _clearGradeAndComment () {
      this._teacherViewService.SaveHomeworkMark(this.state.activeStudent.StudentId, this.state.activeStudent.HomeworkId, null, null)

      HomeworkTeacherViewStore.dispatch({
        type: 'UPDATE_GRADE_AND_COMMENT',
        grade: null,
        comment: null
      })

      this._teacherViewService.IncrementToStudent(1)

      let activeStudent = this.state.activeStudent
      activeStudent.Status = StudentHomeworkStatus.HANDED_IN
    }

    render () {
      return (
          <div id="homework-header-actions">
              <div className="homework-header-actions-row">
                  <div className="homework-header-actions-row-cell cell-left">
                      <button className="btn btn-default btn-sm action-button" onClick={() => { this._previous() }} disabled={!this.state.previousEnabled}><i className="fa fa-chevron-left" aria-hidden="true" />&nbsp; Previous</button>
                  </div>

                  <div className="homework-header-actions-row-cell cell-center">

                      {this.state.isFullScreenMode &&
                      <button className="btn btn-default btn-sm action-button" onClick={() => { this._expand() }}><i className="fa fa-compress" aria-hidden="true" />&nbsp; Collapse</button>
                      }
                      {!this.state.isFullScreenMode &&
                      <button className="btn btn-default btn-sm action-button" onClick={() => { this._expand() }}><i className="fa fa-expand" aria-hidden="true" />&nbsp; Expand</button>
                      }

                      {this.state.activeStudent && this.state.activeStudent.Status === StudentHomeworkStatus.HANDED_IN &&
                      <button className="btn btn-default btn-sm action-button" onClick={() => { this._clearHandedInStatus() }}><i className="fa
                            fa-unlock" aria-hidden="true"
                                                                                                                               />&nbsp; Not Handed In (removes attachments)</button>
                      }

                      {this.state.activeStudent && this.state.activeStudent.Status === StudentHomeworkStatus.NOT_HANDED_IN &&
                      <HandInActionButton handedIn={(date) => { this._handInHomework(date) }} />
                      }

                      {this.state.activeStudent && this.state.activeStudent.Status === StudentHomeworkStatus.MARKED &&
                      <button className="btn btn-default btn-sm action-button" onClick={() => { this._clearGradeAndComment() }}><i className="fa
                            fa-undo" aria-hidden="true"
                                                                                                                                />&nbsp; Clear Grade and Comment</button>
                      }
                  </div>
                  <div className="homework-header-actions-row-cell cell-right">

                      {this.state.activeStudent && this.state.activeStudent.Status === StudentHomeworkStatus.HANDED_IN &&
                      <span>
                          {this.state.showGradeAndComment &&
                          <button className="btn btn-default btn-sm action-button" onClick={() => { this._showGradeAndComment() }}>Grade/Comment &nbsp;<i className="fa fa-chevron-up" aria-hidden="true" /></button>
                          }

                          {!this.state.showGradeAndComment &&
                          <button className="btn btn-default btn-sm action-button" onClick={() => { this._showGradeAndComment() }}>Grade/Comment &nbsp;<i className="fa fa-chevron-down" aria-hidden="true" /></button>
                          }
                      </span>
                      }

                      <button className="btn btn-default btn-sm action-button" onClick={() => { this._next() }} disabled={!this.state.nextEnabled}>Next &nbsp;<i className="fa fa-chevron-right" aria-hidden="true" /></button>
                  </div>
              </div>
          </div>
      )
    }
}

HomeworkHeaderActions.propTypes = {
  expand: PropTypes.func.isRequired
}

export default HomeworkHeaderActions
