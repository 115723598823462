import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import './page-selector.css'

class PageSelector extends Component {
  render () {
    let pageCount = this.props.pageCount
    let onPageChange = this.props.onPageChange
    let selectedPage = this.props.selectedPage

    return (
        <div className="page-selector">
            <ReactPaginate previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={onPageChange}
                containerClassName={'pagination-container'}
                pageClassName={'pagination-page'}
                pageLinkClassName={'pagination-page-link'}
                previousClassName={'pagination-page-previous'}
                nextClassName={'pagination-page-next'}
                previousLinkClassName={'pagination-page-previous-link'}
                nextLinkClassName={'pagination-page-next-link'}
                disabledClassName={'pagination-disabled'}
                breakClassName={'break-me'}
                activeClassName={'pagination-active'}
                forcePage={selectedPage}
            />
        </div>
    )
  }
}

PageSelector.props = {
  onPageChange: PropTypes.func
}

export default PageSelector
