/* eslint-disable react/no-multi-comp */
import React from 'react'
import Moment from 'moment'

function FormatDate (date) {
  let relativeDate = Moment(date).calendar(Moment().startOf('day'), {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'DD/MM/YYYY'
  })

  let exactDate = Moment(date).format('DD/MM/YYYY')

  return (<span>
      <span className="emphasise-text">{relativeDate}</span>
      {(relativeDate !== exactDate) &&
      <span className="faint-text small-text"> ({exactDate})</span>
      }
  </span>)
}

function FormatDateTerseWithOn (date) {
  let relativeDate = Moment(date).calendar(Moment().startOf('day'), {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'on dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: '[on the] DD/MM/YYYY'
  })

  return <span className="emphasise-text">{relativeDate}</span>
}

export { FormatDate, FormatDateTerseWithOn }
