import { createStore, compose, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import { StudentHomeworkStatus } from '../../Services/Homework/Enums'

let initialState = {
  isFullScreenMode: false,
  homeworkId: null,
  activeStudent: null,
  showGradeAndComment: null,
  activeView: StudentHomeworkStatus.HANDED_IN,
  students: null,
  activeStudentHomework: null
}

const enhancer = compose(
  applyMiddleware(logger)
)

function reducer (state, action) {
  switch (action.type) {
  case 'SET_CURRENT_HOMEWORK':
    return {
      ...initialState,
      homeworkId: action.homeworkId
    }
  case 'SET_ACTIVE_STUDENT_HOMEWORK':
    return {
      ...state,
      activeStudentHomework: action.activeStudentHomework,
      showGradeAndComment: false
    }
  case 'SET_STUDENTS':
    return {
      ...state,
      students: action.students
    }
  case 'SET_ACTIVE_STUDENT':
    return {
      ...state,
      activeStudent: action.activeStudent
    }
  case 'SET_ACTIVE_VIEW':
    return {
      ...state,
      activeView: action.activeView
    }
  case 'EXPAND_VIEW':
    return {
      ...state,
      isFullScreenMode: action.isFullScreenMode
    }
  case 'SHOW_GRADE_AND_COMMENT':
    return {
      ...state,
      showGradeAndComment: action.showGradeAndComment
    }
  case 'UPDATE_GRADE_AND_COMMENT':
    let newState = {
      ...state
    }
    newState.activeStudent.Result = action.grade
    newState.activeStudent.Comments = action.comment
    newState.activeStudentHomework.Result = action.grade
    newState.activeStudentHomework.Comments = action.comment
    return newState
  default:
    return state
  }
}

let store = createStore(reducer, initialState, enhancer)

export default store
