import React, { Component } from 'react'
import { authService } from '../../../Services/AuthService'
import { routeConfiguration } from '../../../Services/RouteConfigurationService'

class AuthenticationRouteCheck extends Component {
  constructor (props) {
    super(props)

    let currentPageInfo = routeConfiguration.getCurrentPageInfo()

    let loginIfPageRequiredIt = function (page) {
      if (page.requiresAuthorise) {
        authService.logIn()
      }
    }

    routeConfiguration.subscribeToCurrentPathChange((page) => {
      loginIfPageRequiredIt(page)
    })

    loginIfPageRequiredIt(currentPageInfo)
  }

  render () {
    return <div />
  }
}

export default AuthenticationRouteCheck
