import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Alerts.css'
import scrollToComponent from 'react-scroll-to-component'

export const AlertType = {
  NONE: 'NONE',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

class Alert extends Component {
  constructor (props) {
    super(props)
    this.myRef = React.createRef()
  }

    componentDidUpdate () {
      if (this.props.statusMessage !== this.lastStatusMessage) {
        if (this.props.scrollOnShow) {
          if (this.myRef.current) {
            scrollToComponent(this.myRef.current, { offset: 0, align: 'middle', duration: 500, ease: 'inCirc' })
          }
        }
      }
      this.lastStatusMessage = this.props.statusMessage
    }

    lastStatusMessage = null

    render () {
      if (this.props.status === AlertType.INFO) {
        return (<div ref={this.myRef} className="alert alert-info alert-transition" >{this.props.statusMessage}</div>)
      } else if (this.props.status === AlertType.SUCCESS) {
        return (<div ref={this.myRef} className="alert alert-success alert-transition" >{this.props.statusMessage}</div>)
      } else if (this.props.status === AlertType.ERROR) {
        return (<div ref={this.myRef} className="alert alert-danger alert-transition" >{this.props.statusMessage}</div>)
      } else {
        return (null)
      }
    }
}

Alert.defaultProps = {
  status: PropTypes.string,
  statusMessage: PropTypes.string,
  scrollOnShow: PropTypes.bool
}

export default Alert
