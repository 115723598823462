import React, { Component } from 'react'
import './homework-mark-actions.scss'
import HomeworkTeacherViewStore from '../../../../Stores/HomeworkTeacherViewStore'
import HomeworkTeacherViewService from '../../../../Services/Homework/HomeworkTeacherViewService'
import { StudentHomeworkStatus } from '../../../../Services/Homework/Enums'
import { userContextService } from '../../../../Services/UserContextService'
import { appInsightsService } from '../../../../Services/AppInsightsService'
import * as Constants from '../../../../Constants/constants'

class HomeworkMarkActions extends Component {
    featureToggleOn () {
      let enableSendEmail = userContextService.checkFeatureToggle(Constants.EMAILFEATURETOGGLE)
      this.setState({ enableSendEmail: enableSendEmail })
    }

    state = {
      showGradeAndComment: false,
      grade: null,
      comment: null,
      enableSendEmail: undefined,
      enableSaveNext: false
    }

    componentDidMount () {
      let setStateFromStore = () => {
        let state = HomeworkTeacherViewStore.getState()

        this.setState({
          showGradeAndComment: state.showGradeAndComment,
          activeStudent: state.activeStudent,
          grade: null,
          comment: null,
          enableSaveNext: false
        })
        setTimeout(() => {
          let commentArea = document.getElementById('homework-comment-textarea')
          if (!this.state.comment && commentArea) {
            commentArea.value = ''
          }
        }, 0)

      let userContext = userContextService.get()
       Promise.resolve(userContext).then(response => {
        this.setState({ user: response })
       })
      }

      HomeworkTeacherViewStore.subscribe(() => {
        setStateFromStore()
      })
      setStateFromStore()
    }

    _saveAndNext () {
      var service = new HomeworkTeacherViewService()

      let currentStatus = this.state.activeStudent.Status

      // (we are trigger a store update, so a render will happen iminently)
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.activeStudent.Status = StudentHomeworkStatus.MARKED

      HomeworkTeacherViewStore.dispatch({
        type: 'SHOW_GRADE_AND_COMMENT',
        showGradeAndComment: !this.state.showGradeAndComment
      })

      HomeworkTeacherViewStore.dispatch({
        type: 'UPDATE_GRADE_AND_COMMENT',
        grade: this.state.grade,
        comment: this.state.comment
      })

      if (this.state.enableSendEmail === undefined) {
        this.featureToggleOn()
       }

      service.SaveHomeworkMark(this.state.activeStudent.StudentId, this.state.activeStudent.HomeworkId, this.state.grade, this.state.comment)
        .then(() => {
          // on success, do nothing

          if (this.state.enableSendEmail) {
            let activeStudent = this.state.activeStudent
            let feedbackDetail = { 'schoolGuid': activeStudent.SchoolGuid,
                                 'subjectGuid': activeStudent.SubjectId,
                                 'subject': activeStudent.Subject,
                                 'createdByGuid': activeStudent.CreatedById,
                                 'createdBy': activeStudent.CreatedBy,
                                 'title': activeStudent.Title,
                                 'description': activeStudent.Description,
                                 'source': 'homework-online',
                                 'setByGuid': this.state.user.userId,
                                 'setBy': this.state.user.simsName,
                                 'grade': activeStudent.Result,
                                 'comment': activeStudent.Comments,
                                 'homeworkStudentLinkGuid': activeStudent.Id }
            let recipientsInfo = []
            let studentInfo = { 'recipientId': activeStudent.StudentId, 'recipientEmail': activeStudent.Email, 'recipientName': activeStudent.DisplayForename }
            recipientsInfo.push(studentInfo)
            service.sendHomeworkMarked(feedbackDetail, this.state.user.schoolName, recipientsInfo).then((response) => {
              console.log(response.data)
              let successMessage = 'Homework Marking email sent.'
              appInsightsService.logInfor(successMessage)
              appInsightsService.trackEvent('Email Sent on Homework Marking', { ActiveSchoolId: activeStudent.SchoolGuid })
            })
          }
          service.IncrementToStudent(1, StudentHomeworkStatus.MARKED)
        }, () => {
          // (we are trigger a store update, so a render will happen iminently)
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.activeStudent.Status = currentStatus
          service.IncrementToStudent(-1)
        })
    }

    _onGradeChange (el) {
      let disableSave = !el.value && !this.state.comment
      this.setState({
        grade: el.value,
        enableSaveNext: !disableSave
      })
    }

    _onCommentChange (el) {
      let disableSave = !el.value && !this.state.grade
      this.setState({
        comment: el.value,
        enableSaveNext: !disableSave
      })
    }

    render () {
      if (this.state.showGradeAndComment) {
        return (
            <div id="homework-mark-actions">
                <div className="form-horizontal">
                    <div className="homework-grade form-group">
                        <label htmlFor="homework-grade" className="col-sm-2 control-label">Grade</label>
                        <div className="col-sm-10">
                            <input id="homework-grade" className="form-control" type="input" value={this.state.grade || ''} onChange={(e) => { this._onGradeChange(e.target); e.preventDefault() }} />
                        </div>
                    </div>
                    <div className="homework-comment form-group">
                        <label htmlFor="homework-comment" className="col-sm-2 control-label">Comment</label>
                        <div className="col-sm-10">
                            <textarea
                                id="homework-comment-textarea"
                                className="form-control"
                                onChange={(e) => { this._onCommentChange(e.target); e.preventDefault() }}
                                rows="2"
                            >{this.state.comment}
                            </textarea>
                        </div>
                    </div>
                    <button className="save-button btn btn-default pull-right" disabled={!this.state.enableSaveNext} onClick={() => { this._saveAndNext() }}>Save and Next</button>
                </div>

                {/* Crazy IE11 fix. */}
                <iframe className="cover" src="about:blank" />

            </div>
        )
      } else {
        return null
      }
    }
}

export default HomeworkMarkActions
