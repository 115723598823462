/* eslint-disable */

Array.prototype.hwSelect = function (func) {
    let result = [];
    for (let i = 0; i < this.length; i++) {
        let item = this[i];
        result.push(func(item, i));
    }
    return result;
}

Array.prototype.hwWhere = function (func) {
    let result = [];
    for (let i = 0; i < this.length; i++) {
        let item = this[i];
        if (func(item)) {
            result.push(item);
        }
    }
    return result;
}

Array.prototype.hwCount = function (func) {
    let c = 0;
    for (let i = 0; i < this.length; i++) {
        let item = this[i];
        if (func(item)) {
            c++;
        }
    }
    return c;
}

Array.prototype.hwIndexForFirst = function (predicate) {
      for (let i = 0; i < this.length; i++) {
        let item = this[i];
        if (predicate(item)) {
            return i;
        }
    }
    return null;
}

Array.prototype.hwFirstOrDefault = function (predicate) {

    if (!predicate) {
        predicate = function () {
            return true;
        }
    }

    for (let i = 0; i < this.length; i++) {
        let item = this[i];
        if (predicate(item)) {
            return item;
        }
    }
    return null;
}

Array.prototype.hwLastOrDefault = function (predicate) {
    if (!predicate) {
        predicate = function () {
            return true;
        }
    }

    for (let i = this.length-1; i > -1; i--) {
        let item = this[i];
        if (predicate(item)) {
            return item;
        }
    }
    return null;
}


Array.prototype.hwEach = function (func) {
    if (!func) {
        return;
    }

    for (let i = 0; i < this.length; i++) {
        let item = this[i];
        func(item, i);
    }
}


Array.prototype.hwContains = function (obj) {
    let i = this.length;
    while (i--) {
        if (this[i] === obj) {
            return true;
        }
    }
    return false;
}

Array.prototype.hwAny = function (func) {
    if (!func) {
        return this.length > 0;
    }

    let i = this.length;
    while (i--) {
        if (func(this[i])) {
            return true;
        }
    }
    return false;
}

Array.prototype.hwAll = function (func) {
    let i = this.length;
    while (i--) {
        if (!func(this[i])) {
            return false;
        }
    }
    return true;
}

Array.prototype.hwRemove = function (itemToRemove) {
    let predicate;

    if (typeof itemToRemove === 'function') {
        predicate = itemToRemove;
    } else {
        predicate = function (itemToCheck) {
            return itemToCheck === itemToRemove;
        }
    }

    let i = this.length;
    while (i--) {
        let item = this[i];
        if (predicate(item)) {
            this.splice(i, 1);
        }
    }
    return this;
}

Array.prototype.hwJoin = function (other, predicate, mapping) {
	var result = [];
   for (i = 0; i < this.length; i++){
   		var a = this[i];
        for (j = 0; j < other.length; j++){
      	    var b = other[j];
            if (predicate(a, b)){
                result.push(mapping(a, b));	          	
            }
        }
   }
   return result;
}

Array.prototype.hwGetMap = function (keyPredicate) {
    var map = new Map();
   for (i = 0; i < this.length; i++){
   		var a = this[i];
        let key = keyPredicate(a);
        let value = a;
        map.set(key, value);
   }
   return map;
}

Array.prototype.hwGetPage = function (pageSize, pageNumber) {
   let maximumLength = this.length;
   let startIndex = (pageNumber-1) * pageSize
   let endIndex = startIndex + pageSize;

   if(startIndex > maximumLength){
       return [];
   }

   let result = [];
   for (let i = startIndex; i < this.length && i < endIndex; i++){
       let item = this[i];
       if(item !== undefined)
        {
            result.push(item)
        }
   }
   return result;
}


Array.prototype.hwConcat = function (other) {
	var result = [];
   for (let i = 0; i < this.length; i++){
        result.push(this[i])
    }
   for (let i = 0; i < other.length; i++){
        result.push(other[i])
   }
   return result;
}