import Configuration from './ConfigurationService'
import { userContextService } from './UserContextService'
import ReactAI from 'react-appinsights'
import { createBrowserHistory } from 'history'

const SeverityLevel = {
  Information: 'Information',
  Warning: 'Warning',
  Error: 'Error'
}

class AppInsightsService {
  constructor () {
    let history = createBrowserHistory()
    ReactAI.init({ instrumentationKey: Configuration.AppInsightsInstrumentationKey }, history)
    ReactAI.setAppContext({ urlReferrer: document.referrer })
    this.AI = ReactAI.ai()
  }

  logInfor (message, additionalProperties = undefined) {
    this.logMessage(message, SeverityLevel.Information, additionalProperties)
  }

  logError (message) {
    this.logMessage(message, SeverityLevel.Error)
  }

  logWarning (message) {
    this.logMessage(message, SeverityLevel.Warning)
  }
  trackEvent (message, properties) {
    this.AI.trackEvent(message, properties)
   }

  logMessage (message, severityLevel, additionalProperties = undefined) {
    return userContextService.get().then((userContext) => {
      let properties = {
        schoolId: userContext.schoolId,
        schoolName: userContext.schoolName,
        userId: userContext.userId
      }

      if (additionalProperties) {
        properties = Object.assign(properties, additionalProperties)
      }

      this.AI.trackTrace(message, properties, severityLevel)
    })
  }
}

export let appInsightsService = new AppInsightsService()
