import React, { Component } from 'react'
import User from './User'
import Logo from './Logo'
import BreadCrumbs from './BreadCrumbs'

class Header extends Component {
  render () {
    return (
        <nav className="mobile-navbar">
            <div className="mobile-container-fluid">
                <div className="mobile-left-container">
                    <Logo />
                </div>
                <div className="mobile-right-container">
                    <div className="pull-right">
                        <ul className="nav navbar-nav navbar-right navbar-right-dont-collapse">
                            <User />
                        </ul>
                    </div>
                </div>
                <div className="mobile-center-container">
                    <div className="pull-left arrow-right hidden-xs" />
                    <div className="mobile-text-header">
                        <BreadCrumbs />
                    </div>
                </div>
            </div>
        </nav>
    )
  }
}

export default Header
