import React, { Component } from 'react'
import HomeworkStudentItem from './homeworkStudentItem'

class HomeworkStudentList extends Component {
  render () {
    let homeworkStudentItems = this.props.homework.filteredStudents.map(s => <HomeworkStudentItem key={s.Id} item={s} dueDate={this.props.homework.dueDate}/>)

    return (
        <div id="homework-student-list">
            {homeworkStudentItems}
        </div>
    )
  }
}

export default HomeworkStudentList
