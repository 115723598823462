
// Disabled, untill we re-write and start to use this control
/* eslint-disable */
import React, { Component } from 'react';
import { EditorState, RichUtils} from 'draft-js';
import Editor, { composeDecorators } from 'draft-js-plugins-editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createUndoPlugin from 'draft-js-undo-plugin';
import createToolbarLinkPlugin from 'draft-js-toolbar-link-plugin'
import createLinkifyPlugin from 'draft-js-linkify-plugin'
import 'draft-js-linkify-plugin/lib/plugin.css'

import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
    CodeBlockButton
  } from 'draft-js-buttons';

import './wysiwyg-editor.css'
import 'draft-js/dist/Draft.css'
import 'draft-js-static-toolbar-plugin/lib/plugin.css'

const imagePlugin = createImagePlugin();  //todo:rich

const theme = {
    undo: "draftJsToolbar__button__qi1gf",
    redo: "draftJsToolbar__button__qi1gf",
  };

const undoPlugin = createUndoPlugin({
    theme
});

const { UndoButton, RedoButton } = undoPlugin;

const toolbarLinkPlugin = createToolbarLinkPlugin();
const { LinkButton } = toolbarLinkPlugin;
const linkifyPlugin = createLinkifyPlugin();

const staticToolbarPlugin = createToolbarPlugin({
    structure: [
      HeadlineOneButton,
      HeadlineTwoButton,
      HeadlineThreeButton,
      BoldButton,
      ItalicButton,
      UnderlineButton,
      UnorderedListButton,
      OrderedListButton,
      BlockquoteButton,
      CodeBlockButton,
      LinkButton,
      UndoButton,
      RedoButton,
    ]
  });



const { Toolbar } = staticToolbarPlugin;

class WysiwygEditor extends Component {

    constructor(props) {
        super(props);
      }

    state = {
        editorState: EditorState.createEmpty()
    }

    _onChange = (editorState) => {
        this.setState({editorState: editorState})
    };

    render() {
        return (
            <div className="wysiwyg-editor">
                <div className="wysiwyg-buttons">
                    <Toolbar />
                </div>
                <div className="wysiwyg-text-area">

                    <Editor editorState={this.state.editorState} onChange={this._onChange} plugins={[staticToolbarPlugin, imagePlugin, undoPlugin, toolbarLinkPlugin, linkifyPlugin ]} />
                </div>
            </div>
        );
      }
};

export default WysiwygEditor;
/* eslint-enable */
