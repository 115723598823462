import { authService } from './AuthService'
import axios from 'axios'
import { routeConfiguration } from './RouteConfigurationService'
import { appInsightsService } from './AppInsightsService'
import { Exception } from 'handlebars'

class APIService {
  // static cancelToken = axios.CancelToken;
  static getCancelTokenSource () {
    return axios.CancelToken.source()
  }

  static create (baseURL, timeoutInMilliseconds = 30000, contentType = 'application/json') {
    let axiosInstance = axios.create({
      baseURL: baseURL,
      timeout: timeoutInMilliseconds,
      headers: {
        'Accept': 'application/json',
        'Content-Type': contentType
      }
    })

    axiosInstance.interceptors.request.use((request) => {
      let authStatus = authService.getStatus()
      if (authStatus.isAuthenticated) {
        request.headers.Authorization = 'Bearer ' + authService.getStatus().accessToken
        return request
      }
      throw new Exception('Not authenticated, abandoning request')
    },
    (error) => {
      return Promise.reject(error)
    })

    axiosInstance.interceptors.response.use((response) => response, (error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        routeConfiguration.redirectToRoute('unauthorised')
      }

      appInsightsService.logError(error.message)
      return Promise.reject(error)
    })

    return axiosInstance
  }
}

export default APIService
