import APIService from './APIService'
import Configuration from './ConfigurationService'
import sscache from 'session-storage-cache'

class SchoolDetailsService {
  constructor () {
    this.instance = APIService.create(Configuration.SchoolDetailsApiUrl)
  }

  getDetails (schoolId) {
    const cacheKey = `v2/SchoolDetails/SchoolDetails/${schoolId}`
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v2/SchoolDetails/SchoolDetails/' + schoolId).then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  getCurrentTerm (schoolId) {
    const cacheKey = `school-details-service/v2/SchoolDetails/CurrentTerm/${schoolId}`
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v2/SchoolDetails/CurrentTerm/' + schoolId).then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  getCurrentYear (schoolId) {
    const cacheKey = `school-details-service/v2/CurrentAcademicYear/${schoolId}`
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v2/SchoolDetails/CurrentAcademicYear/' + schoolId).then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  getTimeZone (schoolId) {
    const cacheKey = `school-details-service/v2/TimeZone/${schoolId}`
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v2/SchoolDetails/TimeZone/' + schoolId).then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }
}

export let schoolDetailsService = new SchoolDetailsService()
