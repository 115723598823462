import React, { Component } from 'react'
import './basic-downloader.scss'

class BasicDownloader extends Component {
    state = {
    }

    render () {
      return (
          <div id="basic-downloader">
              <span className="basic-downloader-title">
                  {this.props.file.FileName}
              </span>

              {this.props.file.Oversize &&
                <div className="downloading-files-dangerous" isVisible={this.props.file.Oversize}>
                    <div>This file is too large to be viewed online.</div>
                    <div>(Word and PowerPoint documents must be less than 10 megabytes; Excel must be less than 5 megabytes.)</div>
                </div>
              }

              <i className="file-downloader-icon fa fa-cloud-download" aria-hidden="true" />

              <a className="btn btn-default file-downloader-anchor" href={this.props.file.UrlWithSasToken} download>Download file</a>
              <div className="downloading-files-dangerous">
                  <span>Be careful when downloading files. It is wise to ensure that the file is virus checked before opening it.</span>
              </div>
          </div>
      )
    }
}

export default BasicDownloader
