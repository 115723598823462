import React, { Component } from 'react'
import './homework-view.scss'
import HandedInView from './HandedInView'
import NotHandedInView from './NotHandedInView'
import HomeworkTeacherViewStore from '../../../../Stores/HomeworkTeacherViewStore'
import { StudentHomeworkStatus } from '../../../../Services/Homework/Enums'

class HomeworkView extends Component {
    state = {
      activeStudent: null,
      activeStudentHomework: null
    }

    componentDidMount () {
      HomeworkTeacherViewStore.subscribe(() => {
        var currentState = HomeworkTeacherViewStore.getState()
        this.setState({
          activeStudent: currentState.activeStudent,
          activeStudentHomework: currentState.activeStudentHomework
        })
      })
    }

    render () {
      if (!this.state.activeStudent) {
        return null
      }

      return (
          <div id="homework-view">
              {this.state.activeStudent.Status === StudentHomeworkStatus.HANDED_IN &&
              <HandedInView />
          }

              {this.state.activeStudent.Status === StudentHomeworkStatus.NOT_HANDED_IN &&
              <NotHandedInView />
          }

              {this.state.activeStudent.Status === StudentHomeworkStatus.MARKED &&
              <HandedInView />
          }
          </div>
      )
    }
}

export default HomeworkView
