import React, { Component } from 'react'
import { authService } from '../../../Services/AuthService'

class SilentTokenRenewalAuthenticationFailedCallback extends Component {
  constructor (props) {
    super(props)
    authService.silentTokenRenewFailed()
  }

  render () {
    return <div />
  }
}

export default SilentTokenRenewalAuthenticationFailedCallback
