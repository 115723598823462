import React, { Component } from 'react'
import StudentSelector from '../../../Common/Forms/StudentSelector'
import { homeworkService } from '../../../../Services/Homework/HomeworkService'
import { teacherService } from '../../../../Services/TeacherService'
import LoDash from 'lodash'
import { Typeahead } from 'react-bootstrap-typeahead'
import Button from 'react-bootstrap/lib/Button'
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup'
import { userContextService } from '../../../../Services/UserContextService'
import Promise from 'bluebird'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import '../../homework.css'
import './filters.css'
import HomeworkListStore from '../../../../Stores/HomeworkListStore'

class Filters extends Component {
    // eslint-disable-next-line react/sort-comp
    stateOptions = {
      all: { key: 'all', text: 'All' },
      passed: { key: 'passed', text: 'Due date passed' },
      thisWeek: { key: 'this-week', text: 'Due in next 7 days' },
      next30Days: { key: 'next-30', text: 'Due in next 30 days' }
    }

    setByOptions = {
      mine: 'My Homework',
      all: 'Other Teachers Homework'
    }

    state = {
      searchQuery: null,
      assignedTo: {
        students: [],
        groups: []
      },
      subject: [], // typeaheads uses an array
      setBy: [], // typeaheads uses an array
      state: this.stateOptions.next30Days,
      allTeachers: [],
      allSubjects: [],
      setByOption: this.setByOptions.mine,
      currentUser: null
    }

    componentDidMount () {
      this._debouncedSearchFilterChange = LoDash.debounce((value) => {
        this.setState({ searchQuery: value }, this._saveStateToRedux)

        let newState = {
          searchQuery: value,
          assignedTo: this.state.assignedTo,
          subject: this.state.subject,
          setBy: this.state.setBy,
          state: this.state.state
        }

        this.props.onChange(newState, this.onChangeSequenceNumber++)
      }, 500)

      this.promise = Promise.all([teacherService.all(), homeworkService.getSubjects(), userContextService.get()]).then((result) => {
        let orderedTeachers = LoDash.orderBy(result[0], 'DisplaySurname')

        const user = result[2]
        let currentUser = LoDash.find(orderedTeachers, { 'Id': user.userId })

        let newSetBy = [currentUser]
        let newSetByOption = this.setByOptions.mine

        if (this.defaultSetById != null && this.defaultSetById !== currentUser.Id) {
          newSetBy = [LoDash.find(orderedTeachers, { 'Id': this.defaultSetById })]
          newSetByOption = this.setByOptions.all
        }

        let newState = {
          ...this.state,
          allTeachers: orderedTeachers,
          allSubjects: result[1],
          currentUser: currentUser,
          setBy: newSetBy,
          setByOption: newSetByOption
        }

        if (!this.props.sortOrder) {
          this._restoreStateFromRedux(newState)
        }

        this.setState(newState)

        this.onChange(newState, this.onChangeSequenceNumber++)
      })
    }

    componentWillUnmount () {
      if (this.promise) {
        this.promise.cancel()
      }
    }

    onChange = this.props.onChange;
    onChangeSequenceNumber = 0;
    defaultSetById = this.props.defaultSetBy;

    inputSearch = null

    _saveStateToRedux () {
      HomeworkListStore.dispatch({
        type: 'SET_FILTERS',
        filters: {
          state: this.state.state,
          subject: this.state.subject,
          setBy: this.state.setBy,
          assignedTo: this.state.assignedTo,
          setByOption: this.state.setByOption,
          searchQuery: this.state.searchQuery
        }
      })
    }

    _restoreStateFromRedux (newState) {
      var reduxState = HomeworkListStore.getState()

      if (reduxState && reduxState.filters) {
        newState.state = reduxState.filters.state
        newState.subject = reduxState.filters.subject
        newState.setBy = reduxState.filters.setBy
        newState.assignedTo = reduxState.filters.assignedTo
        newState.setByOption = reduxState.filters.setByOption
        newState.searchQuery = reduxState.filters.searchQuery
        this.inputSearch.value = reduxState.filters.searchQuery
      }
    }

    _onStateChange = (selection) => {
      if (selection === this.state.state) {
        return
      }

      let newState = {
        searchQuery: this.state.searchQuery,
        assignedTo: this.state.assignedTo,
        subject: this.state.subject,
        setBy: this.state.setBy,
        state: selection
      }

      this.onChange(newState, this.onChangeSequenceNumber++)

      this.setState({ state: selection }, this._saveStateToRedux)
    }

    _onSearchQueryChange = (query) => {
      this._debouncedSearchFilterChange(query.target.value) // Introduces a slight delay to search
    }

    _onSubjectChange = (selection) => {
      if (selection === this.state.subject) {
        return
      }

      let newState = {
        searchQuery: this.state.searchQuery,
        assignedTo: this.state.assignedTo,
        subject: selection,
        setBy: this.state.setBy,
        state: this.state.state
      }

      this.onChange(newState, this.onChangeSequenceNumber++)

      this.setState({ subject: selection }, this._saveStateToRedux)
    }

    _onSetByOptionChanged = (setByOption) => {
      if (setByOption === this.state.setByOption) {
        return
      }

      let setBy = []
      if (setByOption === this.setByOptions.mine) {
        setBy = [this.state.currentUser]
      }

      this._onSetByChange(setBy)

      this.setState({
        setByOption: setByOption
      }, this._saveStateToRedux)
    }

    _onSetByChange = (selection) => {
      if (selection === this.state.setBy) {
        return
      }

      let newState = {
        searchQuery: this.state.searchQuery,
        assignedTo: this.state.assignedTo,
        subject: this.state.subject,
        setBy: selection,
        state: this.state.state
      }

      this.onChange(newState, this.onChangeSequenceNumber++)

      this.setState({ setBy: selection }, this._saveStateToRedux)
    }

    _onStudentSelectorSelectionChanged = (selection) => {
      let newState = {
        searchQuery: this.state.searchQuery,
        assignedTo: selection,
        subject: this.state.subject,
        setBy: this.state.setBy,
        state: this.state.state
      }

      this.onChange(newState, this.onChangeSequenceNumber++)

      this.setState({ assignedTo: selection }, this._saveStateToRedux)
    }

    render () {
      return (<div id="homework-filters">

          <table width="100%">
              <tbody>
                  <tr>
                      <td>
                          <div className="filter-row">
                              <div className="filters-show">
                                  <label className="filters-label">Show</label>
                                  <ButtonGroup>
                                      {
                                        Object.keys(this.setByOptions).map((key) => {
                                          return (<Button key={this.setByOptions[key]} id={this.setByOptions[key]}
                                              onClick={() => { this._onSetByOptionChanged(this.setByOptions[key]) }}
                                              active={this.state.setByOption === this.setByOptions[key]}
                                              onChange={() => { }}
                                                  >
                                              {this.setByOptions[key]}
                                          </Button>)
                                        })
                                      }
                                  </ButtonGroup>

                              </div>

                              <div className="filters-set-by">
                                  <label className="filters-label">Set by</label>
                                  <div className="filters-typeahead-wrapper filters-set-by-typeahead-wrapper">
                                      <Typeahead
                                          labelKey={(option) => `${option.DisplaySurname}, ${option.DisplayForename}`}
                                          options={this.state.allTeachers}
                                          placeholder="All"
                                          onChange={this._onSetByChange}
                                          filterBy={['DisplaySurname', 'DisplayForename']}
                                          clearButton
                                          selected={this.state.setBy}
                                          disabled={this.state.setByOption === this.setByOptions.mine}
                                      />
                                  </div>
                              </div>
                          </div>

                          <div className="filter-row">
                              <div className="filters-subject ">
                                  <label className="filters-label">Subject</label>
                                  <div className="filters-typeahead-wrapper filters-typeahead-wrapper-subject">
                                      <Typeahead
                                          labelKey="Name"
                                          options={this.state.allSubjects}
                                          placeholder="Subject"
                                          filterBy={['Name', 'Code']}
                                          clearButton
                                          disabled={this.state.isLoading}
                                          selected={this.state.subject}
                                          onChange={this._onSubjectChange}
                                      />
                                  </div>
                              </div>

                              <div className="filters-assigned-to">
                                  <label style={{ width: '111px', marginLeft: '-41px' }} className="filters-label">Assigned to</label>
                                  <div className="student-selector-absolute-container">
                                      <StudentSelector
                                          id="studentSelectorComponent"
                                          students={this.state.assignedTo.students}
                                          groups={this.state.assignedTo.groups}
                                          onSelectedChanged={this._onStudentSelectorSelectionChanged}
                                          ref={(el) => { this.studentSelectorComponent = el }}
                                          disabled={this.state.isLoading}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="filter-row row-states">
                              <label className="filters-label">State</label>
                              <ButtonGroup>
                                  {
                                    Object.keys(this.stateOptions).map((key) => {
                                      return (<Button key={this.stateOptions[key].key} id={this.stateOptions[key].key}
                                          onClick={() => { this._onStateChange(this.stateOptions[key]) }}
                                          active={this.state.state.key === this.stateOptions[key].key}
                                          onChange={() => { }}
                                              >
                                          {this.stateOptions[key].text}
                                      </Button>)
                                    })
                                  }
                              </ButtonGroup>
                          </div>

                      </td>
                      <td className="right-search-filter">
                          <div className="filters-title-search-box right">
                              <div className="filters-title-search-box-absolute-container">
                                  <input type="Text"
                                      placeholder="Search title"
                                      className="form-control input"
                                      onChange={this._onSearchQueryChange}
                                      ref={el => { this.inputSearch = el }}
                                  />
                                  <i className="fa fa-search search-icon" aria-hidden="true" />
                              </div>
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>)
    }
}

export default Filters
