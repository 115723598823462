import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDataGrid from 'react-data-grid'

class GroupsGrid extends Component {
  constructor (props) {
    super(props)

    this._columns = this._columns = [{ key: 'name', name: 'Name' }]
  }

    state = {
      allGroups: [],
      filteredGroups: [],
      searchFilter: null,
      selectedIndexes: [],
      selectedGroups: []
    }

    componentDidMount () {
      this._initialise(this.props, this.props.selectedGroups)
    }

    componentWillReceiveProps (props) {
      let selectedGroups = this.state.selectedGroups.length ? this.state.selectedGroups : this.props.selectedGroups

      this._initialise(props, selectedGroups)

      if (props.selectedGroups.length === 0) {
        this.setState({
          selectedIndexes: [],
          selectedGroups: []
        })
      }
    }

    _initialise = (props, newlySelectedGroups) => {
      let currentSearchFilter = this.state.searchFilter

      let newSelectedRowIndexes = this._getSelectedRowIndexesFromSelectedGroups(newlySelectedGroups, props.allGroups)
      this.setState({
        allGroups: props.allGroups,
        filteredGroups: props.allGroups,
        selectedIndexes: newSelectedRowIndexes,
        selectedGroups: newlySelectedGroups
      })

      if (currentSearchFilter !== props.searchFilter) {
        let newFilteredGroups = this._filterGroups(props.allGroups, props.searchFilter)
        let newSelectedRowIndexes = this._getSelectedRowIndexesFromSelectedGroups(newlySelectedGroups, newFilteredGroups)

        this.setState({
          filteredGroups: newFilteredGroups,
          selectedIndexes: newSelectedRowIndexes
        })
      }
    }

    _filterGroups = (allGroups, searchFilter) => {
      if (searchFilter === null || searchFilter === '') {
        return allGroups
      }
      return allGroups.hwWhere(g => { return g.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1 })
    }

    _triggerSelectionChange = (selectedGroups) => {
      this.props.onSelectionChanged(selectedGroups)
    }

    _getSelectedRowIndexesFromSelectedGroups = (selectedGroups, filteredGroups) => {
      let selectedRowIndex = []
      for (let i = 0; i < filteredGroups.length; i++) {
        let group = filteredGroups[i]
        if (selectedGroups.hwContains(group.id)) {
          selectedRowIndex.push(i)
        }
      }
      return selectedRowIndex
    }

    _getGroupGuidFromIndex = (index, filteredGroups) => {
      if (filteredGroups[index]) {
        return filteredGroups[index].id
      }
      return null
    }

    _rowGetter = (i) => {
      return this.state.filteredGroups[i]
    };

    _onRowClick = (rowIdx) => {
      let groupId = this._getGroupGuidFromIndex(rowIdx, this.state.filteredGroups)

      if (groupId === null) {
        return
      }

      let newSelectedGroups = this.state.selectedGroups
      if (this.state.selectedGroups.hwContains(groupId)) {
        newSelectedGroups.hwRemove(groupId)
        let newSelectedRowIndexes = this._getSelectedRowIndexesFromSelectedGroups(newSelectedGroups, this.state.filteredGroups)
        this.setState({
          selectedGroups: newSelectedGroups,
          selectedIndexes: newSelectedRowIndexes
        })
        this._triggerSelectionChange(newSelectedGroups)
      } else {
        newSelectedGroups.push(groupId)
        let newSelectedRowIndexes = this._getSelectedRowIndexesFromSelectedGroups(newSelectedGroups, this.state.filteredGroups)
        this.setState({
          selectedGroups: newSelectedGroups,
          selectedIndexes: newSelectedRowIndexes
        })
        this._triggerSelectionChange(newSelectedGroups)
      }
    }
    _onRowsSelected = (rows) => {
      let newSelectedGroups = this.state.selectedGroups
      for (let i = 0; i < rows.length; i++) {
        let selectedRow = rows[i]
        let group = this._getGroupGuidFromIndex(selectedRow.rowIdx, this.state.filteredGroups)
        newSelectedGroups.push(group)
      }

      let newSelectedRowIndexes = this._getSelectedRowIndexesFromSelectedGroups(newSelectedGroups, this.state.filteredGroups)

      this.setState({
        selectedGroups: newSelectedGroups,
        selectedIndexes: newSelectedRowIndexes
      })
      this._triggerSelectionChange(newSelectedGroups)
    };

    _onRowsDeselected = (rows) => {
      let newSelectedGroups = this.state.selectedGroups
      for (let i = 0; i < rows.length; i++) {
        let selectedRow = rows[i]

        let group = this._getGroupGuidFromIndex(selectedRow.rowIdx, this.state.filteredGroups)
        newSelectedGroups.hwRemove(group)
      }

      let newSelectedRowIndexes = this._getSelectedRowIndexesFromSelectedGroups(newSelectedGroups, this.state.filteredGroups)

      this.setState({
        selectedGroups: newSelectedGroups,
        selectedIndexes: newSelectedRowIndexes
      })
      this._triggerSelectionChange(newSelectedGroups)
    };

    render () {
      return (
          <div className="student-selector-popout-grid-container">
              <ReactDataGrid
                  columns={this._columns}
                  rowGetter={this._rowGetter}
                  rowsCount={this.state.filteredGroups.length}
                  headerRowHeight={25}
                  minHeight={215}
                  rowHeight={26}
                  enableRowSelect='single'
                  onRowClick={this._onRowClick}
                  rowSelection={{
                  showCheckbox: true,
                  enableShiftSelect: true,
                  onRowsSelected: this._onRowsSelected,
                  onRowsDeselected: this._onRowsDeselected,
                  selectBy: {
                    indexes: this.state.selectedIndexes
                  }
                }}
              />
          </div>
      )
    }
}

PropTypes.props = {
  selectedGroups: PropTypes.array,
  onSelectionChanged: PropTypes.func,
  allGroups: PropTypes.array,
  searchFilter: PropTypes.string
}

export default GroupsGrid
