import { homeworkService } from '../../../Services/Homework/HomeworkService'
import { teacherService } from '../../../Services/TeacherService'
import { studentService } from '../../../Services/StudentService'
import React, { Component } from 'react'
import { StudentSelector, FieldValiationError, LinkToolbarButton, DatePicker } from '../../Common/Forms/bundle'
import SelectionPreview from '../../Common/Preview'
import Alert, { AlertType } from '../../Common/Alerts/Alert.js'
import DismissableInformation from '../../Common/Alerts/DismissableInformation.js'
import { ToRecipientType } from '../../../Stores/MessagesStore'
import LoDash from 'lodash'
import moment from 'moment'
import { appInsightsService } from '../../../Services/AppInsightsService'
import { Typeahead } from 'react-bootstrap-typeahead'
import scrollToComponent from 'react-scroll-to-component'
import Attachments from '../../Attachment/Attachments'
import ConfirmModal from '../../Common/ConfirmModal'
import { withRouter } from 'react-router-dom'
import Loady from '../../Common/Loady'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import './homework-edit.css'
import '../homework.css'
import { groupService } from '../../../Services/GroupService'
import Promise from 'bluebird'
import Button from 'react-bootstrap/lib/Button'
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup'
import * as Constants from '../../../Constants/constants'
import { schoolDetailsService } from '../../../Services/SchoolDetailsService'
import { schoolService } from '../../../Services/SchoolService'
import { userContextService } from '../../../Services/UserContextService'

class HomeworkEdit extends Component {
  constructor (props) {
    super(props)
    this.myRef = React.createRef()
  }

  state = {
    attachments: [],

    title: '',
    createdBy: '',
    assignedTo: {
      students: [],
      groups: [],
      studentsFromGroups: []
    },
    description: '',
    startDate: moment(),
    dueDate: moment().add(1, 'weeks'),
    subject: [], // typeaheads uses an array
    setBy: [], // typeaheads uses an array

    allTeachers: [],
    allSubjects: [],

    status: '',
    statusMessage: '',

    validationErrors: [],
    allowStudentAttachments: false,

    changeGroupsMembershipsInformation: {
      addedStudents: [],
      deletedStudents: []
    },
    allStudents: [],
    enableSendEmail: undefined

  }

  componentDidMount () {
    this.setState({
      isLoading: true,
      status: null
    })

    let loadSubjects = this._loadSubjects()
    let loadTeachers = this._loadTeachers()
    let loadHomework = this._loadHomework(this.props.match.params.homeworkId)
    this.promises.push(loadHomework)
    this._loadSchoolInformation()

    this.promises.push(Promise.all([loadHomework, loadSubjects]).then(result => {
      let subjectId = result[0].SubjectId
      let subject = LoDash.find(this.state.allSubjects, { 'SubjectId': subjectId })

      if (subject) {
        this.setState({
          subject: [subject]
        })
      }
    }))

    this.promises.push(Promise.all([loadHomework, loadTeachers]).then(result => {
      let setById = result[0].SetById
      let setBy = LoDash.find(this.state.allTeachers, { 'Id': setById })
      if (setBy) {
        this.setState({
          setBy: [setBy]
        })
      }
    }))

    this.promises.push(Promise.all([loadHomework, loadTeachers, loadSubjects]).then(() => {
      this.setState({ isLoading: false })

      this._setChangedStudentsInGroups()
    }).catch((result) => {
      let errorMessage = 'Failed to load homework. ' + result.message
      this.setState({
        status: AlertType.ERROR,
        statusMessage: errorMessage
      })
    }))
  }

  componentDidUpdate () {
    this._scrollToTopValidation()
  }

  componentWillUnmount () {
    this.promises.forEach(promise => {
      promise.cancel()
    })
    homeworkService.cancelRequest('edit')
  }

  promises = [];

  _loadHomework = function (homeworkId) {
    return Promise.all([homeworkService.getHomework(homeworkId), studentService.all()]).then(result => {
      let homework = result[0]
      let students = result[1]

      let allStudentIds = students.map(x => x.Id)

      this.setState({
        id: homework.Id,
        title: homework.Title,
        description: homework.Description,
        startDate: moment(homework.StartDate),
        dueDate: moment(homework.DueDate),
        assignedTo: {
          students: homework.Students.filter(value => allStudentIds.indexOf(value) !== -1),
          groups: homework.Groups,
          studentsFromGroups: homework.StudentsFromGroups
        },
        allowStudentAttachments: homework.AllowStudentAttachments,
        attachments: homework.Attachments,
        allStudents: students
      })

      return homework
    })
  }

  _loadSubjects = function () {
    return homeworkService.getSubjects().then(result => {
      this.setState({
        allSubjects: result
      })

      return result
    })
  }

  _loadSchoolInformation = function () {
    schoolService.getSchoolId().then(schoolId => {
      this.setState({ schoolId: schoolId })
      let schoolDetail = schoolDetailsService.getDetails(schoolId)
      let schoolTimezone = schoolDetailsService.getTimeZone(schoolId)
      Promise.all([schoolDetail, schoolTimezone]).then(response => {
        this.setState({ schoolName: response[0].SchoolName })
        this.setState({ schoolTimezone: response[1] })
      })
    })
  }

  _loadTeachers = function () {
    return teacherService.all().then((result) => {
      let orderedTeachers = LoDash.orderBy(result, 'DisplaySurname')
      this.setState({
        allTeachers: orderedTeachers
      })

      return result
    })
  }

  _getValidationMessage (id) {
    let error = this.state.validationErrors.hwFirstOrDefault(x => { return x.id === id })
    if (error) {
      return (<FieldValiationError message={error ? error.errorMessage : null} />)
    }
    return null
  }

  _getValidationMessages (id) {
    let errors = this.state.validationErrors.hwWhere(x => { return x.id === id })
    if (errors.length) {
      return errors.map(error => {
        return <FieldValiationError key={error.errorMessage} message={error ? error.errorMessage : null} />
      })
    }
    return null
  }

  _scrollToTopValidation () {
    if (!this.myRef.current) {
      return
    }

    let el = null
    let validationErrors = this.myRef.current.getElementsByClassName('field-validation-message')
    if (validationErrors.length) {
      el = validationErrors[0]
    }

    if (el) {
      scrollToComponent(el, { offset: 0, align: 'middle', duration: 500, ease: 'inCirc' })
    }
  }

  _hasFormGroupValidationError = (id) => {
    return this.state.validationErrors.hwFirstOrDefault(x => {
      return x.id === id
    }) ? 'form-group has-error' : 'form-group'
  }

  rejectValidationErrors = (id) => {
    this.setState({ validationErrors: LoDash.reject(this.state.validationErrors, { 'id': id }) })
  };

  _onSubjectChange = (selectedSubject) => {
    this.setState({ subject: selectedSubject })
    this.rejectValidationErrors('homework-subject')
  }

  _onSetByChange = (selection) => {
    this.setState({ setBy: selection })
    this.rejectValidationErrors('homework-set-by')
  }

  _onStudentSelectorSelectionChanged = (selection) => {
    this.setState({ assignedTo: selection })
    this.rejectValidationErrors('homework-assign-to')
  }

  _onTitleChange = (e) => {
    this.setState({ title: e.target.value })
    this.rejectValidationErrors('homework-title')
  }

  _onDescriptionChange = (e) => {
    this.setState({ description: e.target.value })
    this.rejectValidationErrors('homework-description')
  }

  _onAttachmentsChange = (attachments) => {
    // only successfully uploaded attachments are updated.  Errors are reported seperately
    this.setState({ attachments: attachments })
  }

  _setChangedStudentsInGroups = () => {
    let currentGroups = this.state.assignedTo.groups
    let currentStudents = LoDash.union(this.state.assignedTo.studentsFromGroups, this.state.assignedTo.students)
    return groupService.getStudentsInGroups(currentGroups).then((newStudentsInGroups) => {
      let newStudents = LoDash.union(newStudentsInGroups, this.state.assignedTo.students)
      this.setState({
        changeGroupsMembershipsInformation: {
          addedStudents: LoDash.difference(newStudents, currentStudents),
          deletedStudents: LoDash.difference(currentStudents, newStudents)
        }
      })
    })
  }

  _addLink = (url, descriptionElement) => {
    let textContent = this.state.description
    let descriptionWithLink = textContent.substring(0, descriptionElement.selectionStart) +
      url +
      textContent.substring(descriptionElement.selectionEnd)

    descriptionElement.focus()

    this.setState({ description: descriptionWithLink })
    this.rejectValidationErrors('homework-description')
  }

  _onStartDateChange = (date) => {
    this.setState({ startDate: date })
    this.rejectValidationErrors('homework-start-date')
  }

  _onDueDateChange = (date) => {
    this.setState({ dueDate: date })
    this.rejectValidationErrors('homework-due-date')
  }

  _onUploadingAttachmentsStatusChange = (isUploading) => {
    this.setState({ isUploadingAttachments: isUploading })
  }

  onAttachmentValidationErrorsChange = (validationErrors) => {
    const fieldId = 'homework-attachments'

    let filteredErrors = LoDash.reject(this.state.validationErrors, { 'id': fieldId })
    var mappedErrors = validationErrors.map(message => ({ id: fieldId, errorMessage: message }))

    this.setState({
      validationErrors: [...filteredErrors, ...mappedErrors]
    })
  }

  featureToggleOn () {
    let enableSendEmail = userContextService.checkFeatureToggle(Constants.EMAILFEATURETOGGLE)
    this.setState({ enableSendEmail: enableSendEmail })
  }

  _save = () => {
    this.setState({
      status: null,
      isSaving: true
    })

    const titleMaxLength = 100
    const descriptionMaxLength = 1000

    let title = this.state.title.trim()
    let description = this.state.description.trim()
    const startDate = moment(this.state.startDate).tz(this.state.schoolTimezone)
    const dueDate = moment(this.state.dueDate).tz(this.state.schoolTimezone)

    let validationErrors = []
    let studentlist = this.state.allStudents
    let schoolId = this.state.schoolId
    let schoolName = this.state.schoolName

    if (this.state.enableSendEmail === undefined) {
      this.featureToggleOn()
    }

    if (title === '') {
      validationErrors.push({ id: 'homework-title', errorMessage: 'Title is required.' })
    }

    if (title.length > titleMaxLength) {
      validationErrors.push({ id: 'homework-title', errorMessage: 'Title must be less than or equal to ' + titleMaxLength + ' characters.' })
    }

    if (!this.state.subject || this.state.subject.length === 0 || !this.state.subject[0]) {
      validationErrors.push({ id: 'homework-subject', errorMessage: 'Subject is required.' })
    }

    if (!this.state.setBy || this.state.setBy.length === 0 || !this.state.setBy[0]) {
      validationErrors.push({ id: 'homework-set-by', errorMessage: 'Set by is required.' })
    }

    if (description === '') {
      validationErrors.push({ id: 'homework-description', errorMessage: 'Description is required.' })
    }

    if (description.length > descriptionMaxLength) {
      validationErrors.push({ id: 'homework-description', errorMessage: 'Description must be less than or equal to ' + descriptionMaxLength + ' characters.' })
    }

    if (this.state.assignedTo.groups.length === 0 && this.state.assignedTo.students.length === 0) {
      validationErrors.push({ id: 'homework-assign-to', errorMessage: 'Assigned to is required.' })
    }

    if (!startDate.isValid()) {
      validationErrors.push({ id: 'homework-start-date', errorMessage: 'Available from must be a valid date.' })
    }

    if (!dueDate.isValid()) {
      validationErrors.push({ id: 'homework-due-date', errorMessage: 'Due date must be a valid date.' })
    }

    if (dueDate < startDate) {
      validationErrors.push({ id: 'homework-due-date', errorMessage: 'Due date must be after Available from.' })
    }

    if (validationErrors.length > 0) {
      this.setState({ validationErrors: validationErrors, isSaving: false })
      return
    }

    homeworkService.save(this.state.id, title, description, this.state.subject[0], this.state.setBy[0], this.state.assignedTo,
      this.state.attachments, startDate.startOf('day').format(), dueDate.startOf('day').format(), this.state.allowStudentAttachments)
      .then((homework) => {
        let successMessage = 'Homework saved successfully.'
        appInsightsService.logInfor(successMessage)

        var homeworkData = homework.data
        if (this.state.enableSendEmail && homeworkData.HomeworkStudentLinkSummary.length > 0) {
          let recipientInfo = []
          homeworkData.HomeworkStudentLinkSummary.forEach(homeworkLink => {
            let selectedStudent = studentlist && studentlist.length > 0 ? studentlist.hwFirstOrDefault(x => { return x.Id === homeworkLink.StudentId }) : null
            let studentInfo = selectedStudent ? { 'recipientId': homeworkLink.StudentId, 'recipientEmail': selectedStudent.Email, 'recipientName': selectedStudent.DisplayForename } : {}
            recipientInfo.push(studentInfo)
          })
          homeworkService.send(homeworkData, schoolName, recipientInfo).then((response) => {
            console.log(response.data)
            let successMessage = 'Homework email sent.'
            appInsightsService.logInfor(successMessage)
            appInsightsService.trackEvent('Email Sent', { ActiveSchoolId: schoolId })
          })
        }

        this.setState({
          status: AlertType.SUCCESS,
          statusMessage: successMessage,
          isSaving: false
        })

        this.promises.push(this._loadHomework(this.props.match.params.homeworkId).then(() => {
          this._setChangedStudentsInGroups()
        }))
      }).catch(result => {
        let errorMessage = 'Save failed. ' + result.message
        appInsightsService.logError(errorMessage)

        this.setState({
          status: AlertType.ERROR,
          statusMessage: errorMessage,
          isSaving: false
        })
      })
  }

  render () {
    let changedMembershipsMessage = ''
    if (this.state.changeGroupsMembershipsInformation.addedStudents.length > 0 || this.state.changeGroupsMembershipsInformation.addedStudents.length > 0) {
      changedMembershipsMessage = 'Groups members for this homework have changed since it was last saved. Saving the homework will update who can see the homework.'
    }

    return (
        <div className="homework-edit " ref={this.myRef}>
            <Loady isLoading={this.state.isLoading} />
            <div className="row">
                <Alert status={this.state.status} statusMessage={this.state.statusMessage} scrollOnShow />
                <DismissableInformation message={changedMembershipsMessage} />
            </div>
            <div className="row">
                <div id="homework-form" className="col-sm-8" >
                    <div className="form-horizontal">
                        <div className={this._hasFormGroupValidationError('homework-title')} >
                            <label htmlFor="homework-title" className="col-sm-2 control-label">Title</label>
                            <div className="col-sm-8">
                                <input id="homework-title"
                                    type="text"
                                    className="form-control"
                                    placeholder="Title"
                                    value={this.state.title}
                                    onChange={this._onTitleChange}
                                    disabled={this.state.isLoading}
                                />
                                {this._getValidationMessage('homework-title')}
                            </div>
                        </div>

                        <div className={this._hasFormGroupValidationError('homework-subject')}>
                            <label htmlFor="homework-subject" className="col-sm-2 control-label">Subject</label>
                            <div className="col-sm-5">
                                <Typeahead
                                    labelKey="Name"
                                    options={this.state.allSubjects}
                                    placeholder="Subject"
                                    filterBy={['Name', 'Code']}
                                    clearButton
                                    disabled={this.state.isLoading}
                                    selected={this.state.subject}
                                    onChange={this._onSubjectChange}
                                />
                                {this._getValidationMessage('homework-subject')}
                            </div>
                        </div>

                        <div className={this._hasFormGroupValidationError('homework-set-by')}>
                            <label htmlFor="homework-set-by" className="col-sm-2 control-label">Set by</label>
                            <div className="col-sm-5">
                                <Typeahead
                                    labelKey={(option) => `${option.DisplaySurname}, ${option.DisplayForename}`}
                                    options={this.state.allTeachers}
                                    placeholder="Set by"
                                    onChange={this._onSetByChange}
                                    filterBy={['DisplaySurname', 'DisplayForename']}
                                    clearButton
                                    disabled={this.state.isLoading}
                                    selected={this.state.setBy}
                                />
                                {this._getValidationMessage('homework-set-by')}
                            </div>
                        </div>

                        <div className={this._hasFormGroupValidationError('homework-assign-to')} >
                            <label htmlFor="homework-assign-to" className="col-sm-2 control-label">Assigned to</label>
                            <div className="col-sm-5">
                                <StudentSelector
                                    id="studentSelectorComponent"
                                    students={this.state.assignedTo.students}
                                    groups={this.state.assignedTo.groups}
                                    onSelectedChanged={this._onStudentSelectorSelectionChanged}
                                    ref={(el) => { this.studentSelectorComponent = el }}
                                    disabled={this.state.isLoading}
                                />
                                {this._getValidationMessage('homework-assign-to')}
                            </div>
                            <div className="col-sm-5">
                                <div className="homework-preview-link">
                                    <SelectionPreview
                                        title="Selected Students"
                                        previewLinkText="Preview Selection"
                                        selectedRecipientType={ToRecipientType.STUDENTS}
                                        selectedStudents={this.state.assignedTo.students}
                                        selectedGroups={this.state.assignedTo.groups}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={this._hasFormGroupValidationError('homework-start-date')} >
                            <label htmlFor="homework-start-date" className="col-sm-2 control-label">Available from</label>
                            <div className="col-sm-5">
                                <DatePicker
                                    id="homework-start-date"
                                    selected={this.state.startDate}
                                    onChange={this._onStartDateChange}
                                    disabled={this.state.isLoading}
                                />
                                {this._getValidationMessage('homework-start-date')}
                            </div>
                        </div>

                        <div className={this._hasFormGroupValidationError('homework-due-date')} >
                            <label htmlFor="homework-due-date" className="col-sm-2 control-label">Due date</label>
                            <div className="col-sm-5">
                                <DatePicker
                                    id="homework-due-date"
                                    selected={this.state.dueDate}
                                    onChange={this._onDueDateChange}
                                    disabled={this.state.isLoading}
                                />
                                {this._getValidationMessage('homework-due-date')}
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="homework-due-date" className="col-sm-2 control-label">Submission Type</label>
                            <div className="col-sm-5">
                                <ButtonGroup>
                                    <Button
                                        onClick={() => { this.setState({ allowStudentAttachments: true }) }}
                                        active={this.state.allowStudentAttachments}
                                        onChange={() => { }}
                                    >
                      Online Submission
                                    </Button>
                                    <Button
                                        onClick={() => { this.setState({ allowStudentAttachments: false }) }}
                                        active={!this.state.allowStudentAttachments}
                                        onChange={() => { }}
                                    >
                      In Person
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="homework-resources" className="col-sm-2 control-label">Attachments</label>
                            <div className="col-sm-10">
                                <Attachments
                                    ref={(el) => { this.attachmentComponent = el }}
                                    attachments={this.state.attachments}
                                    onChange={this._onAttachmentsChange}
                                    onValidationErrorsChange={this.onAttachmentValidationErrorsChange}
                                    onUploadingStatusChange={this._onUploadingAttachmentsStatusChange}
                                    consumerName={Constants.HOMEWORKCOMPONENT}
                                />
                                {this._getValidationMessages('homework-attachments')}
                            </div>
                        </div>

                        <div className={this._hasFormGroupValidationError('homework-description')}>
                            <label htmlFor="homework-description" className="col-sm-2 control-label">Description</label>
                            <div className="col-sm-10">
                                <div className="toolbar">
                                    <LinkToolbarButton bindTo={this.homeworkDescriptionElement} linkGenerated={(url) => this._addLink(url, this.homeworkDescriptionElement)} />
                                </div>
                                <textarea
                                    id="homework-description"
                                    className="form-control homework-description"
                                    rows="10"
                                    onChange={(e) => this._onDescriptionChange(e)}
                                    value={this.state.description}
                                    ref={el => { this.homeworkDescriptionElement = el }}
                                    disabled={this.state.isLoading}
                                />
                                {this._getValidationMessage('homework-description')}
                            </div>
                        </div>

                        <ConfirmModal
                            launchButtonText={this.state.isSaving ? 'Saving' : 'Save'}
                            confirmButtonText="Save"
                            bsStyle="primary"
                            modalBsStyle="warning"
                            modalTitle="Are You Sure?"
                            modalBody={<div><p>You're making changes to homework that could have been read and submitted by students.</p><p>Are you sure you want to save your changes?</p></div>}
                            disabled={this.state.isSaving || this.state.isLoading || this.state.isUploadingAttachments}
                            onConfirm={this._save}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default withRouter(HomeworkEdit)
