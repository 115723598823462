/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import { appInsightsService } from '../../Services/AppInsightsService'
import { schoolService } from '../../Services/SchoolService'

class Sickness extends Component {
  render () {
    let schoolId = schoolService.getSchoolId()
    Promise.all([schoolId]).then(function (results) {
      schoolId = results[0]
      appInsightsService.trackEvent('Clicked From Sickness menu ' + { ActiveSchoolId: schoolId })
    })

    return (
        <div></div>
    )
  }
}

export default Sickness
