import React, { Component } from 'react'
import PropTypes from 'prop-types'

class StatusIcon extends Component {
  _getIcon (status) {
   if (status === 'Handed in') {
      return <span className="handed-in-icon"><i className="fa fa-check status-icon" aria-hidden="true" /></span>
   }

   if (status === 'Handed in late') {
      return <span className="handed-in-late-icon"><i className="fa fa-check status-icon" aria-hidden="true" /></span>
   }

   if (status === 'Overdue') {
      return <span className="not-handed-in-icon"><i className="fa fa-times status-icon" aria-hidden="true" /></span>
   }

   if (status === 'Not set') {
      return <span className="not-set-icon"><i className="fa fa-minus status-icon" aria-hidden="true" /></span>
   }

   if (status === 'Set') {
      return <span className="due-icon"><i className="fa fa-minus status-icon" aria-hidden="true" /></span>
   }

   return null
  }

  render () {
      let status = this.props.status
      return this._getIcon(status)
  }
}

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired
}

export default StatusIcon
