import React, { Component } from 'react'
import { userContextService } from '../../../Services/UserContextService'
import { roleService } from '../../../Services/RoleService'
import { authService } from '../../../Services/AuthService'
import { routeConfiguration } from '../../../Services/RouteConfigurationService'

class AuthenticationRolesAndClaimsCheck extends Component {
  constructor (props) {
    super(props)

    if (authService.getStatus().isAuthenticated) {
      userContextService.get().then((userContext) => {
        if (!userContext.schoolId || !userContext.userId) {
          routeConfiguration.redirectToRoute('unauthorised')
          return
        }

        Promise.all([roleService.hasSchoolAdminRole(), roleService.hasEngagementRole(), roleService.hasStandaloneHomeworkRole()])
          .then((results) => {
            let schoolAdminRole = results[0]
            let engagmentRole = results[1]
            let standaloneHomeworkRole = results[2]
            let isSchoolAdminOrHasEngagementOrStandaloneHomeworkRole = (schoolAdminRole || engagmentRole || standaloneHomeworkRole)
            if (!isSchoolAdminOrHasEngagementOrStandaloneHomeworkRole) {
              routeConfiguration.redirectToRoute('unauthorised')
            }
          })
      })
    }
  }

  render () {
    return <div />
  }
}

export default AuthenticationRolesAndClaimsCheck
