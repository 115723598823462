import File from '../../Components/Common/FileIcons/file.svg'
import ZIP from '../../Components/Common/FileIcons/zip.svg'
import RFT from '../../Components/Common/FileIcons/rtf.svg'
import SVG from '../../Components/Common/FileIcons/svg.svg'
import AI from '../../Components/Common/FileIcons/ai.svg'
import AVI from '../../Components/Common/FileIcons/avi.svg'
import MP4 from '../../Components/Common/FileIcons/mp4.svg'
import PSD from '../../Components/Common/FileIcons/psd.svg'
import CSV from '../../Components/Common/FileIcons/csv.svg'
import MP3 from '../../Components/Common/FileIcons/mp3.svg'
import TXT from '../../Components/Common/FileIcons/txt.svg'
import PNG from '../../Components/Common/FileIcons/png.svg'
import JPG from '../../Components/Common/FileIcons/jpg.svg'
import PPT from '../../Components/Common/FileIcons/ppt.svg'
import XLS from '../../Components/Common/FileIcons/xls.svg'
import DOC from '../../Components/Common/FileIcons/doc.svg'
import PDF from '../../Components/Common/FileIcons/pdf.svg'

class HomeworkIconService {
  GetIcon (extension) {
    switch (extension.toLowerCase()) {
    case 'zip':
      return ZIP
    case 'rtf':
      return RFT
    case 'svg':
      return SVG
    case 'ai':
      return AI
    case 'avi':
      return AVI
    case 'mp4':
      return MP4
    case 'psd':
      return PSD
    case 'csv':
      return CSV
    case 'mp3':
      return MP3
    case 'txt':
      return TXT
    case 'png':
      return PNG
    case 'jpg':
    case 'jpeg':
      return JPG
    case 'pps':
    case 'ppsm':
    case 'ppsx':
    case 'pptm':
    case 'ppt':
    case 'pptx':
      return PPT
    case 'xls':
    case 'xlt':
    case 'xlsx':
    case 'xltm':
    case 'xltx':
      return XLS
    case 'doc':
    case 'docx':
      return DOC
    case 'pdf':
      return PDF
    default:
      return File
    }
  }
}

export default HomeworkIconService
