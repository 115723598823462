/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import LeftNav from '../LeftNav'
import Store from '../../../Stores/RouteStore'
import { routeConfiguration } from '../../../Services/RouteConfigurationService'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { HomeworkDashboard, HomeworkCatalogue, HomeworkEdit, HomeworkReports, HomeworkIndividualStudentView, HomeworkCreate, HomeworkHandedIn, HomeworkTeacherView } from '../../Homework/bundle'
import { Messages } from '../../Messages/bundle'
import { Sickness } from '../../Sickness/bundle'
import { Unauthorised, SignedOut } from '../../Auth/bundle'
import { Provider } from 'react-redux'
import { MessagesStore } from '../../../Stores/MessagesStore'
import { authService } from '../../../Services/AuthService'
import { userContextService } from '../../../Services/UserContextService'
import { roleService } from '../../../Services/RoleService'
import GoogleAnalytics from '../GoogleAnalytics'
import '../SosFont'

const portalRole = 1
const attendanceOfficerRole = 2
const parentAndAttendanceOfficerRole = 3
const parentProduct = 'parent'
const studentProduct = 'student'
const experimentalfeaturesProduct = 'experimentalfeatures'
const extracurricularProduct = 'extracurricular'
const standalonehomeworkProduct = 'standalonehomework'
const engagementProduct = 'sims-engagement'
const schoolAdminProduct = 'schooladmin'
const parentLiteProduct = 'parent-lite'

const MessagesWithProvider = () => (
    <Provider store={MessagesStore}>
        <Messages />
    </Provider>
)

class Contents extends Component {
  state = {
    path: '',
    currentPage: {}
  };

  componentDidMount () {
    let path = Store.getState().path
    this.setState({ path: path })

    let currentPageInfo = routeConfiguration.getCurrentPageInfo()
    this.setState({ currentPage: currentPageInfo })

    Store.subscribe(() =>
      this.setState({ path: Store.getState().path })
    )
    routeConfiguration.subscribeToCurrentPathChange((currentPage) => {
      this.setState({ currentPage: currentPage })

      let roleList = roleService.getProductList()
      let userContextServicePromise = userContextService.get()
      Promise.all([userContextServicePromise, roleList]).then(result => {
        this.checkAuthentication(result[0], result[1], currentPage.path)
      })
    })

    this.setState({ authToken: authService.getStatus().accessToken })
    authService.subscribeToAuthChanges(state => {
      this.setState({ authToken: state.accessToken })
    })
  }

  checkAuthentication (userContext, roleList, currentPagePath) {
    // checks if school has product licence
    let parentstudentEngagementEnabled = userContext.products.hwAny((p) => p.Name === parentProduct) || userContext.products.hwAny((p) => p.Name === studentProduct)
    let experimentalFeaturesEnabled = userContext.products.hwAny((p) => p.Name === experimentalfeaturesProduct)
    let activitiesEnabled = userContext.products.hwAny((p) => p.Name === extracurricularProduct)
    let standaloneHomeworkEnabled = userContext.products.hwAny((p) => p.Name === standalonehomeworkProduct)
    let parentLiteEnabled = userContext.products.hwAny((p) => p.Name === parentLiteProduct)

    // checks if user has particular product role
    let hasEngagementRole = parentstudentEngagementEnabled ? roleList.hwAny(p => p.ProductCode === engagementProduct && (p.Roles === portalRole || p.Roles === parentAndAttendanceOfficerRole)) : false
    let hasStandaloneHomeworkRole = standaloneHomeworkEnabled ? roleList.hwAny(p => p.ProductCode === standalonehomeworkProduct && p.Roles === portalRole) : false
    let hasSchoolAdminRole = roleList.hwAny((p) => p.ProductCode === schoolAdminProduct && p.Roles === portalRole)
    let hasNoRelevanceLicences = !parentstudentEngagementEnabled && !experimentalFeaturesEnabled && !activitiesEnabled && !standaloneHomeworkEnabled && !parentLiteEnabled

    let hasOnlyParentLiteLicence = !parentstudentEngagementEnabled && !experimentalFeaturesEnabled && !activitiesEnabled && !standaloneHomeworkEnabled && parentLiteEnabled
    let hasParentLiteAttendanceRole = parentLiteEnabled ? roleList.hwAny(p => p.ProductCode === engagementProduct && (p.Roles === attendanceOfficerRole || p.Roles === parentAndAttendanceOfficerRole)) : false
    let hasParentLiteSchoolAdminRole = parentLiteEnabled ? roleList.hwAny((p) => p.ProductCode === schoolAdminProduct && p.Roles === portalRole) : false
    let hasValidParentLiteLicenceAndRole = hasParentLiteAttendanceRole || hasParentLiteSchoolAdminRole

    if (hasNoRelevanceLicences) {
      this.setState({ routes: [] })
      routeConfiguration.redirectToRoute('noLicenses')
    }

    if (!hasEngagementRole && !hasSchoolAdminRole && !hasStandaloneHomeworkRole && !hasValidParentLiteLicenceAndRole) {
      this.setState({ routes: [] })
      routeConfiguration.redirectToRoute('unauthorised')
    }

    if (currentPagePath === '/messages' && !parentstudentEngagementEnabled) {
      this.setState({ routes: [] })
      routeConfiguration.redirectToRoute('unauthorised')
    }

    if (currentPagePath !== '/sickness' && hasOnlyParentLiteLicence) {
      this.setState({ routes: [] })
      routeConfiguration.redirectToRoute('unauthorised')
    }
  }

  render () {
    return (
        <div className="container-fluid main-container container-height">
            <div className="row">
                <LeftNav />
                <GoogleAnalytics />
                <Router>
                    <div className="page-content">
                        <div className="page-content-center">
                            <Switch>
                                <Route path="/homework/all-homework/sortBy/:sortOrder/setBy/:setById" component={HomeworkCatalogue} />
                                <Route path="/homework/dashboard" component={HomeworkDashboard} />
                                <Route path="/homework/all-homework" component={HomeworkCatalogue} />

                                <Route path="/homework/edit/:homeworkId" component={HomeworkEdit} />

                                <Route path="/homework/submissions/:homeworkId" component={HomeworkHandedIn} />

                                <Route path="/homework/teacherview/:homeworkId/:selectedStudentId" component={HomeworkTeacherView} />
                                <Route path="/homework/teacherview/:homeworkId" component={HomeworkTeacherView} />

                                <Route path="/homework/Create" component={HomeworkCreate} />

                                <Route path="/homework/reports" component={HomeworkReports} />
                                <Route path="/homework/studentview/:studentId" component={HomeworkIndividualStudentView} />

                                <Route path="/messages">
                                    <MessagesWithProvider />
                                </Route>

                                <Route path="/sickness" component={Sickness} />

                                <Route path="/noLicenses">
                                    <div className="panel panel-primary panel-default">
                                        <div className="panel-heading">Access unavailable</div>
                                        <div className="panel-body">
                                            <p>
                          SIMS Engagement is currently not available to your school.
                                            </p>
                                        </div>
                                    </div>
                                </Route>

                                <Route path="/Unauthorised" component={Unauthorised} />

                                <Route path="/signedOut" component={SignedOut} />
                            </Switch>
                        </div>
                    </div>
                </Router>
            </div>
        </div>
    )
  }
}

export default Contents
