import React, { Component } from 'react'
import DropDownMenu from './DropDownMenu'
import { authService } from '../../../../Services/AuthService'
import { userContextService } from '../../../../Services/UserContextService'
import onClickOutside from 'react-onclickoutside'
import { imageService } from '../../../../Services/ImageService'

class User extends Component {
   // eslint-disable-next-line react/sort-comp
   defaultState = {
      isOpen: false,
      isAuthenticated: false,
      user: {
        providername: '',
        identityProvider: '',
        schoolName: '',
        simsName: ''
      }
    }

    state = {
      ...this.defaultState
    }

    async _setPictureUrl () {
      if (authService.getStatus().isAuthenticated) {
        if (this.state.user && this.state.user.photoId) {
          let largePhotoSrc = imageService.getTeacher(this.state.user.schoolId, this.state.user.userId, this.state.user.photoId, 5)
          let smallPhotoSrc = imageService.getTeacher(this.state.user.schoolId, this.state.user.userId, this.state.user.photoId, 2)

          let results = await Promise.all([largePhotoSrc, smallPhotoSrc])

          this.setState(
            {
              largePhotoSrc: results[0],
              smallPhotoSrc: results[1]
            })
        }
      }
    }

    componentDidMount () {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ isAuthenticated: authService.getStatus().isAuthenticated })

      userContextService.get().then((userContext) => {
        this.setState(
          {
            user: {
              ...userContext
            }
          })

        this._setPictureUrl()
      })

      authService.subscribeToAuthChanges(async (authStatus) => {
        this.setState({ isAuthenticated: authStatus.isAuthenticated })

        if (authStatus.isAuthenticated) {
          let userContext = await userContextService.get()

          this.setState(
            {
              user: {
                ...userContext
              }
            })
          this._setPictureUrl()
        } else {
          this.setState({
            ...this.defaultState
          })
        }
      })
    }

    handleClickOutside = () => {
      this.setState({ isOpen: false })
    };

    toggleOpen (e) {
      this.setState({
        isOpen: !this.state.isOpen
      })
      e.preventDefault()
      e.stopPropagation()
    }

    render () {
      return (
          <li className="nav-user" onClick={(e) => { this.toggleOpen(e) }}>
              <a href="open-usermenu" className="nav-user-name dropdown-toggle" >
                  <span>
                      {this.state.smallPhotoSrc
                        ? <img src={this.state.smallPhotoSrc} className="user-mini-photo" alt="current user mini"/>
                        : <span className="user-image">
                            <i className="fa fa-user"></i>
                        </span>
                      }
                      <span className="pull-right nav-user-name-limit">
                          <span id="user-name" ng-show="userContext" className="ng-binding">{this.state.user.providername}&nbsp;<span className="caret" />
                              <br />{this.state.user.schoolName}
                          </span>
                      </span>
                  </span>
              </a>
              <DropDownMenu {...this.state} />
          </li>
      )
    }
}

export default onClickOutside(User)
