import React, { Component } from 'react'
import './handed-in-view.scss'
import { HomeworkTeacherViewService, HomeworkIconService } from '../../../../../Services/Homework/Bundle'
import HomeworkTeacherViewStore from '../../../../../Stores/HomeworkTeacherViewStore'
import FileHelper from '../../../../../Services/Utilities/FileHelper'
import BasicDownloader from './BasicDownloader'
import GradeAndComment from './GradeAndComment'
import NoAttachments from './NoAttachments'
import ImageViewer from './ImageViewer'
import { StudentHomeworkStatus } from '../../../../../Services/Homework/Enums'

class HandedInView extends Component {
    state = {
      activeStudentHomework: null,
      activeStudent: null,
      activeFile: null,
      tabMaxWidth: null
    }

    componentWillMount () {
      let mount = () => {
        let currentState = HomeworkTeacherViewStore.getState()

        if (this.state.activeStudent !== currentState.activeStudent) {
          this.setState({
            activeStudent: currentState.activeStudent
          })
        }

        if (currentState.activeStudentHomework === null) {
          this.setState({
            activeStudentHomework: null,
            activeFile: null
          })
          return
        }

        if (currentState.activeStudentHomework !== this.state.activeStudentHomework) {
          let activeFile = currentState.activeStudentHomework.StudentAttachments.hwFirstOrDefault()
          if (currentState.activeStudent && currentState.activeStudent.Status === StudentHomeworkStatus.MARKED) {
            activeFile = this.psudoFile
          }

          this.setState({
            activeStudentHomework: currentState.activeStudentHomework,
            activeFile: activeFile
          })
        }
      }

      mount()
      HomeworkTeacherViewStore.subscribe(() => {
        mount()
      })
    }

    componentDidMount () {
      window.addEventListener('resize', () => {
        this._adjustTabSize()
      })
      this._adjustTabSize()
    }

    componentDidUpdate () {
      this._adjustTabSize()
    }

    psudoFile = {
      FileName: 'Grade and Comment'
    }

    _adjustTabSize () {
      let tabs = this.refs['tabs']
      if (tabs) {
        let numberOfTabs = this.state.activeStudentHomework.StudentAttachments.length

        if (this.state.activeStudent && this.state.activeStudent.Status === StudentHomeworkStatus.MARKED) {
          numberOfTabs++
        }

        let newTabsMaxWidth = (tabs.clientWidth - 150) / numberOfTabs

        if (this.state.tabMaxWidth !== newTabsMaxWidth) {
          this.setState({
            tabMaxWidth: newTabsMaxWidth
          })
        }
      }
    }

    _makeFileActive (file) {
      if (this.state.activeFile !== file) {
        this.setState({
          activeFile: file
        })
      }
    }
    _getIconForFile (file) {
      let extension = FileHelper.getFileExtensionsFromFileName(file.FileName)
      var service = new HomeworkIconService()
      return service.GetIcon(extension)
    }

    _getFileRenderer = (file) => {
      if (!file) {
        return null
      }

      if (file === this.psudoFile) {
        return <GradeAndComment grade={this.state.activeStudentHomework.Result} comment={this.state.activeStudentHomework.Comments} />
      }

      let extension = FileHelper.getFileExtensionsFromFileName(file.FileName)
      switch (extension.toLowerCase()) {
      case 'pdf':
        let teacherViewService = new HomeworkTeacherViewService()
        if (teacherViewService.CanBrowserOpenPdf()) {
          return <iframe className="embedded-pdf ms-doc-viewer" title="embedded-pdf" src={file.UrlWithSasToken} />
        }

        return <BasicDownloader file={file} />
      case 'pptx':
      case 'docx':
      case 'doc':
        if (file.FileSize > 10485760) {
          file.Oversize = true
          return <BasicDownloader file={file} />
        }
        return <iframe className="ms-doc-viewer" title="microsoft-online" src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(file.UrlWithSasToken)} />
      case 'xlsx':
        if (file.FileSize > 5242880) {
          file.Oversize = true
          return <BasicDownloader file={file} />
        }
        return <iframe className="ms-doc-viewer" title="microsoft-online" src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(file.UrlWithSasToken)} />
      case 'mp3':
        return <div id="basic-downloader"><audio controls style={{ marginTop: '50px' }}><source src={file.UrlWithSasToken} type="audio/mpeg" /></audio></div>
      case 'png':
      case 'jpg':
      case 'gif':
      case 'jpeg':
        return <ImageViewer file={file}/>
      default:
        return <BasicDownloader file={file} />
      }
    }

    render () {
      if (this.state.activeStudentHomework) {
        return (

            <div id="handed-in-view" className="flex-box-columns">
                <div className="handed-in-view-tabs flex-box-header" ref={'tabs'}>

                    {this.state.activeStudent && this.state.activeStudent.Status === StudentHomeworkStatus.MARKED &&
                    <a href="GradeAndComment" onClick={(e) => { this._makeFileActive(this.psudoFile); e.preventDefault() }}>
                        <div className={(this.state.activeFile === this.psudoFile) ? 'handed-in-view-tab active' : 'handed-in-view-tab'} style={{ maxWidth: (this.state.tabMaxWidth !== null) ? this.state.tabMaxWidth + 'px' : null }}>
                            <i className="fa fa-check-square-o" aria-hidden="true" />&nbsp;
                            {this.psudoFile.FileName}
                        </div>
                    </a>
                    }

                    {this.state.activeStudentHomework.StudentAttachments && this.state.activeStudentHomework.StudentAttachments.map((s) => {
                      return (
                          <a key={s.Id} ref={s.Id} href={s.FileName} onClick={(e) => { this._makeFileActive(s); e.preventDefault(); e.target.scrollIntoView() }}>
                              <div className={(s === this.state.activeFile) ? 'handed-in-view-tab active' : 'handed-in-view-tab'} style={{ maxWidth: (this.state.tabMaxWidth !== null) ? this.state.tabMaxWidth + 'px' : null }}>
                                  <img className="file-icon" src={this._getIconForFile(s)} alt={s.FileName} height="15px" />
                                  {s.FileName}
                              </div>
                          </a>
                      )
                    })}
                </div>

                <div className="handed-in-view-body flex-box-content">

                    {
                      this._getFileRenderer(this.state.activeFile)
                    }

                    {!this.state.activeStudentHomework.StudentAttachments.length && this.state.activeStudent.Status === StudentHomeworkStatus.HANDED_IN &&
                    <NoAttachments />
                    }

                </div>
            </div>
        )
      } else {
        return <div> Loading ..</div>
      }
    }
}

export default HandedInView
