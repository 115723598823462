import React from 'react'
import PropTypes from 'prop-types'
import CustomDatePickerInput from './CustomDatePickerInput'
import ReactDatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './datePicker.css'

class DatePicker extends React.Component {
  render () {
    return (
        <ReactDatePicker
            id={this.props.id}
            className="form-control"
            selected={this.props.selected}
            onChange={this.props.onChange}
            dateFormat={this.props.dateFormat}
            disabled={this.props.disabled}
            customInput={<CustomDatePickerInput />}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            todayButton={this.props.todayButton}
        />
    )
  }
}

DatePicker.defaultProps = {
  dateFormat: 'll'
}

DatePicker.propTypes = {
  dateFormat: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.any
}

export default DatePicker
