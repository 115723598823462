/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import ConfirmModal from '../../Common/ConfirmModal'
import { ButtonToolbar, Button } from 'react-bootstrap'
import { FormatDate } from '../Common/Utils.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class HomeworkItem extends Component {
  render () {
    const homework = this.props.item
    const groups = this.props.groups

    return (

        <div id={homework.Id} className="homework-catelogue-list-item">
            <div className="homework-title">{homework.Title}</div>
            {homework.SetBy &&
            <div className="label-homework-set-buy-container" >
                <span className="label-homework-set-buy faint-text small-text" >Set by</span>
                <span className="homework-set-buy">{homework.SetBy}</span>
            </div>
            }

            <table id="homework-list-table">
                <tbody>
                    <tr>
                        <td className="emphasise-text homework-subject">{homework.Subject}</td>
                        <td className="date-label-properties faint-text homework-available-from">Available From</td>
                        <td className="date-value">{FormatDate(homework.StartDate)}</td>
                        <td>
                            <div className="homework-stats">
                                {homework.SubmissionCount}/{homework.TotalStudents}<span className="faint-text"> handed in</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td><LinkedEntities homework={homework} groups={groups}/></td>
                        <td className="date-label-properties faint-text">Due</td>
                        <td className="date-value">{FormatDate(homework.DueDate)}</td>
                    </tr>
                </tbody>
            </table>

            <div>
                <ButtonToolbar className="homework-actions-area">
                    <ConfirmModal launchButtonText={' Delete'}
                        confirmButtonText="Delete"
                        btnClasses="btn-xs delete-homework-btn"
                        iconClasses="fa fa-trash"
                        modalTitle="Are You Sure?"
                        modalBody={<div><p>You're deleting homework that could have been read and submitted by students.</p><p>Are you sure you want to delete?</p></div>}
                        onConfirm={() => homework.deleteHomeworkCallback(homework.Id)}
                    />
                    <Button className="edit-homework-btn" bsStyle="primary" bsSize="xsmall" href={'/#/homework/edit/' + homework.Id}><i className="fa fa-pencil-square-o" aria-hidden="true" /> Edit</Button>

                    {homework.AllowStudentAttachments &&
                    <Button className="homework-submissions-btn" bsStyle="primary" bsSize="xsmall" href={'/#/homework/teacherview/' + homework.Id + '?homeworktitle=' + encodeURIComponent(homework.Title)}><FontAwesomeIcon icon="users" /> Marking</Button>
                    }
                    {!homework.AllowStudentAttachments &&
                    <Button className="homework-submissions-btn" bsStyle="primary" bsSize="xsmall" href={'/#/homework/submissions/' + homework.Id}><FontAwesomeIcon icon="users" /> Submissions</Button>
                    }

                </ButtonToolbar>
            </div>
        </div>
    )
  }
}

export default HomeworkItem

class LinkedEntities extends Component {
  render () {
    let homework = this.props.homework
    let groups = this.props.groups

    return (
        <div className="linked-entities">
            {homework.AttachmentCount > 0 &&
            <span className="homework-attachment"><FontAwesomeIcon icon="paperclip" />({homework.AttachmentCount}) </span>
            }
            <Group groupId={homework.GroupId} groupCount={homework.GroupCount} groups={groups}/>
            {homework.GroupCount > 0 && homework.StudentCount > 0 &&
            <span> + </span>
            }
            <Student studentCount={homework.StudentCount}/>
        </div>
    )
  }
}

function Group (props) {
  const count = props.groupCount
  const id = props.groupId

  let groups = props.groups
  let groupText = null

  switch (count) {
  case 0:
    return null
  case 1:
    if (groups && groups.has(id)) {
      groupText = groups.get(id)
    } else {
      groupText = 'Unknown group'
    }
    break
  default:
    groupText = count + ' groups'
    break
  }
  return <span className="linked-entity-properties">{groupText}</span>
}

function Student (props) {
  const count = props.studentCount
  let studentText = null

  switch (count) {
  case 0:
    return null
  case 1:
    studentText = ' student'
    break
  default:
    studentText = ' students'
    break
  }
  return <span className="linked-entity-properties">{count + studentText}</span>
}
