import React, { Component } from 'react'
import { imageService } from '../../../Services/ImageService'
import './individual-student-view.scss'
import { homeworkService, StudentHomeworkOverviewService } from '../../../Services/Homework/Bundle'
import { schoolDetailsService } from '../../../Services/SchoolDetailsService'
import { userContextService } from '../../../Services/UserContextService'
import { studentService } from '../../../Services/StudentService'
import Dropster from '../../Common/Dropster'
import Loady from '../../Common/Loady'
import PageSelector from '../../../Components/Common/PageSelector'
import { FormatDate } from '../Common/Utils.js'
import StudentPercentage from '../Common/StudentPercentage'
import StudentPercentageToolTip from '../Common/StudentPercentageToolTip'
import StatusIcon from './StatusIcon'
import EmptyList from '../../Common/EmptyList'

class HomeworkIndividualStudentView extends Component {
  state = {
    allSubjects: [],
    isLoading: true,
    hasErrored: false,
    student: {
        Id: '',
        Name: '',
        Year: '',
        Reg: ''
    },
    stats: {
        handedInOnTime: 0,
        handedInLate: 0,
        due: 0,
        overdue: 0
    },
    tooltip: {
        isVisible: false,
        left: 0,
        top: 0,
        percentage: 0,
        total: 0,
        title: '',
        color: ''
    },
    allStudentOverviewItems: [],
    searchQuery: null,
    displayOrder: 'title',
    selectedSubject: 'all',
    page: [],
    totalRows: 0,
    pageSize: 5,
    currentPageNumber: 0,
    startDate: new Date(),
    endDate: new Date(),
    schoolId: null
  }

  widgetEl = React.createRef()

  orderBys = [
    {
        code: 'title',
        title: 'Title',
        helpText: 'alpabetical'
    },
    {
        code: 'dueDate',
        title: 'Due Date',
        helpText: 'Newest first'
    }
  ]

  individualTimeFrame = [
        {
            code: 'THIS_YEAR',
            title: 'This Year',
            helpText: 'This academic year'
        },
        {
            code: 'THIS_TERM',
            title: 'This Term',
            helpText: 'The current term'
        }
  ]

  _studentHomeworkOverviewService = new StudentHomeworkOverviewService();

componentDidMount () {
    userContextService.get().then(context => {
        let studentId = this.props.match.params.studentId
        this._getStudentAndImage(studentId)
        this._getSubjects()

        this._setTimeFrame(context.schoolId, 'THIS_YEAR').then(() => {
            this._getDataForTimeframe(studentId)
        })
    })
}

_getDataForTimeframe (studentId) {
    this.setState({ isLoading: true })
    this._studentHomeworkOverviewService.getStudentOverview(studentId, this.state.startDate, this.state.endDate).then(data => {
        let stats = {
            handedInOnTime: data.hwCount(x => { return x.Status === 'Handed in' }),
            handedInLate: data.hwCount(x => { return x.Status === 'Handed in late' }),
            notSet: data.hwCount(x => { return x.Status === 'Not set' }),
            due: data.hwCount(x => { return x.Status === 'Set' }),
            overdue: data.hwCount(x => { return x.Status === 'Overdue' })
        }

        this.setState({
            totalRows: data.length,
            allStudentOverviewItems: data,
            filteredStudentOverviewItems: data,
            stats: stats
        })

        this.setState({
            isLoading: false
        })
        this._preparePage()
    })
}

_getStudentAndImage (studentId) {
    studentService.getSingle(studentId).then(data => {
        this.setState({
            student: {
                Id: data.Id,
                Name: data.LegalForename + ' ' + data.LegalSurname,
                Year: 'Year ' + data.CurrentYearGroup,
                Reg: data.CurrentRegGroup
            }
        })

        // imageService.getStudent(data.SchoolGuid, data.Id, data.PhotoId, 2)
        //     .then((src) => {
        //         this.setState({ largePhotoSrc: src })
        //     })
        })
}

_getSubjects () {
    homeworkService.getSubjects().then(res => {
        let subjects = res.hwSelect((a) => {
            return {
                code: a.SubjectId,
                title: a.Name,
                helpText: null
            }
            })

            subjects.splice(0, 0, {
                code: 'all',
                title: 'All Subjects',
                helpText: null
            })
        this.setState({
            allSubjects: subjects
        })
    })
}

_onSubjectChanged = (subjectId) => {
    this.setState({
        selectedSubject: subjectId
    }, this._preparePage)
}

_pageChanged = (newPage) => {
    this.setState({
        currentPageNumber: newPage.selected
    },
    this._preparePage)
}

_preparePage = () => {
        let items = this.state.allStudentOverviewItems

        if (this.state.selectedSubject !== 'all') {
            items = items.hwWhere((x) => { return x.SubjectId === this.state.selectedSubject })
        }

        if (this.state.searchQuery) {
            items = items.hwWhere(x => { return x.Title.toLowerCase().includes(this.state.searchQuery) })
        }

        let properySelector
        switch (this.state.displayOrder) {
            case 'title':
                properySelector = (x) => x.Title
                break
            case 'dueDate':
                properySelector = (x) => x.DueDate
                break
        }

        items.sort(function (a, b) {
            var propA = properySelector(a)
            var propB = properySelector(b)

            if (propA > propB) {
                return 1
            }

            if (propA < propB) {
                return -1
            }

            return 0
        })

        let totalRows = items.length
        let currentPageNumber = this._movePageToMaximumInRangeIfRequired(totalRows)

        this.setState({
            page: items.hwGetPage(this.state.pageSize, currentPageNumber + 1),
            currentPage: currentPageNumber,
            totalRows: totalRows
        })
}

_onOrderByChanged = (newOrderBy) => {
    this.setState({
        displayOrder: newOrderBy
    }, this._preparePage)
}

_onSearchQueryChange = (ref) => {
    this.setState({
        searchQuery: ref.target.value.toLowerCase()
    }, this._preparePage)
}

_movePageToMaximumInRangeIfRequired (totalRows) {
        let numberOfPages = Math.ceil(totalRows / this.state.pageSize)
        let currentPageNumber = this.state.currentPageNumber
        if ((currentPageNumber + 1) > numberOfPages) {
            currentPageNumber = numberOfPages - 1
        }
        return currentPageNumber
    }

_showStudentToolTip (event, args) {
    let boundingRect = this.widgetEl.current.getBoundingClientRect()
    let leftOffset = 300
    let topOffset = 90

    this.setState({
        tooltip: {
            left: event.clientX - boundingRect.left + leftOffset,
            top: event.clientY - boundingRect.top + topOffset,
            isVisible: true,
            title: args.name,
            percentage: args.percentage.toFixed(2),
            color: args.color,
            total: args.total
        }
    })
}

_hideStudentToolTip () {
    let tooltip = this.state.tooltip
    tooltip.isVisible = false

    this.setState({
        tooltip: tooltip
    })
}

_setTimeFrame (schoolId, timeFrame) {
    if (timeFrame === 'THIS_YEAR') {
        return new Promise((resolve) => {
            schoolDetailsService.getCurrentYear(schoolId).then(y => {
                this.setState({
                    startDate: y.StartDate,
                    endDate: y.EndDate
                }, () => { resolve() })
            })
        })
    } else if (timeFrame === 'THIS_TERM') {
        return new Promise((resolve) => {
            schoolDetailsService.getCurrentTerm(schoolId).then(y => {
                this.setState({
                    startDate: y.StartDate,
                    endDate: y.EndDate
                }, () => { resolve() })
            })
        })
    }
}

_timeFrameChange (selection) {
    userContextService.get().then(context => {
        this._setTimeFrame(context.schoolId, selection).then(() => {
            let studentId = this.props.match.params.studentId
            this._getDataForTimeframe(studentId)
        })
    })
}

render () {
    return (
        <div className="individual-student-view">

            <Loady isLoading={this.state.isLoading && !this.state.hasErrored}/>
            <div className="pull-right">
                <Dropster options={this.individualTimeFrame} narativeText="Timeframe" onChange={x => this._timeFrameChange(x)} />
            </div>
            <div className="individual-student-view-header">
                <table>
                    <tbody>
                        <tr>
                            <td style={{ display: this.state.largePhotoSrc ? 'block' : 'none' }}>
                                <img src={this.state.largePhotoSrc} />
                            </td>
                            <td style={{ verticalAlign: 'top' }} >
                                <div style={{ marginLeft: this.state.largePhotoSrc ? '0' : '-37px' }}>
                                    <div className="student-name">
                                        {this.state.student.Name}
                                    </div>
                                    <div className="year">
                                        {this.state.student.Year}
                                    </div>
                                    <div className="reg">
                                        {this.state.student.Reg}
                                    </div>
                                    <div className="student-percentage-header" ref={this.widgetEl}>
                                        <StudentPercentage row={{
                                    HandedInOnTime: this.state.stats.handedInOnTime,
                                    HandedInLate: this.state.stats.handedInLate,
                                    TotalOverdue: this.state.stats.overdue,
                                    TotalDue: this.state.stats.due
                                    }} onMouseEnter={(e, args) => this._showStudentToolTip(e, args)} onMouseOut={() => this._hideStudentToolTip()}
                                        />

                                        <StudentPercentageToolTip
                                            color={this.state.tooltip.color}
                                            isVisible={this.state.tooltip.isVisible}
                                            top={this.state.tooltip.top}
                                            left={this.state.tooltip.left}
                                            title={this.state.tooltip.title}
                                            percentage={this.state.tooltip.percentage}
                                            total={this.state.tooltip.total}
                                        />

                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="widget-container student-widget">
                <div className="widget">
                    <div className="widget-header">
                        <div className="widget-drops">
                            <div className="widget-header-cell widget-title">
                                Overview
                            </div>
                            <div className="widget-header-cell widget-drop filter-drop">
                                <div className="filters-title-search-box">
                                    <div className="filters-title-search-box-absolute-container">
                                        <input type="Text"
                                            placeholder="Search title"
                                            className="form-control input"
                                            onChange={this._onSearchQueryChange}
                                            ref={el => { this.inputSearch = el }}
                                        />
                                        <i className="fa fa-search search-icon" aria-hidden="true" />
                                    </div>
                                </div>
                            </div>
                            <div className="widget-header-cell widget-drop">
                                <Dropster options={this.orderBys} narativeText="Order By" onChange={this._onOrderByChanged} />
                            </div>
                            <div className="widget-header-cell widget-drop">
                                {this.state.allSubjects &&
                                    <Dropster options={this.state.allSubjects} narativeText="Subject" onChange={this._onSubjectChanged} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={(this.state.isLoading || this.state.hasErrored) ? 'widget-content empty-list' : 'widget-content'} style={{ maxHeight: 'none', width: '100%' }}>
                        {this.state.page.length > 0 &&
                        <>
                            <div className="student-homework-list">
                                {this.state.page.map(x => {
                                            return (
                                                <div key={x.HomeworkId} className="homework-catelogue-list-item">
                                                    <div className="homework-title">{x.Title}</div>
                                                    <div className="label-homework-set-buy-container">
                                                        <span className="label-homework-set-buy faint-text small-text">Set by</span>
                                                        <span className="homework-set-buy">{x.SetBy}</span></div>
                                                    <table className="student-homework-list-table">
                                                        <tbody>
                                                            <tr>
                                                                <td className="emphasise-text homework-subject">{x.Subject}</td>
                                                                <td className="date-label-properties faint-text homework-available-from">Available From</td>
                                                                <td className="date-value">{FormatDate(x.StartDate)}</td>
                                                                <td />
                                                            </tr>
                                                            <tr>
                                                                <td />
                                                                <td className="date-label-properties faint-text">Due</td>
                                                                <td className="date-value">{FormatDate(x.ExtendedDudeDate || x.DueDate)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="hand-in-status">

                                                        {x.Result && x.Result !== null &&
                                                            <>
                                                                <span className="faint-text">Mark</span>
                                                                <span className="mark-value emphasise-text">{x.Result}</span>
                                                            </>
                                                        }

                                                        <span className="faint-text">Status</span>
                                                        <span className="hand-in-status-text emphasise-text">{x.Status}</span>

                                                        <StatusIcon status={x.Status} />
                                                    </div>
                                                    <div className="student-action-buttons">
                                                        <a className="btn btn-xs btn-primary marking-button" href={`/#/homework/teacherview/${x.HomeworkId}/${this.state.student.Id}?homeworktitle=Long%20shore%20drift`}>
                                                            <i className="fa fa-users" aria-hidden="true" />&nbsp;Marking
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                            </div>

                            <PageSelector pageCount={(this.state.totalRows / this.state.pageSize)} onPageChange={this._pageChanged} selectedPage={this.state.currentPageNumber} />
                        </>
                    }

                        {!this.state.isLoading && this.state.page.length === 0 &&
                        <EmptyList />
                    }

                    </div>

                </div>
            </div>
        </div>
    )
  }
}

export default HomeworkIndividualStudentView
