const ValidateValue = (value, obj) => {
  let enumIncludesValue = Object.values(obj).includes(value)

  if (!enumIncludesValue) {
    throw new Error(`${value} is not valid value of object. Valid valuse are ^${Object.values(obj).join('|')}$`)
  }
}

const StudentHomeworkStatus = Object.freeze({
  NOT_HANDED_IN: 'Not Handed In',
  HANDED_IN: 'Handed In',
  MARKED: 'Marked'
})

const HomeworkTotalsDimensions = Object.freeze({
  YEAR: 'year',
  REG_GROUP: 'regGroup',
  HOUSE: 'house',
  GENDER: 'gender',
  SUBJECT: 'subject',
  TEACHER: 'teacher',
  SUBJECT_BY_YEAR: 'subjectByYear'
})

const DashboardTimeFrame = Object.freeze({
  THIS_NC_YEAR: 'academicYear',
  THIS_TERM: 'term'
})

const DashboardStudentSortOrder = Object.freeze({
  NOT_HANDED_IN_DESC: 'overdue-desc',
  NOT_HANDED_IN_ASC: 'overdue-asc',
  HANDED_IN_ASC: 'handedin-asc',
  HANDED_IN_DESC: 'handedin-desc',
  LATE_ASC: 'late-asc',
  LATE_DESC: 'late-desc',
  DUE_ASC: 'due-asc',
  DUE_DESC: 'due-desc',
  NAME_DESC: 'name-desc',
  NAME_ASC: 'name-asc'
})

const Nothing = Object.freeze({
})

export {
  StudentHomeworkStatus,
  HomeworkTotalsDimensions,
  Nothing,
  DashboardTimeFrame,
  ValidateValue,
  DashboardStudentSortOrder
}
