import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faExclamation, faHourglassEnd, faPaw } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleDown, faClock } from '@fortawesome/free-regular-svg-icons'
import onClickOutside from 'react-onclickoutside'
import './homework-handed-in.css'

class HomeworkStatusLegend extends Component {
    state = {
      isPopupDisplayed: false
    };

    _displayLegend = () => {
      this.setState({ isPopupDisplayed: !this.state.isPopupDisplayed })
    }

    handleClickOutside = () => {
      this.setState({ isPopupDisplayed: false })
    };

    legendItems = [
      {
        id: 'waiting',
        icon: faClock,
        iconClassName: 'still-waiting-icon',
        title: ' Not yet handed in'
      },
      {
        id: 'handedIn',
        icon: faArrowAltCircleDown,
        iconClassName: 'handed-in-icon',
        title: ' Handed in'
      },
      {
        id: 'late',
        icon: faHourglassEnd,
        iconClassName: 'handed-in-late-icon',
        title: ' Handed in late'
      },
      {
        id: 'overdue',
        icon: faExclamation,
        iconClassName: 'overdue-icon',
        title: ' Overdue'
      },
      {
        id: 'dog',
        icon: faPaw,
        iconClassName: 'dog-ate-it-icon',
        title: ' The dog ate it'
      }
    ];

    _getLegendItems = () => {
      let items = this.legendItems.map((l) => {
        return (
            <div id={l.id} className="info-drop-down-item">
                <FontAwesomeIcon icon={l.icon} className={'legend-icon ' + l.iconClassName}/><span className="legend-text"> {l.title}</span>
            </div>
        )
      })

      return (
          <div id="info-drop-down">
              <div>
                  <h5>Legend</h5>
              </div>
              {items}
          </div>
      )
    }

    render () {
      return (
          <div id="status-legend" onClick={this._displayLegend} >
              <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
              {this.state.isPopupDisplayed &&
                    this._getLegendItems()
              }
          </div>
      )
    }
}

export default onClickOutside(HomeworkStatusLegend)
