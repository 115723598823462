import APIService from '../APIService'
import Configuration from '../ConfigurationService'
import sscache from 'session-storage-cache'
import LoDash from 'lodash'

class HomeworkService {
  constructor () {
    this.instance = APIService.create(Configuration.HomeworkUrl)
    this.homeworkAttachmentInstance = APIService.create(Configuration.HomeworkUrl, 60 * 1000, 'multipart/form-data')
    this.homeworkEmailInstance = APIService.create(Configuration.MessagesUrl)
  }

  cancelTokens = {
    createHomework: {
      id: 'create',
      cancelTokenSource: null
    },
    editHomework: {
      id: 'edit',
      cancelTokenSource: null
    },
    deleteHomework: {
      id: 'delete',
      cancelTokenSource: null
    },
    saveStudentLinks: {
      id: 'saveStudentLinks',
      cancelTokenSource: []
    },
    saveMark: {
      id: 'saveMark',
      cancelTokenSource: []
    }
  };

  cancelRequest (requestName) {
    let cancelToken = LoDash.find(this.cancelTokens, { 'id': requestName })
    if (cancelToken) {
      if (Array.isArray(cancelToken.cancelTokenSource)) {
        cancelToken.cancelTokenSource.forEach(tokenSource => {
          tokenSource.cancel('Cancelled by user')
        })
        cancelToken.cancelTokenSource = []
      } else {
        if (cancelToken.cancelTokenSource !== null) {
          cancelToken.cancelTokenSource.cancel('Cancelled by user')
        }
      }
    }
  }

  getHomeworkList (props) {
    let setById = props.filters.setBy && props.filters.setBy.length === 1 ? props.filters.setBy[0].Id : null
    let subjectId = props.filters.subject && props.filters.subject.length === 1 ? props.filters.subject[0].SubjectId : null

    let data = {
      SortOrder: props.sortOrder,
      Page: props.page,
      PageSize: props.pageSize,
      SetBy: setById,
      SearchQuery: props.filters.searchQuery,
      Subject: subjectId,
      Groups: props.filters.groups,
      Students: props.filters.students,
      State: props.filters.state
    }

    return this.instance.post('/v3/HomeworkManagement/GetHomeworkList', data).then((response) => {
      return response.data
    })
  }

  create (
    title,
    description,
    subject,
    createdBy,
    setBy,
    assignedTo,
    attachments,
    startDate,
    dueDate,
    allowStudentAttachments
  ) {
    let cancelTokenSource = APIService.getCancelTokenSource()
    this.cancelTokens.createHomework.cancelTokenSource = cancelTokenSource

    var createdByTitleAndName = (createdBy.Title ? createdBy.Title + ' ' : '') + createdBy.DisplayForename.charAt(0) + ' ' + createdBy.DisplaySurname
    var setByTitleAndName = (setBy.Title ? setBy.Title + ' ' : '') + setBy.DisplayForename.charAt(0) + ' ' + setBy.DisplaySurname

    let data = JSON.stringify({
      Title: title,
      Description: description,
      SubjectId: subject.SubjectId,
      Subject: subject.Name,
      CreatedById: createdBy.Id,
      CreatedBy: createdByTitleAndName,
      SetById: setBy.Id,
      SetBy: setByTitleAndName,
      Source: 'homework-online',
      Students: assignedTo.students,
      Groups: assignedTo.groups,
      Attachments: attachments,
      StartDate: startDate,
      DueDate: dueDate,
      AllowStudentAttachments: allowStudentAttachments
    })

    return this.instance.post('/v3/HomeworkManagement/Create', data, {
      cancelToken: cancelTokenSource.token
    })
  }

  save (id, title, description, subject, setBy, assignedTo, attachments, startDate, dueDate, allowStudentAttachments) {
    let cancelTokenSource = APIService.getCancelTokenSource()
    this.cancelTokens.editHomework.cancelTokenSource = cancelTokenSource

    var setByTitleAndName = (setBy.Title ? setBy.Title + ' ' : '') + setBy.DisplayForename.charAt(0) + ' ' + setBy.DisplaySurname

    let data = JSON.stringify({
      Id: id,
      Title: title,
      Description: description,
      SubjectId: subject.SubjectId,
      Subject: subject.Name,
      SetById: setBy.Id,
      SetBy: setByTitleAndName,
      Source: 'homework-online',
      Students: assignedTo.students,
      Groups: assignedTo.groups,
      Attachments: attachments,
      StartDate: startDate,
      DueDate: dueDate,
      AllowStudentAttachments: allowStudentAttachments
    })

    return this.instance.post('/v3/HomeworkManagement/Edit', data, {
      cancelToken: cancelTokenSource.token
    })
  }

  deleteHomework (homeworkId) {
    let cancelTokenSource = APIService.getCancelTokenSource()
    this.cancelTokens.deleteHomework.cancelTokenSource = cancelTokenSource

    const address = `/v3/HomeworkManagement/DeleteHomework/${homeworkId}`
    return this.instance.delete(address, {
      cancelToken: cancelTokenSource.token
    }).then((response) => {
      return response.data
    })
  }

  getHomework (homeworkId) {
    const address = `/v3/HomeworkManagement/GetHomework/${homeworkId}`

    return this.instance.get(address).then((response) => {
      return response.data
    })
  }

  getHomeworkStudents (homeworkId) {
    const address = `/v3/HomeworkManagement/GetHomeworkStudents/${homeworkId}`

    return this.instance.get(address).then((response) => {
      return response.data
    })
  }

  getSubjects () {
    const address = '/v3/HomeworkManagement/Subjects'
    const cacheKey = 'homework-service/' + address
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get(address).then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  getAllowedAttachmentExtensions () {
    const address = '/v3/HomeworkManagement/GetValidAttachmentFileTypes'
    const cacheKey = 'homework-service/' + address
    const cacheTimeInMinutes = 30

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get(address).then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  getStudentHomework (studentId, homeworkId) {
    const address = `/v3/HomeworkManagement/GetStudentSubmission/${studentId}/${homeworkId}`
    return this.instance.get(address).then((response) => {
      return response.data
    })
  }

  async isFileExtensionValid (fileExtension) {
    var validFileExtensions = await this.getAllowedAttachmentExtensions()
    validFileExtensions = validFileExtensions.hwSelect(x => x.toLowerCase())
    return validFileExtensions.hwContains(fileExtension.toLowerCase())
  }

  saveStudentLinks (homeworkId, students) {
    let cancelTokenSource = APIService.getCancelTokenSource()
    this.cancelTokens.saveStudentLinks.cancelTokenSource.push(cancelTokenSource)

    let data = JSON.stringify({
      Id: homeworkId,
      Students: students
    })

    return this.instance.post('/v3/HomeworkManagement/UpdateHomeworkStudents', data, {
      cancelToken: cancelTokenSource.token
    })
  }

  saveHomeworkMark (studentId, homeworkId, result, comments) {
    let cancelTokenSource = APIService.getCancelTokenSource()
    this.cancelTokens.saveMark.cancelTokenSource.push(cancelTokenSource)

    let data = JSON.stringify({
      StudentId: studentId,
      HomeworkId: homeworkId,
      Result: result,
      Comments: comments
    })

    return this.instance.post('/v3/HomeworkManagement/SaveHomeworkMark', data, {
      cancelToken: cancelTokenSource.token
    })
  }
  getMaxFileSize () {
    const oneMeg = 1048576
    const maxFileSize = 150 * oneMeg
    return maxFileSize
  }
  checkFileSize (file) {
    let maxFileSize = this.getMaxFileSize()
    if (file.size > maxFileSize) {
      return true
    }
    return false
  }
  upload (formData, config) {
    return this.homeworkAttachmentInstance.post('/v3/HomeworkManagement/UploadAttachment', formData, config)
  }
  send (homeworkDetail, schoolName, recipientInfo) {
    let homeworkNotification = { HomeworkDetail: homeworkDetail, SchoolName: schoolName }
    return this.homeworkEmailInstance.post('/Notification/NotifyHomework', {
      HomeworkNotification: homeworkNotification,
      RecipientInfo: recipientInfo
    })
  }

  sendHomeworkMarked (feedbackDetail, schoolName, recipientsInfo) {
    let feedbackNotification = { feedbackDetail: feedbackDetail, schoolName: schoolName }
    return this.homeworkEmailInstance.post('/Notification/NotifyFeedback', {
      FeedbackNotification: feedbackNotification,
      RecipientsInfo: recipientsInfo
    })
  }
}

export let homeworkService = new HomeworkService()
