/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './data-list.scss'

class DataList extends Component {
  render () {
    let data = this.props.items

    if (!data || !data.length) {
        return null
    }

    return (
        <table className="data-list table table-striped table-hover">
            <thead>
                <tr>
                    {this.props.headerTemplate()}
                </tr>
            </thead>
            <tbody>
                {data.map(x => (<tr key={x.Id} className="data-list-item">{this.props.itemTemplate(x)}</tr>))}
            </tbody>
        </table>
    )
  }
}

DataList.propTypes = {
    headerTemplate: PropTypes.func.isRequired,
    itemTemplate: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    minItems: PropTypes.number.isRequired
}

export default DataList
