import { createStore, compose, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import { AlertType } from '../../Components/Common/Alerts/Alert.js'

let ToRecipientType = {
  STUDENTS: 'Students',
  PARENTS: 'Parents',
  STUDENTANDPARENTS: 'Students and Parents'
}

let MessagesStatus = {
  NONE: 'None',
  SENDING: 'Sending',
  INFO: AlertType.INFO,
  SUCCESS: AlertType.SUCCESS,
  ERROR: AlertType.ERROR
}

var initialState = {
  to: {
    groups: [],
    students: []
  },
  visibleTo: ToRecipientType.STUDENTANDPARENTS,
  calculatedRecipientIds: [],
  selectedReceipientType: '',
  focusedElement: '',
  subject: '',
  subjectSelectionStart: 0,
  subjectSelectionEnd: 0,
  message: '',
  textAreaSelectionStart: 0,
  textAreaSelectionEnd: 0,

  validationErrors: [],
  status: MessagesStatus.NONE
}

const ActionTypes = {
  'ON_TAG_CLICK': 'ON_TAG_CLICK',
  'STUDENT_SELECTION_CHANGED': 'STUDENT_SELECTION_CHANGED',
  'RECIPIENT_CHANGED': 'RECIPIENT_CHANGED',
  'MESSAGE_SENDING': 'MESSAGE_SENDING',
  'MESSAGE_SENT_SUCCESS': 'MESSAGE_SENT_SUCCESS',
  'MESSAGE_SENT_FAILURE': 'MESSAGE_SENT_FAILURE',
  'MESSAGE_VALIDATION_ERROR': 'MESSAGE_VALIDATION_ERROR',
  'MESSAGE_CLEAR_ALL': 'MESSAGE_CLEAR_ALL'
}

const reducer = (state, action) => {
  switch (action.type) {
  case ActionTypes.ON_TAG_CLICK:
    if (action.focusedElement === 'messageTextArea') {
      let textContent = action.message
      let firstPart = textContent.substring(0, action.textAreaSelectionStart) + action.tag
      let newTextFull = firstPart + textContent.substring(action.textAreaSelectionEnd)
      return {
        ...state,
        message: newTextFull,
        textAreaSelectionStart: firstPart.length,
        textAreaSelectionEnd: firstPart.length,
        focusedElement: action.focusedElement,
        validationErrors: action.validationErrors
      }
    } else {
      let textContent = action.subject
      let firstPart = textContent.substring(0, action.subjectSelectionStart) + action.tag
      let newTextFull = firstPart + textContent.substring(action.subjectSelectionEnd)
      return {
        ...state,
        subject: newTextFull,
        subjectSelectionStart: firstPart.length,
        subjectSelectionEnd: firstPart.length,
        focusedElement: action.focusedElement,
        validationErrors: action.validationErrors
      }
    }

  case ActionTypes.RECIPIENT_CHANGED:
    return {
      ...state,
      selectedReceipientType: action.selectedReceipientType,
      subject: action.subject,
      message: action.message
    }

  case ActionTypes.STUDENT_SELECTION_CHANGED:
    return {
      ...state,
      to: action.to,
      validationErrors: action.validationErrors
    }

  case ActionTypes.MESSAGE_SENDING:
    return {
      ...state,
      validationErrors: [],
      status: MessagesStatus.SENDING
    }

  case ActionTypes.MESSAGE_SENT_SUCCESS:
    return {
      ...state,
      status: MessagesStatus.SUCCESS,
      statusMessage: action.statusMessage,
      subject: '',
      message: ''
    }

  case ActionTypes.MESSAGE_CLEAR_ALL:
    return {
      ...state,
      status: null,
      statusMessage: null,
      subject: '',
      message: ''
    }

  case ActionTypes.MESSAGE_SENT_FAILURE:
    return {
      ...state,
      status: MessagesStatus.ERROR,
      statusMessage: action.statusMessage
    }

  case ActionTypes.MESSAGE_VALIDATION_ERROR:
    return {
      ...state,
      validationErrors: action.validationErrors,
      status: MessagesStatus.NONE,
      statusMessage: '',
      subject: action.subject,
      message: action.message
    }

  default:
    return state
  }
}

const enhancer = compose(applyMiddleware(logger))
let MessagesStore = createStore(reducer, initialState, enhancer)

export { MessagesStore, ActionTypes, ToRecipientType, MessagesStatus }
