import React, { Component } from 'react'
import '../homework-widget.scss'
import './student-view.scss'
import DataList from '../../../Common/DataList'
import PageSelector from '../../../Common/PageSelector'
import StudentSelector from '../../../Common/Forms/StudentSelector'
import Dropster from '../../../Common/Dropster'
import { DropsterTimeframes, Loading, Error } from '../widgetComponents'
import { DashboardTimeFrame, DashboardStudentSortOrder } from '../../../../Services/Homework/Enums'
import { HomeworkDashboardService } from '../../../../Services/Homework/Bundle'
import StudentPercentage from '../../Common/StudentPercentage'
import StudentPercentageToolTip from '../../Common/StudentPercentageToolTip'
import ExportButton from '../ExportButton'
import EmptyList from '../../../Common/EmptyList'
import StudentListStore from '../../../../Stores/StudentListStore'

class StudentView extends Component {
    sortOrders = [
        {
            code: DashboardStudentSortOrder.NAME_ASC,
            title: 'Name',
            helpText: 'Alphabetical'
        },
        {
            code: DashboardStudentSortOrder.NOT_HANDED_IN_DESC,
            title: 'Not Handed In',
            helpText: 'Highest first'
        },
        {
            code: DashboardStudentSortOrder.HANDED_IN_DESC,
            title: 'Handed In',
            helpText: 'Highest first'
        },
        {
            code: DashboardStudentSortOrder.DUE_DESC,
            title: 'Due',
            helpText: 'Highest first'
        },
        {
            code: DashboardStudentSortOrder.LATE_DESC,
            title: 'Handed In Late',
            helpText: 'Hightest first'
        },
        {
            code: DashboardStudentSortOrder.DUE_ASC,
            title: 'Due',
            helpText: 'Lowest first'
        },
        {
            code: DashboardStudentSortOrder.HANDED_IN_ASC,
            title: 'Handed In',
            helpText: 'Lowest first'
        },
        {
            code: DashboardStudentSortOrder.LATE_ASC,
            title: 'Handed In Late',
            helpText: 'Lowest first'
        },
        {
            code: DashboardStudentSortOrder.NOT_HANDED_IN_ASC,
            title: 'Not Handed In',
            helpText: 'Lowest first'
        }
    ]

    homeworkDashboardService = new HomeworkDashboardService()

    state = {
        page: [],
        currentPage: 0,
        totalRows: 0,
        pageSize: 8,
        selectedStudents: [],
        selectedGroups: [],
        isLoading: false,
        hasErrored: false,
        tooltip: {
            isVisible: false,
            left: 0,
            top: 0,
            title: '',
            color: '',
            total: 0,
            percentage: 0
        }
    }
    temporySelection = {
        groups: [],
        students: []
    }
    currentTimeFrame = DashboardTimeFrame.THIS_TERM
    currentSortOrder = DashboardStudentSortOrder.NAME_ASC
    currentPage = 0
    widgetEl = React.createRef()

    componentDidMount () {
        this._getSavedState()
        this._getData()
    }

    componentWillUnmount () {
        this._saveState()
        this.homeworkDashboardService.cancelAll()
    }

    _saveState () {
        StudentListStore.dispatch({
            type: 'SET_FILTERS',
            filters: {
                state: this.state,
                currentPage: this.currentPage,
                totalRows: this.state.totalRows,
                selectedStudents: this.state.selectedStudents,
                selectedGroups: this.state.selectedGroups,
                currentTimeFrame: this.currentTimeFrame,
                currentSortOrder: this.currentSortOrder
            }
        })
    }

    _getSavedState () {
        var savedState = StudentListStore.getState()

        if (savedState && savedState.filters) {
            this.currentPage = savedState.filters.currentPage
            this.setState({
                ...savedState.filters.state,
                currentPage: this.currentPage
            }, () => {
                this._getData()
            })
            this.currentSortOrder = savedState.filters.currentSortOrder
            this.currentTimeFrame = savedState.filters.currentTimeFrame
            this.selectedSortOrderObj = this.sortOrders.hwFirstOrDefault(x => x.code === this.currentSortOrder)
            this.selectedTimeFrameObj = DropsterTimeframes.hwFirstOrDefault(x => x.code === this.currentTimeFrame)
        }
    }

    _getData () {
        this.setState({
            isLoading: true,
            hasErrored: false
        })

        let students = (this.state.selectedStudents.length) ? this.state.selectedStudents : null
        let groups = (this.state.selectedGroups.length) ? this.state.selectedGroups : null

        this.homeworkDashboardService.getStudentsWithHandInStatus(
            this.currentTimeFrame,
            students,
            groups,
            this.currentSortOrder,
            this.state.pageSize,
            this.currentPage + 1
        ).then(data => {
            this.setState({
                page: data.page,
                totalRows: data.totalRows,
                isLoading: false,
                hasErrored: false
            })
        }, () => {
            this.setState({ isLoading: false, hasErrored: true })
        })

        this.homeworkDashboardService.getStudentHandInStatusDataForExport(this.currentTimeFrame, this.currentSortOrder)
            .then(data => {
                this.setState({
                    chartData: data
                })
            })
    }

    _timeframeChange (newTimeFrame) {
        this.currentTimeFrame = newTimeFrame
        this._getData()
    }

    _sortOrderChange (newSortOrder) {
        this.currentSortOrder = newSortOrder
        this._getData()
    }

    _onStudentSelectorClose () {
        this.currentPage = 0
        this.setState({
            selectedStudents: this.temporySelection.students,
            selectedGroups: this.temporySelection.groups,
            currentPage: 0
        }, () => {
            this._getData()
        })
    }

    _studentSelectionChanged (selection) {
        this.temporySelection.students = selection.students
        this.temporySelection.groups = selection.groups
    }

    _pageChanged (newPage) {
        this.currentPage = newPage.selected
        this.setState({
            currentPage: newPage.selected
        })
        this._getData()
    }

    _showStudentToolTip (event, args) {
        let boundingRect = this.widgetEl.current.getBoundingClientRect()
        let leftOffset = -50
        let topOffset = -100
        this.setState({
            tooltip: {
                left: event.clientX - boundingRect.left + leftOffset,
                top: event.clientY - boundingRect.top + topOffset,
                isVisible: true,
                title: args.name,
                percentage: args.percentage.toFixed(2),
                color: args.color,
                total: args.total
            }
        })
    }

    _hideStudentToolTip () {
        this.setState({
            tooltip: {
                isVisible: false
            }
        })
    }

    render () {
        let headerTemplate = () => {
            return (<>
                <th width="250">Name</th>
                <th width="120">Reg</th>
                <th width="80">Year</th>
                <th width="150" />
                <th width="100">Handed In</th>
                <th width="100">Handed In Late</th>
                <th width="100">Due</th>
                <th width="100">Not Handed In</th>
            </>)
        }

        let rowTemplate = (i) => {
            return (
                <>
                    <td>
                        <a href={'/#/homework/studentview/' + i.Id}>{i.DisplaySurname + ', ' + i.DisplayForename}</a>
                    </td>
                    <td>
                        {i.RegGroup}
                    </td>
                    <td>
                        {i.YearGroup}
                    </td>
                    <td align="center" style={{ paddingRight: '30px' }}>
                        <StudentPercentage row={i} onMouseEnter={(e, args) => this._showStudentToolTip(e, args)} onMouseOut={() => this._hideStudentToolTip()} />
                    </td>
                    <td>
                        {i.HandedInOnTime}
                    </td>
                    <td>
                        {i.HandedInLate}
                    </td>
                    <td>
                        {i.TotalDue}
                    </td>
                    <td>
                        {i.TotalOverdue}
                    </td>
                </>
            )
        }

        return (
            <div className="widget-container student-widget">
                <div className="widget" ref={this.widgetEl}>
                    <div className="widget-header">
                        <div className="widget-drops">
                            <div className="widget-header-cell widget-title">
                                Student Overview
                                <ExportButton filename="StudentOverview" data={this.state.chartData} timeframe={this.currentTimeFrame} disabled={(this.state.isLoading || this.state.hasErrored)} />
                            </div>
                            <div className="widget-header-cell widget-drop time-frame">
                                <Dropster selectedOption={this.selectedSortOrderObj} options={this.sortOrders} narativeText="Order by" onChange={(x) => this._sortOrderChange(x)} />
                            </div>
                            <div className="widget-header-cell widget-drop">
                                <Dropster selectedOption={this.selectedTimeFrameObj} options={DropsterTimeframes} narativeText="Timeframe" onChange={(x) => this._timeframeChange(x)} />
                            </div>
                            <div className="student-view-student-selector">
                                <StudentSelector students={this.state.selectedStudents} groups={this.state.selectedGroups} onClose={() => this._onStudentSelectorClose()} onSelectedChanged={(x) => this._studentSelectionChanged(x)} />
                            </div>
                        </div>
                    </div>
                    <div className={(this.state.isLoading || this.state.hasErrored) ? 'widget-content empty-list' : 'widget-content'} style={{ maxHeight: 'none', width: '100%' }}>
                        {this.state.page.length > 0 &&
                            <>
                                <DataList itemTemplate={rowTemplate} headerTemplate={headerTemplate} items={this.state.page} minItems={this.state.pageSize} />
                                <PageSelector pageCount={(this.state.totalRows / this.state.pageSize)} onPageChange={(p) => { this._pageChanged(p) }} selectedPage={this.state.currentPage} />
                            </>
                        }

                        {this.state.page.length === 0 && !this.state.isLoading && !this.state.hasErrored &&
                            <EmptyList />
                        }

                        <Loading isLoading={this.state.isLoading} />
                        <Error hasErrored={this.state.hasErrored} />
                    </div>

                    <StudentPercentageToolTip
                        color={this.state.tooltip.color}
                        isVisible={this.state.tooltip.isVisible}
                        top={this.state.tooltip.top}
                        left={this.state.tooltip.left}
                        title={this.state.tooltip.title}
                        percentage={this.state.tooltip.percentage}
                        total={this.state.tooltip.total}
                    />
                </div>
            </div>
        )
    }
}

export default StudentView
