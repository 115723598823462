import React, { Component } from 'react'
import { homeworkService } from '../../../Services/Homework/HomeworkService'
import { studentService } from '../../../Services/StudentService'
import Promise from 'bluebird'
import Moment from 'moment'
import Loady from '../../Common/Loady'
import Alert, { AlertType } from '../../Common/Alerts/Alert.js'
import LoDash from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleDown, faClock } from '@fortawesome/free-regular-svg-icons'
import './homework-handed-in.css'
import { FormatDate } from '../Common/Utils.js'
import { ToggleButtonGroup, ButtonToolbar, ToggleButton, Button } from 'react-bootstrap'
import { appInsightsService } from '../../../Services/AppInsightsService'
import HomeworkStudentList from './homeworkStudentList'
import HomeworkStatusLegend from './HomeworkStatusLegend'
import { DatePicker, SortOrderSelector } from '../../Common/Forms/bundle'
import HomeworkSelectAll from './HomeworkSelectAll'

class HomeworkHandedIn extends Component {
    state = {
      isLoading: true,
      isSaving: false,
      status: '',
      statusMessage: '',
      id: null,
      title: '',
      subject: '',
      dueDate: null,
      students: [],
      filteredStudents: [],
      handedInDate: Moment(),
      filters: [1, 2],
      isSelectAll: false,
      selectedSortOrder: this.defaultSortOrder
    }

    componentWillMount () {
      this.promise = Promise.all([homeworkService.getHomeworkStudents(this.props.match.params.homeworkId), studentService.all()])
        .then(result => {
          let homework = result[0]
          let students = result[1]

          let allStudentIds = students.map(x => x.Id)
          let homeworkStudents = homework.Students
            .filter(student => allStudentIds.indexOf(student.StudentId) !== -1) // To exclude students who are no longer current.

          homeworkStudents.forEach(student => {
            let mappedStudent = LoDash.find(students, { 'Id': student.StudentId })
            student.DisplayForename = mappedStudent.DisplayForename
            student.DisplaySurname = mappedStudent.DisplaySurname
            student.CurrentYearGroup = mappedStudent.CurrentYearGroup
            student.CurrentRegGroup = mappedStudent.CurrentRegGroup
            student.PhotoId = mappedStudent.PhotoId
            student.submissionStatus = student.HandedInDate === null ? 1
              : student.Result === null || student.Result === '' ? 2 : 3
          })

          this._sortStudents(homeworkStudents, true)

          this.setState({
            ...this.state,
            id: homework.Id,
            subject: homework.Subject,
            title: homework.Title,
            dueDate: homework.DueDate,
            students: homeworkStudents,
            filteredStudents: homeworkStudents,
            isLoading: false,
            selectedSortOrder: this.defaultSortOrder
          })
        })
        .catch((error) => {
          let errorMessage = 'Failed to load homework. ' + error.message
          this.setState({
            ...this.state,
            status: AlertType.ERROR,
            statusMessage: errorMessage,
            isLoading: false
          })
        })
    }

    componentWillUnmount () {
      if (this.promise) {
        this.promise.cancel()
      }
      homeworkService.cancelRequest('saveStudentLinks')
    }

    sortOrders = [
      {
        title: 'Submission Status',
        helpText: '',
        code: 'status'
      },
      {
        title: 'Student Name',
        helpText: '',
        code: 'name-asc'
      }
    ];

    defaultSortOrder = this.sortOrders[0].code;

    today = Moment();

    _sortStudents (homeworkStudents, isSortByStatus) {
      homeworkStudents.sort(function (a, b) {
        let compareA, compareB
        if (isSortByStatus) {
          compareA = a.submissionStatus + (a.DisplaySurname + a.DisplayForename).toLowerCase()
          compareB = b.submissionStatus + (b.DisplaySurname + b.DisplayForename).toLowerCase()
        } else {
          compareA = (a.DisplaySurname + a.DisplayForename).toLowerCase()
          compareB = (b.DisplaySurname + b.DisplayForename).toLowerCase()
        }
        if (compareA < compareB) {
          return -1
        }
        if (compareA > compareB) {
          return 1
        }
        return 0
      })
    }

    _onSortOrderChanged = (sortOrder) => {
      if (sortOrder === this.state.selectedSortOrder) {
        return
      }

      let isSortByStatus = sortOrder === 'status'

      this._sortStudents(this.state.filteredStudents, isSortByStatus)

      this.setState({
        ...this.state,
        selectedSortOrder: sortOrder
      })
    }

    _onFilterChanged = (selectedFilters) => {
      let filteredStudents = this.state.students.filter(s => selectedFilters.includes(s.submissionStatus))
      this._sortStudents(filteredStudents, this.state.selectedSortOrder === 'status')

      this.setState({
        ...this.state,
        filters: selectedFilters,
        filteredStudents: filteredStudents
      })
    }

    _selectAll = (option) => {
      let students = this.state.filteredStudents
      let isSelectAll = option.target.checked === true

      students.forEach(s => {
        s.isSelected = isSelectAll
        s.selectedClassName = isSelectAll ? 'student-item-selected' : 'student-item-not-selected'
      })

      this.setState({
        ...this.state,
        isSelectAll: isSelectAll,
        filteredStudents: students
      })
    }

    _onHandedInDateChange = (date) => {
      this.setState({
        ...this.state,
        handedInDate: date
      })
    }

    _save = (isHandedIn) => {
      this.setState({
        ...this.state,
        status: '',
        statusMessage: '',
        isSaving: true
      })

      let selectedStudents = this.state.filteredStudents.filter(s => s.isSelected)
      if (selectedStudents.length === 0) {
        return
      }
      selectedStudents.forEach(s => {
        s.HandedInDate = isHandedIn ? this.state.handedInDate : null
        s.submissionStatus = isHandedIn ? 2 : 1
      })

      homeworkService.saveStudentLinks(this.state.id, selectedStudents)
        .then(() => {
          this.setState({
            ...this.state,
            status: AlertType.SUCCESS,
            statusMessage: 'Handed In status updated.',
            isSaving: false
          })
        }).catch(result => {
          let errorMessage = 'Save failed. ' + result.message
          appInsightsService.logError(errorMessage)

          this.setState({
            status: AlertType.ERROR,
            statusMessage: errorMessage,
            isSaving: false
          })
        })
    }

    render () {
      return (
          <div id="homework-handed-in">
              <Loady isLoading={this.state.isLoading} />
              <div className="row">
                  <Alert status={this.state.status} statusMessage={this.state.statusMessage} scrollOnShow />
              </div>

              {!this.state.isLoading &&
              <div>
                  <div id="homework-handed-in-header">
                      <div>
                          <h3>{this.state.title}</h3>
                          <h4>{this.state.subject}</h4>
                      </div>
                      <div><h5><span className="faint-text">Due</span> <span className="date">{FormatDate(this.state.dueDate)}</span></h5></div>
                      <label className="filters-label">Show</label>
                      <ButtonToolbar id="filter-btn-toolbar">
                          <ToggleButtonGroup type="checkbox" value={this.state.filters} onChange={this._onFilterChanged.bind(this)}>
                              <ToggleButton value={1}><FontAwesomeIcon icon={faClock} className="still-waiting-icon" /> Not Handed In</ToggleButton>
                              <ToggleButton value={2}><FontAwesomeIcon icon={faArrowAltCircleDown} className="handed-in-icon" /> Handed In</ToggleButton>
                          </ToggleButtonGroup>
                      </ButtonToolbar>
                      <HomeworkStatusLegend />
                  </div>

                  {this.state.filteredStudents.length === 0 &&
                  <div className="no-matching-students">
                      No matching students.
                  </div>
                  }

                  {this.state.filteredStudents.length > 0 &&
                  <div>
                      <div className="handedin-list-header">
                          <HomeworkSelectAll onChange={this._selectAll.bind(this)}/>
                          <SortOrderSelector onChange={this._onSortOrderChanged.bind(this)} defaultSortOrder={this.state.selectedSortOrder} options={this.sortOrders} />
                      </div>

                      <div>
                          <div id="homework-handed-in-body">
                              <HomeworkStudentList homework={this.state} />
                              <div className="homework-handed-in-actions">

                                  <div>
                                      <label htmlFor="homework-handed-in-date" className="handed-in-date-label control-label">Set date handed in </label>
                                      <br />
                                      <div className="homework-date">
                                          <DatePicker
                                              id="homework-handed-in-date"
                                              todayButton="Today"
                                              selected={this.state.handedInDate}
                                              onChange={this._onHandedInDateChange}
                                              maxDate={Moment()}
                                          />
                                      </div>
                                  </div>
                                  <br />
                                  <div>
                                      <Button bsStyle="primary" className="handed-in-status-update-btn" onClick={this._save.bind(this, true)}>
                                          <FontAwesomeIcon icon={faArrowAltCircleDown}/> Record as Handed In
                                      </Button>
                                  </div>

                                  <Button bsStyle="warning" className="handed-in-status-update-btn" onClick={this._save.bind(this, false)}>
                                      <FontAwesomeIcon icon={faUndo} /> Clear Handed In Status
                                  </Button>
                              </div>
                          </div>
                      </div>
                  </div>
                  }
              </div>

              }
          </div>
      )
    }
}

export default HomeworkHandedIn
