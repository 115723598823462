import APIService from '../APIService'
import Configuration from '../ConfigurationService'
import sscache from 'session-storage-cache'
import { HomeworkTotalsDimensions, DashboardTimeFrame, ValidateValue, DashboardStudentSortOrder } from './Enums'
import { studentService } from '../StudentService'
import { groupService } from '../GroupService'

class HomeworkDashboardService {
  constructor () {
    this.instance = APIService.create(Configuration.HomeworkUrl)
  }

    cancelToken = APIService.getCancelTokenSource();

    cancelAll () {
        this.cancelToken.cancel('All dashboard requests cancelled by user')
    }

    getHomeworkByDimension (timeframe, dimension) {
      ValidateValue(timeframe, DashboardTimeFrame)
      ValidateValue(dimension, HomeworkTotalsDimensions)

      const address = `v3/ManagementDashboard/GetTotals?timeRange=${timeframe}&dimension=${dimension}`
      const cacheKey = 'homework-dashboard/' + address
      const cacheTimeInMinutes = 30

      let cachedResult = sscache.get(cacheKey)
      if (cachedResult) {
        return Promise.resolve(cachedResult)
      } else {
        return this.instance.get(address, {
          cancelToken: this.cancelToken.token
        }).then((response) => {
          sscache.set(cacheKey, response.data, cacheTimeInMinutes)

          return response.data
        })
      }
    }

    getHandInStatusByDimension (timeframe, dimension) {
      ValidateValue(timeframe, DashboardTimeFrame)
      ValidateValue(dimension, HomeworkTotalsDimensions)

      const address = `v3/ManagementDashboard/GetHandedInStatus?timeRange=${timeframe}&dimension=${dimension}`
      const cacheKey = 'homework-dashboard/' + address
      const cacheTimeInMinutes = 30

      let cachedResult = sscache.get(cacheKey)
      if (cachedResult) {
        return Promise.resolve(cachedResult)
      } else {
        return this.instance.get(address, {
          cancelToken: this.cancelToken.token
        }).then((response) => {
          sscache.set(cacheKey, response.data, cacheTimeInMinutes)
          return response.data
        })
      }
    }

    _memoryCache = null;
    _getStudentWithHandInStatus () {
      const address = `v3/ManagementDashboard/GetAllStudentHomeworkStatistics`
      const cacheKey = 'homework-dashboard/' + address
      const cacheTimeInMinutes = 30

      let cachedResult = this._memoryCache || sscache.get(cacheKey)
      if (cachedResult) {
        return Promise.resolve(cachedResult)
      } else {
        return this.instance.get(address, {
          cancelToken: this.cancelToken.token
        }).then((response) => {
          sscache.set(cacheKey, response.data, cacheTimeInMinutes)
          this._memoryCache = response.data
          return response.data
        })
      }
    }

    async _getHandInStatusAndStudentDataJoined (timeframe) {
      let allStudentTask = studentService.all()
      let homeworkStatsTask = this._getStudentWithHandInStatus()

      await Promise.all([allStudentTask, homeworkStatsTask])

      let allStudents = await allStudentTask
      let allHomeworkStats = await homeworkStatsTask

      let allStudentsMap = allStudents.hwGetMap(s => s.Id)
      let allStatsMap = allHomeworkStats.hwGetMap(s => s.StudentId)

      // Add missing students with default values
      allStudentsMap.forEach((s) => {
        if (!allStatsMap.get(s.Id)) {
          allHomeworkStats.push({
            StudentId: s.Id,
            CurrentTermTotalHandedInOnTime: 0,
            CurrentTermTotalHandedInLate: 0,
            CurrentTermTotalOverdue: 0,
            CurrentTermTotalDue: 0,
            AcademicYearTotalHandedInOnTime: 0,
            AcademicYearTotalHandedInLate: 0,
            AcademicYearTotalOverdue: 0,
            AcademicYearTotalDue: 0
          })
        }
      })

      let joinedSet = allHomeworkStats.hwSelect((s) => {
          let student = allStudentsMap.get(s.StudentId)
          if (!student) {
            student = this._unknownStudent
          }
          let res = {
            Id: student.Id,
            DisplayForename: student.DisplayForename,
            DisplaySurname: student.DisplaySurname,
            YearGroup: student.CurrentYearGroup,
            RegGroup: student.CurrentRegGroup
          }

          if (timeframe === DashboardTimeFrame.THIS_NC_YEAR) {
            res.HandedInOnTime = s.AcademicYearTotalHandedInOnTime
            res.HandedInLate = s.AcademicYearTotalHandedInLate
            res.TotalOverdue = s.AcademicYearTotalOverdue
            res.TotalDue = s.AcademicYearTotalDue
          } else if (timeframe === DashboardTimeFrame.THIS_TERM) {
            res.HandedInOnTime = s.CurrentTermTotalHandedInOnTime
            res.HandedInLate = s.CurrentTermTotalHandedInLate
            res.TotalOverdue = s.CurrentTermTotalOverdue
            res.TotalDue = s.CurrentTermTotalDue
          }

          let total = res.HandedInOnTime + res.HandedInLate + res.TotalOverdue + res.TotalDue
          res.HandedInOnTimePercentage = (res.HandedInOnTime / total) * 100
          res.HandedInLatePercentage = (res.HandedInLate / total) * 100
          res.TotalOverduePercentage = (res.TotalOverdue / total) * 100
          res.TotalDuePercentage = (res.HandedInOnTime / total) * 100

          return res
      })

      return joinedSet
    }

    _secondarySort (a, b) {
      let sortPropFunc = (x) => x.DisplaySurname + ', ' + x.DisplayForename

      let aProp = sortPropFunc(a)
      let bProp = sortPropFunc(b)

      let result = 0
      if (aProp > bProp) {
        result = 1
      }

      if (aProp < bProp) {
        result = -1
      }
      return result
    }

    _sortStudents (students, sortOrder) {
      let propertySelector = null
      let direction = 1

      let nameSelector = (x) => x.DisplaySurname + ', ' + x.DisplayForename
      switch (sortOrder) {
        case DashboardStudentSortOrder.NAME_ASC:
         propertySelector = nameSelector
         direction = 1
         break
        case DashboardStudentSortOrder.NAME_DESC:
         propertySelector = nameSelector
         direction = -1
         break
        case DashboardStudentSortOrder.HANDED_IN_ASC:
         propertySelector = (x) => x.HandedInOnTime
         direction = 1
         break
        case DashboardStudentSortOrder.HANDED_IN_DESC:
         propertySelector = (x) => x.HandedInOnTime
         direction = -1
         break
        case DashboardStudentSortOrder.NOT_HANDED_IN_ASC:
         propertySelector = (x) => x.TotalOverdue
         direction = 1
         break
        case DashboardStudentSortOrder.NOT_HANDED_IN_DESC:
         propertySelector = (x) => x.TotalOverdue
         direction = -1
         break
        case DashboardStudentSortOrder.LATE_ASC:
         propertySelector = (x) => x.HandedInLate
         direction = 1
         break
        case DashboardStudentSortOrder.LATE_DESC:
         propertySelector = (x) => x.HandedInLate
         direction = -1
         break
        case DashboardStudentSortOrder.DUE_ASC:
         propertySelector = (x) => x.TotalDue
         direction = 1
         break
        case DashboardStudentSortOrder.DUE_DESC:
         propertySelector = (x) => x.TotalDue
         direction = -1
         break
        default:
          propertySelector = nameSelector
          direction = 1
      }

      return students.sort((a, b) => {
        let aProp = propertySelector(a)
        let bProp = propertySelector(b)

        let result = 0
        if (aProp > bProp) {
          result = 1
        }

        if (aProp < bProp) {
          result = -1
        }

        result = result * direction

        if (result === 0) {
          return this._secondarySort(a, b)
        }

        return result
      })
    }

    _unknownStudent = {
      CurrentHouse: 'Unknown',
      DisplayForename: 'Unknown',
      DisplaySurname: '',
      Id: '00000000-0000-0000-0000-000000000000',
      IsActive: true,
      PersonType: 1,
      SchoolGuid: '00000000-0000-0000-0000-000000000000'
    }

    async getStudentsWithHandInStatus (timeframe, students, groups, sortOrder, pageSize, page) {
      ValidateValue(timeframe, DashboardTimeFrame)
      ValidateValue(sortOrder, DashboardStudentSortOrder)

      let setOfStudentsToFilterTo = []
      if (students != null) {
        setOfStudentsToFilterTo = setOfStudentsToFilterTo.hwConcat(students)
      }

      if (groups != null) {
        let studentsInGroups = await groupService.getStudentsInGroups(groups)
        setOfStudentsToFilterTo = setOfStudentsToFilterTo.hwConcat(studentsInGroups)
      }

      let completeSetOfData = null

      if (groups != null && groups.length > 0 && setOfStudentsToFilterTo.length === 0) {
        // if the user has picked a group with no students, show an empty grid
        // shortcut the fetch
        completeSetOfData = []
      } else {
        completeSetOfData = await this._getHandInStatusAndStudentDataJoined(timeframe)
      }

      if (setOfStudentsToFilterTo.length) {
        let setOfStudentsToFilterToMap = setOfStudentsToFilterTo.hwGetMap(x => x)
        completeSetOfData = completeSetOfData.hwWhere(s => setOfStudentsToFilterToMap.has(s.Id))
      }

      this._sortStudents(completeSetOfData, sortOrder)

      let pageToReturn = completeSetOfData.hwGetPage(pageSize, page)

      return {
        page: pageToReturn,
        totalRows: completeSetOfData.length
      }
    }

    async getStudentHandInStatusDataForExport (timeframe, sortOrder) {
      ValidateValue(timeframe, DashboardTimeFrame)
      ValidateValue(sortOrder, DashboardStudentSortOrder)

      let completeSetOfData = await this._getHandInStatusAndStudentDataJoined(timeframe)
      this._sortStudents(completeSetOfData, sortOrder)

      // Strip out the percentages
      return completeSetOfData.hwSelect((x) => {
        return {
          DisplayForename: x.DisplayForename,
          DisplaySurname: x.DisplaySurname,
          YearGroup: x.YearGroup,
          RegGroup: x.RegGroup,
          TotalHandedInOnTime: x.HandedInOnTime,
          TotalHandedInLate: x.HandedInLate,
          TotalOverdue: x.TotalOverdue,
          TotalDue: x.TotalDue
        }
      })
    }
}

export default HomeworkDashboardService
