import React, { Component } from 'react'
import Configuration from '../../../Services/ConfigurationService'

class Footer extends Component {
  render () {
    return (
        <div className="footer vertical-spacing" >
            <div>
                <a href={Configuration.simsPortalLink} >
                Help - Engagement Portal
                </a>
            </div>
        </div>
    )
  }
}

export default Footer
