import React, { Component } from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import LinkToolbarButtonPopout from './LinkToolbarButtonPopout'
import './linkToolbarButton.css'

class LinkToolbarButton extends Component {
    state = {
      popoutOpen: false,
      defaultDisplayText: ''
    }

    handleClickOutside = () => {
      this.setState({
        popoutOpen: false
      })
    };

    _getLinkText = (element) => {
      let startIndex = element.selectionStart
      let endIndex = element.selectionEnd

      return element.value.slice(startIndex, endIndex)
    }

    _togglePopout = (e) => {
      e.stopPropagation()

      if (this.state.popoutOpen) {
        this._closePopout()
      } else {
        this._openPopout()
      }
    }

    _openPopout = () => {
      this.setState({
        popoutOpen: true,
        defaultDisplayText: this._getLinkText(this.props.bindTo)
      })

      this.toolbarBtn.blur()
    }

    _closePopout = () => {
      this.setState({
        popoutOpen: false
      })

      this.props.bindTo.focus()
    }

    _onAddClick = (url) => {
      this._closePopout()

      if (url) {
        this.props.linkGenerated(url)
      }
    }

    render () {
      return (
          <div>
              <button className="link-toolbar-btn" onClick={this._togglePopout} ref={el => { this.toolbarBtn = el }}>
                  <i className="fa fa-link" aria-hidden="true" />
                  <span className="link-toolbar-btn-label">Add Link</span>
              </button>

              {this.state.popoutOpen &&
              <LinkToolbarButtonPopout defaultDisplayText={this.state.defaultDisplayText} onAddClick={this._onAddClick} onCancelClick={this._closePopout} />
              }
          </div>
      )
    }
}

LinkToolbarButton.propTypes = {
  linkGenerated: PropTypes.func
}

export default onClickOutside(LinkToolbarButton)
