import React, { Component } from 'react'
import { userContextService } from '../../../Services/UserContextService'
import { userProfileService } from '../../../Services/UserProfileService'
import { roleService } from '../../../Services/RoleService'
import Configuration from '../../../Services/ConfigurationService'

const parentProductName = 'parent'
const optionsProductName = 'options'

const parentProduct = { id: parentProductName, displayName: 'SIMS Parent', url: Configuration.ParentUrl, isLicensed: false, hasRole: false, styles: 'sos-font sos-parent' }
const optionsProduct = { id: optionsProductName, displayName: 'Options', url: Configuration.OptionsUrl, isLicensed: false, hasRole: false, styles: 'fa fa-list-ol' }

class Unauthorised extends Component {
  state = {
    unauthorisedMessage: null,
    productAccessMessage: null,
    availableProducts: null,
    products: [
      parentProduct,
      optionsProduct
    ]
  }

  componentDidMount () {
    let userContextServicePromise = userContextService.get()
    let roleServicePromise = roleService.hasEngagementRole()
    let optionsRolePromise = roleService.hasOptionsRole()
    let userProfileServicePromise = userProfileService.getIdentities()

    let promises = [userContextServicePromise, roleServicePromise, userProfileServicePromise, optionsRolePromise]

    let success = (result) => {
      let userContext = result[0]
      let hasEngagementRole = result[1]
      let profile = result[2]
      optionsProduct.hasRole = result[3]
      if (!userContext.schoolId || !userContext.userId) {
        this.setState({
          ...this.state,
          unauthorisedMessage: 'Before signing in, please make sure you are registered or contact your school if you have not received a registration email.'
        })
      } else {
          let identity = null
          if (profile === null) {
            return
          } else {
            identity = profile.Identities[0]
          }

          this.setProductPermissions(identity)

          let noOfAvailableProducts = this.state.products.filter(p => p.isLicensed && p.hasRole).length
          let hasProducts = noOfAvailableProducts > 0
          let s = noOfAvailableProducts > 1 ? 's' : ''
          this.setState({
            ...this.state,
            unauthorisedMessage: 'You have successfully signed in but do not have access to this portal. Please contact your administrator.',
            productAccessMessage: hasProducts ? <p>You do have access to the following product{s}:</p> : null,
            availableProducts: hasProducts ? this.getAvailableProductsList(this.state.products) : null
          })
      }
    }

    let error = () => {
      this.setState({
        ...this.state,
        unauthorisedMessage: 'Unauthorised'
      })
    }

    Promise.all(promises).then(success, error)

    sessionStorage.clear()
  }

  setProductPermissions (identity) {
    parentProduct.hasRole = identity.IsParent
    parentProduct.isLicensed = identity.Products.hwAny(p => p.Name === parentProductName)
    optionsProduct.isLicensed = identity.Products.hwAny(p => p.Name === optionsProductName)
  }

  getAvailableProductsList (products) {
    return (
        <div>
            {products.filter(p => p.isLicensed === true && p.hasRole === true).map(product =>
                <a key={product.id} href={product.url} className="btn btn-default" style={{ margin: '2px' }}><i className={product.styles} />&nbsp;{product.displayName}</a>
            )}
        </div>
    )
  }

  render () {
    if (this.state.unauthorisedMessage === null) {
      return null
    }
    return (
        <div className="panel panel-primary panel-default">
            <div className="panel-heading">Unauthorised access</div>
            <div className="panel-body">
                <p>
                    {this.state.unauthorisedMessage}
                </p>
                {this.state.productAccessMessage}
                {this.state.availableProducts}
            </div>
        </div>
    )
  }
}

export default Unauthorised
