import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { authService } from '../../../../../Services/AuthService'
import Configuration from '../../../../../Services/ConfigurationService'

let SignInOutLinks = function (props) {
  if (props.isAuthenticated) {
    return (
      <>
        <div className="divider"></div>
        <div onClick={() => { this.openMyProfile() }}>
            <i className="fa fa-user" />My Profile
        </div>
        <div onClick={() => { this.logOut() }}>
            <i className="fa fa-sign-out" />Sign Out
        </div>
      </>
    )
  } else {
    return (
        <div onClick={() => { this.logIn() }}>
            <i className="fa fa-sign-in" />Sign In
        </div>
    )
  }
}

class DropDownMenu extends Component {
  constructor (props) {
    super(props)
    SignInOutLinks = SignInOutLinks.bind(this)
  }

    state = {
      user: null,
      photoSrc: null
    }

    logIn () {
      authService.logIn()
    }
    logOut () {
      authService.logOut()
    }

    openMyProfile () {
      window.open(Configuration.MyProfileLink,  '_blank')
    }

    componentWillReceiveProps (newProps) {
      this.setState({ ...newProps })
    }

    stopProp (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    render () {
      if (!this.props.isOpen) {
        return null
      }

      return (
          <ul className="dropdown-menu" onClick={(e) => this.stopProp(e)}>
              {this.props.isAuthenticated && this.state.largePhotoSrc &&
              <li className="nav-user-dropdown-picture">
                  <img className="nav-user-dropdown-picture-img" alt="Current user" src={this.state.largePhotoSrc} />
              </li>
              }
              {this.props.user.schoolName &&
              <li>
                  <span className="nav-user-header">School</span>
                  <span className="nav-user-info">{this.props.user.schoolName}</span>
              </li>
              }

              {this.props.user.simsName &&
              <li>
                  <span className="nav-user-header">Name in SIMS</span>
                  <span className="nav-user-info">{this.props.user.simsName}</span>
              </li>
              }

              <li className="signInOut">
                  <SignInOutLinks isAuthenticated={this.props.isAuthenticated} />
              </li>
          </ul>
      )
    }
}

DropDownMenu.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object
}

export default DropDownMenu
