import React, { Component } from 'react'
import { authService } from '../../../Services/AuthService'
import { Redirect } from 'react-router-dom'
import { routeConfiguration } from '../../../Services/RouteConfigurationService'

class AuthenticationCallback extends Component {
  constructor (props) {
    super(props)

    this.state.hasIssuedRedirect = authService.bounceBackSaveStateThenRedirect()

    let currentPageInfo = routeConfiguration.getCurrentPageInfo()
    this.state.currentPath = currentPageInfo.path

    let loginIfPageRequiredIt = function (page) {
      if (page.requiresAuthorise) {
        authService.logIn()
      }
    }

    routeConfiguration.subscribeToCurrentPathChange((page) => {
      loginIfPageRequiredIt(page)
    })

    loginIfPageRequiredIt(currentPageInfo)
  }

  state ={
    hasIssuedRedirect: true,
    currentPath: null
  }

  render () {
    if ((!this.state.hasIssuedRedirect && this.state.currentPath === '/') && this.state.currentPath !== '/messages') {
      return <Redirect from="/" to="/" />
    }
    return null
  }
}

export default AuthenticationCallback
