import React, { Component } from 'react'
import './not-handed-in.scss'

class NotHandedInView extends Component {
  render () {
    return (
        <div id="not-handed-in">
            <span className="not-handed-in-title">Not Handed In</span>
            <i className="icon icon-not-handed-in" />

            <span className="not-handed-in-question">Did the student hand you the homework in person? <br/>... Press "Hand In" at the top</span>

        </div>
    )
  }
}

export default NotHandedInView
