/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import 'moment-timezone'
import { homeworkService } from '../../../Services/Homework/HomeworkService'
import { groupService } from '../../../Services/GroupService'
import { appInsightsService } from '../../../Services/AppInsightsService'
import Alert, { AlertType } from '../../Common/Alerts/Alert.js'
import '../homework.css'
import './homework-catalogue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaperclip, faUsers } from '@fortawesome/free-solid-svg-icons'
import PageSelector from '../../Common/PageSelector'
import Filters from './Filters'
import Loady from '../../Common/Loady'
import LoDash from 'lodash'
import Promise from 'bluebird'
import HomeworkListStore from '../../../Stores/HomeworkListStore'
import { SortOrderSelector } from '../../Common/Forms/bundle'
import HomeworkItem from './HomeworkItem.js'

library.add(faPaperclip, faUsers)

class HomeworkCatalogue extends Component {
  pageSize = 5;
  defaultSetBy = this.props.match.params.setById;

  sortOrders = [
    {
      title: 'Due Date',
      helpText: '(earliest due first)',
      code: 'duedate-asc',
      urlCode: 'DueDate'
    },
    {
      title: 'Title',
      helpText: '(alphabetical)',
      code: 'title-asc',
      urlCode: 'Title'
    },
    {
      title: 'Subject',
      helpText: '(alphabetical)',
      code: 'subject-asc',
      urlCode: 'Subject'
    },
    {
      title: 'Created Date',
      helpText: '(most recent first)',
      code: 'createdate-desc',
      urlCode: 'CreatedDate'
    }
  ]

  defaultSortOrder = this.props.match.params.sortOrder
    ? LoDash.find(this.sortOrders, { 'urlCode': this.props.match.params.sortOrder }).code
    : this.sortOrders[0].code;

  state = {
    homework: [],
    groups: [],
    sortOrder: this.defaultSortOrder,
    pageCount: 0,
    currentPage: 1,
    isLoading: true,
    status: '',
    statusMessage: '',
    filters: {
      setBy: null,
      searchQuery: null,
      state: null,
      subject: null,
      groups: [],
      students: []
    }
  };

  componentDidMount () {
    // if no explicit sort by in query string then try and restore previous state
    if (!this.props.match.params.sortOrder) {
      let newState = this.state
      this._restoreStateFromRedux(newState)
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState(newState)
    }
  }

  componentWillUnmount () {
    if (this.promise) {
      this.promise.cancel()
    }
    homeworkService.cancelRequest('delete')
  }
    _saveStateToRedux (state = this.state) {
      HomeworkListStore.dispatch({
        type: 'SET_PAGEANDSORT',
        pageSort: {
          currentPage: state.currentPage,
          sortOrder: state.sortOrder
        }
      })
    }

    _restoreStateFromRedux (newState) {
      var reduxState = HomeworkListStore.getState()

      if (reduxState && reduxState.pageSort) {
        newState.currentPage = reduxState.pageSort.currentPage
        newState.sortOrder = reduxState.pageSort.sortOrder
      }
    }

    _onPageChange = (data) => {
      let selectedPage = data.selected + 1

      this._loadHomeworkInfo({
        sortOrder: this.state.sortOrder,
        page: selectedPage,
        pageSize: this.pageSize,
        filters: this.state.filters
      })
      this.setState({
        ...this.state,
        currentPage: selectedPage
      }, this._saveStateToRedux)
    }

    _onSortOrderChanged = (sortOrder) => {
      if (sortOrder === this.state.sortOrder) {
        return
      }

      this._loadHomeworkInfo({
        sortOrder: sortOrder,
        page: 1,
        pageSize: this.pageSize,
        filters: this.state.filters
      })

      this.setState({
        ...this.state,
        sortOrder: sortOrder,
        currentPage: 1
      }, this._saveStateToRedux)
    }

    _onFiltersChanged = (selection, sequenceNumber) => {
      let newPage = 1

      // if this is the first time that the filters have changed (there is always on at the start once it has loaded certain information)
      // then we need to check if there is some saved state and restore it.
      if (sequenceNumber === 0) {
        var reduxState = HomeworkListStore.getState()
        if (reduxState && reduxState.pageSort) {
          newPage = reduxState.pageSort.currentPage
        }
      } else {
        this._saveStateToRedux({
          sortOrder: this.state.sortOrder,
          currentPage: newPage
        })
      }

      let changedFilters = {
        setBy: selection.setBy,
        subject: selection.subject,
        groups: selection.assignedTo.groups,
        students: selection.assignedTo.students,
        state: selection.state.key,
        searchQuery: selection.searchQuery
      }

      this._loadHomeworkInfo({
        sortOrder: this.state.sortOrder,
        page: newPage,
        pageSize: this.pageSize,
        filters: changedFilters
      })

      this.setState({
        ...this.state,
        currentPage: newPage,
        filters: changedFilters
      })
    }

    _loadHomeworkInfo (props) {
      this.setState({
        ...this.state,
        isLoading: true,
        status: null,
        statusMessage: ''
      })

      this.promise = Promise.all([homeworkService.getHomeworkList(props), groupService.getAllGroupsMap()])
        .then((result) => {
          if (result[0].Items) result[0].Items.forEach(h => { h.deleteHomeworkCallback = this._deleteHomeworkCallback })
          this.setState({
            ...this.state,
            homework: result[0].Items,
            groups: result[1],
            pageCount: Math.ceil(result[0].TotalItems / this.pageSize),
            isLoading: false
          })
        }).catch(result => {
          let errorMessage = 'Failed to load homework dashboard. ' + result.message
          appInsightsService.logError(errorMessage)

          this.setState({
            ...this.state,
            status: AlertType.ERROR,
            statusMessage: errorMessage,
            isLoading: false
          })
        })
    }

    _deleteHomeworkCallback = (homeworkId) => {
      homeworkService.deleteHomework(homeworkId).then(() => {
        this._loadHomeworkInfo({
          sortOrder: this.state.sortOrder,
          page: this.state.currentPage,
          pageSize: this.pageSize,
          filters: this.state.filters
        })
      })
    }

    render () {
      return (
          <div id="homework-catelogue">
              <Loady isLoading={this.state.isLoading}/>
              <div className="row">
                  <Alert status={this.state.status} statusMessage={this.state.statusMessage} scrollOnShow />
              </div>
              <div className="homework-catelogue-controls-container">
                  <Filters onChange={this._onFiltersChanged} defaultSetBy={this.defaultSetBy} sortOrder={this.props.match.params.sortOrder} currentPath={this.props.location.pathname}/>

                  {this.state.homework.length > 0 ? (
                      <div>
                          <SortOrderSelector onChange={this._onSortOrderChanged} defaultSortOrder={this.state.sortOrder} options={this.sortOrders}/>
                          <HomeworkList homework={this.state.homework} groups={this.state.groups}/>
                          <PageSelector pageCount={this.state.pageCount} onPageChange={this._onPageChange} selectedPage={this.state.currentPage - 1}/>
                      </div>
                  ) : (!this.state.isLoading &&
                  <div className="no-records">
                      <div align='center'>No matching records found.</div>
                  </div>
                  )
                  }
              </div>
          </div>
      )
    }
}

export default HomeworkCatalogue

class HomeworkList extends Component {
  render () {
    let homeworkItems = this.props.homework.map(h => <HomeworkItem key={h.Id} item={h} groups={this.props.groups}/>)

    return (
        <div id="homework-list">
            {homeworkItems}
        </div>
    )
  }
}
