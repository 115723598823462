import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StudentSelectorPopout from './StudentSelectorPopout'
import { groupService } from '../../../../Services/GroupService'
import { studentService } from '../../../../Services/StudentService'
import LoDash from 'lodash'
import onClickOutside from 'react-onclickoutside'
import Promise from 'bluebird'

import './student-selector.css'

class StudentSelector extends Component {
    state = {
      groupsPreviewOpen: false,
      studentsPreviewOpen: false,
      popoutOpen: false,
      combinedGroupsForPreview: null,
      studentsForPreview: null,
      groups: this.props.groups,
      students: this.props.students
    };

    promises = [];

    componentDidMount () {
      this.promises.push(Promise.all([groupService.getYears(), groupService.getHouses(), groupService.getUserDefinedGroups(), groupService.getRegistrationGroups(), groupService.getSubjectClasses(), groupService.getPreAdmissionGroups()])
        .then((results) => {
          let combinedGroups = []

          let years = results[0].hwSelect(x => {
            return {
              name: 'Year ' + x.name,
              id: x.id
            }
          })

          combinedGroups = LoDash.concat(combinedGroups, years)
          combinedGroups = LoDash.concat(combinedGroups, results[1])
          combinedGroups = LoDash.concat(combinedGroups, results[2])
          combinedGroups = LoDash.concat(combinedGroups, results[3])
          combinedGroups = LoDash.concat(combinedGroups, results[4])

          let preAdmissions = results[5].hwSelect(x => {
            return {
              name: 'Pre Admission: ' + x.name,
              id: x.id
            }
          })
          combinedGroups = LoDash.concat(combinedGroups, preAdmissions)
          this.setState({
            combinedGroupsForPreview: combinedGroups
          })
        }))

      this.promises.push(Promise.all([studentService.all(), studentService.allPreAdmissions()]).then(results => {
        let combinedAllStudents = LoDash.concat(results[0], results[1])
        this.setState({
          studentsForPreview: combinedAllStudents
        })
      }))
    }

    componentWillUnmount () {
      this.promises.forEach(promise => {
        promise.cancel()
      })
    }

    componentWillReceiveProps (props) {
      this.setState({
        groups: props.groups,
        students: props.students
      })
    }

    handleClickOutside = () => {
      if (this.state.popoutOpen || this.state.groupsPreviewOpen || this.state.studentsPreviewOpen) {
        if (this.props.onClose) {
          this.props.onClose()
        }
      }

      this.setState({
        groupsPreviewOpen: false,
        studentsPreviewOpen: false,
        popoutOpen: false
      })
    };

    clear = () => {
      let clearedSelection = { students: [], groups: [] }
      this.setState(clearedSelection)
      this.props.onSelectedChanged(clearedSelection)
    }

    _selectionChanged = (newSelection) => {
      let newSelectionInternal = {
        students: newSelection.selectedStudents,
        groups: newSelection.selectedGroups
      }
      this.setState(newSelectionInternal)
      this.props.onSelectedChanged(newSelectionInternal)
    }

    _closeAllPopups = () => {
      this.setState({
        groupsPreviewOpen: false,
        studentsPreviewOpen: false,
        popoutOpen: false
      })
      if (this.props.onClose) {
        this.props.onClose()
      }
    }

    _onStudentsPreviewClick = (e) => {
      if (this.state.studentsPreviewOpen) {
        this._closeAllPopups()
      } else {
        if (this.props.students.length) {
          this.setState({
            groupsPreviewOpen: false,
            studentsPreviewOpen: true,
            popoutOpen: false
          })
        }
      }
      e.stopPropagation()
    }

    _onGroupsPreviewClick = (e) => {
      if (this.state.groupsPreviewOpen) {
        this._closeAllPopups()
      } else {
        this.setState({
          groupsPreviewOpen: true,
          studentsPreviewOpen: false,
          popoutOpen: false
        })
      }
      e.stopPropagation()
    }

    _openPopout = (e) => {
      if (this.props.disabled) {
        return
      }

      if (this.state.popoutOpen) {
        this._closeAllPopups()
      } else {
        this.setState({
          groupsPreviewOpen: false,
          studentsPreviewOpen: false,
          popoutOpen: true
        })
      }
      if (this.props.studentList) {
        this.props.studentList(this.state.studentsForPreview)
      }
      e.stopPropagation()
    }

    _closePopout = () => {
      this.setState({
        groupsPreviewOpen: false,
        studentsPreviewOpen: false,
        popoutOpen: false
      })
      if (this.props.onClose) {
       this.props.onClose()
      }
    }

    _getGroupsPreview = () => {
      let allGroups = this.state.combinedGroupsForPreview
      let selectedGroups = this.props.groups

      let previewGroups = []
      for (let i = 0; i < selectedGroups.length; i++) {
        let selectedGroup = selectedGroups[i]
        let groupInfo = allGroups.hwFirstOrDefault(x => { return x.id === selectedGroup })
        previewGroups.push(groupInfo)
      }
      return previewGroups
    }

    _getStudentPreview = () => {
      let allStudents = this.state.studentsForPreview
      let selectedStudents = this.props.students

      if (!allStudents) {
        return []
      }

      let previewStudents = []
      for (let i = 0; i < selectedStudents.length; i++) {
        let selectedStudent = selectedStudents[i]
        let studentInfo = allStudents.hwFirstOrDefault(x => { return x.Id === selectedStudent })
        previewStudents.push(studentInfo)
      }
      return previewStudents
    }

    _removeGroup = (event, group) => {
      var newGroups = this.state.groups.hwRemove(x => x === group.id) || []
      this.setState({
        groups: newGroups
      })

      if (!newGroups.length) {
        this.setState({
          groupsPreviewOpen: false
        })

        this._closePopout()
      }

      this._selectionChanged({
        selectedGroups: newGroups,
        selectedStudents: this.state.students
      })

      event.stopPropagation()
    }

    _removeStudent = (event, student) => {
      var newStudents = this.state.students.hwRemove(x => x === student.Id) || []
      this.setState({
        students: newStudents
      })

      if (!newStudents.length) {
        this.setState({
          studentsPreviewOpen: false
        })

        this._closePopout()
      }

      this._selectionChanged({
        selectedGroups: this.state.groups,
        selectedStudents: newStudents
      })

      event.stopPropagation()
    }
    sendAllStudentList = () => {
      if (this.props.studentList) this.props.studentList(this.state.studentsForPreview)
     }

      render () {
      return (
          <div className="student-selector">

              <div className={this.props.disabled ? 'form-control disabled' : 'form-control'} onClick={this._openPopout}>
                  {(this.props.groups && this.props.groups.length)
                    ? (
                        <span onClick={this._onGroupsPreviewClick} className="student-selector-clickable">{this.props.groups.length} {(this.props.groups.length === 1) ? 'group' : 'groups'}</span>
                    )
                    : (null)
                  }

                  {(this.props.students && this.props.students.length)
                    ? (
                        <span onClick={this._onStudentsPreviewClick} className="student-selector-clickable">{this.props.students.length} {(this.props.students.length === 1) ? 'student' : 'students'}</span>
                    )
                    : (null)
                  }

                  {(!this.props.students.length) && (!this.props.groups.length)
                    ? (
                        <span className="no-students-selected">No students selected</span>
                    )
                    : (null)
                  }
                  <button className="student-selector-open-button" disabled={this.props.disabled}>
                      <i className="fa fa-user" aria-hidden="true" />
                  </button>
              </div>

              {this.state.studentsPreviewOpen &&
              <div className="students-preview student-selector-preview">
                  {this._getStudentPreview().map(x => {
                    return (
                        <div key={x.Id} className="student-selector-preview-item">
                            <div className="student-selector-preview-item-cross" onClick={(e) => this._removeStudent(e, x)}><i className="fa fa-times" aria-hidden="true" /></div>
                            {x.DisplayForename} {x.DisplaySurname} - {x.CurrentRegGroup}
                        </div>
                    )
                  })}
              </div>
              }

              {this.state.groupsPreviewOpen &&
              <div className="groups-preview student-selector-preview">
                  {this._getGroupsPreview().map(x => {
                    return (
                        <div key={x.id} className="student-selector-preview-item">
                            <div className="student-selector-preview-item-cross" onClick={(e) => this._removeGroup(e, x)}><i className="fa fa-times" aria-hidden="true" /></div>
                            {x.name}
                        </div>
                    )
                  })}
              </div>
              }
              {this.state.popoutOpen &&
              <StudentSelectorPopout selectionChanged={this._selectionChanged} selectedStudents={this.state.students} selectedGroups={this.state.groups} closePopout={this._closePopout} includePreAdmission={this.props.includePreAdmission} />
              }
          </div>

      )
    }
}

StudentSelector.props = {
  onSelectedChanged: PropTypes.func,
  includePreAdmission: PropTypes.bool,
  students: PropTypes.array,
  groups: PropTypes.array,
  onClose: PropTypes.func
}

StudentSelector.defaultProps = {
  includePreAdmission: false,
  students: [],
  groups: []
}

export default onClickOutside(StudentSelector)
