/* eslint-disable react/no-multi-comp */
import LoDash from 'lodash'
import React, { Component } from 'react'
import { groupService } from '../../../../Services/GroupService'
import { studentService } from '../../../../Services/StudentService'
import { schoolAdminUserInviteService } from '../../../../Services/SchoolAdminUserInviteService'
import './studentPreview.css'
import ReactDataGrid from 'react-data-grid'
import PropTypes from 'prop-types'

class FornameAndSurnameFormatter extends React.Component {
    static propTypes = {
      value: PropTypes.object.isRequired
    };

    render () {
      const forename = this.props.value.forename
      const surname = this.props.value.surname
      const isRegistered = this.props.value.isRegistered
      return (
          <div>
              {forename} {surname}

              {isRegistered &&
              <div className="registered">Registered</div>
              }
              {!isRegistered &&
              <div className="not-registered">Not Registered</div>
              }
          </div>)
    }
}

class StudentPreview extends Component {
    state = {
      students: [],
      isLoading: false
    }

    _columns = this._columns = [
      {
        key: 'forenameAndSurnameAndIsRegistered',
        name: 'Name',
        formatter: FornameAndSurnameFormatter,
        width: 300
      },
      {
        key: 'currentYearGroup',
        name: 'Year'
      },
      {
        key: 'reg',
        name: 'Reg'
      }
    ];

    componentDidMount () {
      this.setState({
        isLoading: true
      })

      groupService.getPreAdmissionGroups().then(allPreAdmissionGroups => {
        let selectedPreAdmissionsGroups = this.props.selectedGroups.hwWhere(x => { return allPreAdmissionGroups.hwAny(y => y.id === x) })

        var studentsInSelectedGroups = Promise.all([
          groupService.getStudentsInGroups(this.props.selectedGroups),
          groupService.getPreAdmissionStudentsInGroups(selectedPreAdmissionsGroups)])
          .then(result => {
            let studentsInGroups = result[0]
            let preAdmissionStudentsInGroups = result[1]

            let allStudentsInGroups = LoDash.union(studentsInGroups, preAdmissionStudentsInGroups)
            return allStudentsInGroups
          })

        var allStudents = Promise.all([
          studentService.all(),
          studentService.allPreAdmissions(),
          schoolAdminUserInviteService.allRegisteredStudents(),
          schoolAdminUserInviteService.allRegisteredPreAdmissionStudents()
        ]).then(result => {
          let students = result[0]
          let preAdmissionStudents = result[1]
          let allRegisteredStudents = LoDash.union(result[2], result[3])

          let allStudents = LoDash.union(students, preAdmissionStudents)

          for (let i = 0; i < allStudents.length; i++) {
            let student = allStudents[i]
            student.isRegistered = allRegisteredStudents.hwAny(x => x.ExternalUserId === student.Id)
          }

          return allStudents
        })

        Promise.all([
          studentsInSelectedGroups,
          allStudents
        ]).then(result => {
          let studentsInSelectedGroups = result[0]
          let allStudents = result[1]

          let allSelectedStudents = LoDash.union(studentsInSelectedGroups, this.props.selectedStudents)

          let students = allStudents.hwJoin(allSelectedStudents, (a, b) => a.Id === b, (a, b) => { return { ...a } })

          students = LoDash.orderBy(students, ['DisplaySurname', 'DisplayForename', 'CurrentYearGroup', 'CurrentRegGroup'])
          this.setState({
            students: students.hwSelect(x => {
              return {
                forenameAndSurnameAndIsRegistered: {
                  forename: x.DisplayForename,
                  surname: x.DisplaySurname,
                  isRegistered: x.isRegistered
                },
                currentYearGroup: x.CurrentYearGroup,
                reg: x.CurrentRegGroup,
                id: x.Id
              }
            }),
            isLoading: false
          })
        })
      })
    }

    _rowGetter = (i) => {
      return this.state.students[i]
    };

    render () {
      return (
          <div className="student-preview">
              {this.state.isLoading &&
              <div>
                  <div className="student-preview-loady" />
                  <span className="student-preview-loady-text">Loading</span>
              </div>
              }

              Students ({this.state.students.length})
              <ReactDataGrid
                  columns={this._columns}
                  rowGetter={this._rowGetter}
                  rowsCount={this.state.students.length}
                  headerRowHeight={30}
                  minHeight={200}
                  rowHeight={26}
              />

          </div>
      )
    }
}

export default StudentPreview
