import { authService } from './AuthService'
import { personService } from './PersonService'
import jwtDecode from 'jwt-decode'
import { schoolDetailsService } from './SchoolDetailsService'
import { userProfileService } from './UserProfileService'

class UserContextService {
  constructor () {
    this.featureToggleList = []
    let resolveUserContext = (authState, resolve) => {
      let userData = this._getUserDataFromToken(authState.accessToken)

      if (userData.userId) {
        let personData = personService.getSingle(userData.userId)
        let schoolData = schoolDetailsService.getDetails(userData.schoolId)
        let userProfileData = userProfileService.getIdentities()
        let that = this
        Promise.all([personData, schoolData, userProfileData]).then(function (results) {
          let person = results[0]
          let school = results[1]
          let userProfile = results[2]
          that.featureToggleList = userProfile.Identities[0].Features
          resolve({
            ...userData,
            simsName: (person) ? person.DisplayForename + ' ' + person.DisplaySurname : '',
            photoId: userProfile.Identities[0].PhotoId,
            products: userProfile.Identities[0].Products,
            schoolName: (school) ? school.SchoolName : ''
          })
        })
      } else {
        resolve({
          ...userData
        })
      }
    }

    this.userContextLoad = new Promise((resolve) => {
      let currentStatus = authService.getStatus()
      if (currentStatus.isAuthenticated) {
        resolveUserContext(currentStatus, resolve)
      }

      authService.subscribeToAuthChanges((newAuthStatus) => {
        if (newAuthStatus.isAuthenticated) {
          resolveUserContext(newAuthStatus, resolve)
        }
      })
    })
  }

  userContextLoad = null;

  _getUserDataFromToken (token) {
    let decodedToken = jwtDecode(token)

    let result = {
      provider: decodedToken.provider,
      providername: decodedToken.providername,
      userId: null,
      schoolId: null
    }

    if (decodedToken.userorganisationidentifier) {
      let splitUserOrgIdentifier = decodedToken.userorganisationidentifier.split('|')
      result =
        {
          ...result,
          userId: splitUserOrgIdentifier[0].toLowerCase(),
          schoolId: splitUserOrgIdentifier[1]
        }
    }

    return result
  }

  get () {
    return this.userContextLoad
  }
  isFeatureEnabled = function (featurename) {
    var isFeatureEnabled = false
    if (this.featureToggleList.length > 0) {
      let dataFound = this.featureToggleList.find(f => f.Name.toLowerCase() === featurename.toLowerCase())
     if (dataFound) {
       isFeatureEnabled = dataFound.Enabled
     }
    }
    return isFeatureEnabled
  };
  checkFeatureToggle = function (widgetName) {
    if (this.featureToggleList.length > 0) {
      switch (widgetName.toLowerCase()) {
        case 'show-message-attachment':
          return this.isFeatureEnabled('show-message-attachment')
        case 'enable-send-email-homework':
          return this.isFeatureEnabled('enable-send-email-homework')
        case 'enable-sicknessreport':
          return this.isFeatureEnabled('enable-sicknessreport')
        default:
          return true
      }
    }
  };
}

export let userContextService = new UserContextService()
