import { UrlHelper } from './Utilities/bundle'
import Configuration from './ConfigurationService'
import AuthStore from '../Stores/AuthStore'
import { routeConfiguration } from './RouteConfigurationService'
import jwtDecode from 'jwt-decode'

class AuthService {
  static getAuthUrl (originalUrl, nonce, displayPrompt = true) {
    let encodedOriginal = encodeURIComponent(originalUrl)
    let promptParam = (!displayPrompt) ? '&prompt=none' : ''
    return Configuration.AuthorizationEndpoint +
                 '?response_type=code%20id_token%20token' +
                 '&client_id=' + Configuration.ClientId +
                 '&pfidpadapterid=' + Configuration.AdapterId +
                 '&scope=' + Configuration.Scopes +
                 '&redirect_uri=' + encodeURIComponent(Configuration.RedirectUri) +
                 '&nonce=' + nonce +
                 promptParam +
                 '&state=' + encodedOriginal
  }

  static generateNonce (length) {
    let text = ''
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
  }

  constructor () {
    let authStatus = this.getStatus()
    if (authStatus.isAuthenticated === true && window === window.top) {
      let decodedToken = jwtDecode(authStatus.accessToken)
      let tokenExpiryTimeAsSecondsSince1970 = decodedToken.exp
      this.scheduleSilentTokenRenewal(tokenExpiryTimeAsSecondsSince1970)
    }
  }

  logIn () {
    let authStatus = this.getStatus()
    if (authStatus.isAuthenticated === false) {
      let currentPage = routeConfiguration.getCurrentPageInfo().path

      if (currentPage === '/signedout') {
        currentPage = '/'
      }

      let nonce = AuthService.generateNonce(32)
      let authUrl = AuthService.getAuthUrl(currentPage, nonce, true)
      window.location = authUrl
    }
  }

  logOut (fromSimsId = true) {
    let authStatus = this.getStatus()
    if (authStatus.isAuthenticated === true) {
      AuthStore.dispatch({
        type: 'SET_UNAUTHENTICATED'
      })

      sessionStorage.clear()

      if (fromSimsId) {
        let token = authStatus.idToken
        let url = Configuration.LogoutEndpoint + '?id_token_hint=' + encodeURIComponent(token) + '&post_logout_redirect_uri=' + encodeURIComponent(Configuration.PostLogoutRedirectUrl)
        window.location.replace(url)
      }
    }
  }

  bounceBackSaveStateThenRedirect () {
    let params = UrlHelper.getParams(window.location.href)

    let newStatusAction = {
      type: 'SET_AUTHENTICATED',
      idToken: null,
      accessToken: null,
      isAuthenticated: false
    }

    newStatusAction.idToken = params['id_token']
    if (newStatusAction.idToken) {
      newStatusAction.accessToken = params['access_token']
      newStatusAction.isAuthenticated = true

      let decodedToken = jwtDecode(newStatusAction.accessToken)
      let tokenExpiryTimeAsSecondsSince1970 = decodedToken.exp

      AuthStore.dispatch(newStatusAction)

      if (window === window.top) {
        this.scheduleSilentTokenRenewal(tokenExpiryTimeAsSecondsSince1970)
      }

      let state = params['state']
      if (state) {
        state = decodeURIComponent(state)
        if (state === '/unauthorised') {
          routeConfiguration.redirectToRoute('/')
        } else {
          routeConfiguration.redirectToRoute(state)
        }

        return true
      }
    }
    return false
  }

    messageListener = (e) => {
      if (e.data === 'silentTokenReceived') {
        let altToken = jwtDecode(JSON.parse(localStorage.getItem('authStore')).accessToken)
        let altTokenExpiryTimeAsSecondsSince1970 = altToken.exp
        this.scheduleSilentTokenRenewal(altTokenExpiryTimeAsSecondsSince1970)
        AuthStore.dispatch({
          type: 'REFRESH_FROM_STORAGE'
        })
      } else {
        this.logOut(false)
        routeConfiguration.redirectToRoute('/signedOut')
      }
    }
    silentTokenRenewalTimeout = null;
    currentIFrame = null;

    scheduleSilentTokenRenewal (expiryTimeInSecondsSince1970) {
      if (this.silentTokenRenewalTimeout) {
        clearTimeout(this.silentTokenRenewalTimeout)
      }
      window.removeEventListener('message', this.messageListener, false)

      if (this.currentIFrame) {
        window.document.body.removeChild(this.currentIFrame)
        this.currentIFrame = null
      }

      let now = new Date()
      let timeNowInMillisecondsSince1970 = now.getTime()
      let expiryInMillisecondsSince1970 = expiryTimeInSecondsSince1970 * 1000
      let numberOfMillisecondsDelayBeforeRenew = (expiryInMillisecondsSince1970 - timeNowInMillisecondsSince1970)

      numberOfMillisecondsDelayBeforeRenew -= (60 * 1000)

      // If the token has already expired then remove the token from this site.
      if (expiryInMillisecondsSince1970 < timeNowInMillisecondsSince1970) {
        routeConfiguration.redirectToRoute('/signedout')
        return
      }

      console.log('minutes until silent token renewal = ', numberOfMillisecondsDelayBeforeRenew / 1000 / 60)

      let authTokenUrl = AuthService.getAuthUrl('/silent-token-renew', AuthService.generateNonce(32), false)

      let renewToken = () => {
        window.addEventListener('message', this.messageListener, false)
        this.currentIFrame = window.document.createElement('iframe')
        this.currentIFrame.src = authTokenUrl
        this.currentIFrame.height = 220
        this.currentIFrame.width = 220
        this.currentIFrame.style.display = 'none'
        window.document.body.appendChild(this.currentIFrame)
      }

      this.silentTokenRenewalTimeout = setTimeout(renewToken, numberOfMillisecondsDelayBeforeRenew)
    }

    silentTokenReceived () {
      if (window.top && window.parent && window !== window.top) {
        window.parent.postMessage('silentTokenReceived', window.location.protocol + '//' + window.location.host)
      }
    }

    silentTokenRenewFailed () {
      if (window.top && window.parent && window !== window.top) {
        window.parent.postMessage('silentTokenFailed', window.location.protocol + '//' + window.location.host)
      }
    }

    getStatus () {
      return AuthStore.getState()
    }

    subscribeToAuthChanges (func) {
      AuthStore.subscribe(function () {
        func(AuthStore.getState())
      })
    }
}

export let authService = new AuthService()
