import React, { Component } from 'react'
import PropTypes from 'prop-types'

class LinkToolbarButtonPopout extends Component {
  constructor (props) {
    super(props)

    this.state = {
      url: '',
      displayText: this.props.defaultDisplayText || ''
    }
  }

  componentDidMount () {
    this.displayTextElement.focus()
  }

    _onUrlChange = (e) => {
      this.setState({
        url: e.currentTarget.value
      })
    }

    _onDisplayTextChange = (e) => {
      this.setState({
        displayText: e.currentTarget.value
      })
    }

    _onAddClick = (e) => {
      e.stopPropagation()

      const url = this.state.url
      const displayText = this.state.displayText || ''

      let simsFormattedUrl = null
      if (url) {
        simsFormattedUrl = `[${displayText} | ${url}] `
      }

      this.props.onAddClick(simsFormattedUrl)
    }

    _onCancelClick = (e) => {
      e.stopPropagation()

      this.props.onCancelClick()
    }

    render () {
      return (
          <div className="link-toolbar-button-popout">
              <div className="form-horizontal">
                  <div className="form-group form-group-sm" >
                      <div className="col-sm-12">
                          <input id="link-title"
                              type="text"
                              className="form-control  link-toolbar-button-popout-input"
                              placeholder="Display text"
                              onChange={this._onDisplayTextChange}
                              value={this.state.displayText}
                              ref={el => { this.displayTextElement = el }}
                          />
                      </div>
                  </div>
                  <div className="form-group form-group-sm" >
                      <div className="col-sm-12">
                          <input id="link-url"
                              type="text"
                              className="form-control link-toolbar-button-popout-input"
                              placeholder="Web address"
                              onChange={this._onUrlChange}
                          />
                      </div>
                  </div>
                  <button className="btn btn-sm btn-primary right" onClick={this._onAddClick}>Add</button>
                  <button className="btn btn-sm btn-default right margin-right" onClick={this._onCancelClick}>Cancel</button>
              </div>
          </div>
      )
    }
}

LinkToolbarButtonPopout.propTypes = {
  defaultDisplayText: PropTypes.string,
  onAddClick: PropTypes.func,
  onCancelClick: PropTypes.func
}

export default LinkToolbarButtonPopout
