const FileHelper = {
  getHumanReadableFileSize: (size) => {
    if (size === 0) {
      return '0 Bytes'
    }

    var i = Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['Bytes', 'KB', 'MB', 'GB', 'TB'][i]
  },
  getFileExtensionsFromFileName: (filename) => {
    let lastIndex = filename.lastIndexOf('.')
    if (lastIndex > -1 && lastIndex < (filename.length - 1)) {
      return filename.substring(filename.lastIndexOf('.') + 1)
    }
    return ''
  }

}

export default FileHelper
