/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faExclamation, faHourglassEnd } from '@fortawesome/free-solid-svg-icons'
import { faClock, faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons'
import Moment from 'moment'

class HomeworkStudentItem extends Component {
  constructor (props) {
    super(props)

    this.state.student = this.props.item
    this.state.dueDate = this.props.dueDate
    this._studentItemOnClick = this._studentItemOnClick.bind(this)

    this.state.student.isSelected = false
    this.state.student.selectedClassName = GetItemSelectedClassName(false)
  }

    state = {
      student: {},
      dueDate: '',
      photoSrc: null
    }

    _studentItemOnClick () {
      let student = this.state.student
      student.isSelected = !student.isSelected
      student.selectedClassName = GetItemSelectedClassName(student.isSelected)

      this.setState({
        student: student
      })
    }

    // componentDidMount(){
    //     this.getStudentPhotoPromise = imageService.getStudent(this.state.student.SchoolGuid, this.state.student.StudentId, this.state.student.PhotoId, 2);

    //     this.getStudentPhotoPromise.then((photoSrc => {
    //         this.setState({photoSrc: photoSrc});
    //         }), () => {
    //             //Image cannot be received, no need to log anything
    //         })
    // }

    // componentWillUnmount() {
    //     if (this.getStudentPhotoPromise) {
    //         this.getStudentPhotoPromise.cancel();
    //     }
    // }

    render () {
      let PhotoTd = () => { return <td rowSpan={'3'} className="student-icon"><FontAwesomeIcon icon={faUser} size={'4x'}/></td> }
      if (this.state.photoSrc) {
        PhotoTd = () => {
          return (<td rowSpan="3" className="student-icon">
              <img
                  alt={this.state.student.DisplayForename + ' ' + this.state.student.DisplaySurname}
                  src={this.state.photoSrc}
                  title={this.state.student.DisplayForename + ' ' + this.state.student.DisplaySurname}
              />
          </td>)
        }
      }

      return (
          <div id={this.state.student.Id} className={'homework-student-list-item ' + this.state.student.selectedClassName} onClick={this._studentItemOnClick}>
              <div className="homework-student-list-item-table-container">
                  <table>
                      <tbody>
                          <tr>
                              <td rowSpan="3" className="homework-student-list-item-checkbox-container">
                                  <input type="checkbox" checked={this.state.student.isSelected} />
                              </td>
                              <PhotoTd />
                              <td className="student-details">{this.state.student.DisplayForename} {this.state.student.DisplaySurname}</td>
                              <td className="handed-in-status-td">
                                  <div className="handed-in-status">
                                      {GetHandedInStatusIcon(this.state.student.HandedInDate, this.state.dueDate)}
                                  </div>
                              </td>
                          </tr>
                          <tr/>
                          <tr>
                              <td className="student-details">{this.state.student.CurrentRegGroup}  Year {this.state.student.CurrentYearGroup}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      )
    }
}

function GetItemSelectedClassName (isSelected) {
  return isSelected ? 'student-item-selected' : 'student-item-not-selected'
}

function GetHandedInStatusIcon (handedInDate, dueDate) {
  let today = Moment().toISOString()
  let isHandedIn = (handedInDate !== null)

  // dueDate is a Date, handedInDate is a datetime
  // When doing a comparison of dueDate and handedInDate ignore the time element.
  let isLate = Moment(handedInDate).isAfter(dueDate, 'day')
  let isOverdue = today > Moment(dueDate).add(1, 'day').toISOString()

  let HandedIn = () => { return <div><FontAwesomeIcon icon={faArrowAltCircleDown} className="status-icon handed-in-icon"/><span className="tag">Handed In</span></div> }
  let HandedInLate = () => { return <div><FontAwesomeIcon icon={faHourglassEnd} className="status-icon handed-in-late-icon"/><span className="tag">Handed In Late</span></div> }
  let OverDue = () => { return <div><FontAwesomeIcon icon={faExclamation} className="status-icon overdue-icon"/><span className="tag">Overdue</span></div> }
  let NotHandedIn = () => { return <div><FontAwesomeIcon icon={faClock} className="status-icon still-waiting-icon"/><span className="tag">Not Yet Handed In</span></div> }

  let StateTag = HandedIn
  if (isHandedIn) {
    if (isLate) {
      StateTag = HandedInLate
    } else {
      StateTag = HandedIn
    }
  } else {
    if (isOverdue) {
      StateTag = OverDue
    } else {
      StateTag = NotHandedIn
    }
  }

  return (
      <StateTag />
  )
}

export default HomeworkStudentItem
