import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

class ConfirmModal extends Component {
    state = {
      showConfirmModal: false
    }
      _handleClose = () => {
        this.setState({
          showConfirmModal: false
        })
      }

      _handleConfirm = () => {
        this._handleClose()

        this.props.onConfirm()
      }

      _handleShow = () => {
        this.setState({
          showConfirmModal: true
        })
      }

      render () {
        let btnClasses = 'btn btn-default right ' + this.props.btnClasses
        return (
            <div>
                <Button
                    bsStyle={this.props.bsStyle}
                    type="button"
                    className= {btnClasses}
                    disabled={this.props.disabled}
                    onClick={this._handleShow}
                >
                    <i className={this.props.iconClasses} aria-hidden="true" />
                    {this.props.launchButtonText}
                </Button>

                <Modal show={this.state.showConfirmModal} onHide={this._handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.modalBody}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this._handleClose}>Close</Button>
                        <Button bsStyle={this.props.modalBsStyle} onClick={this._handleConfirm}>{this.props.confirmButtonText}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
      }
}

ConfirmModal.defaultProps = {
  modalTitle: PropTypes.string.isRequired,
  modalBody: PropTypes.element.isRequired,
  onConfirm: PropTypes.func.isRequired,
  bsStyle: 'warning',
  modalBsStyle: 'danger'
}

ConfirmModal.props = {
  modalTitle: PropTypes.string,
  modalBody: PropTypes.element,
  onConfirm: PropTypes.func,
  bsStyle: PropTypes.string,
  modalBsStyle: PropTypes.string
}

export default ConfirmModal
