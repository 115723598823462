import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './studentPercentage.scss'

class StudentPercentage extends Component {
  render () {
    let row = this.props.row
    let total = row.HandedInOnTime + row.HandedInLate + row.TotalOverdue + row.TotalDue
    let handedInPercentage = (row.HandedInOnTime / total) * 100
    let latePercentage = (row.HandedInLate / total) * 100
    let overDuePercentage = (row.TotalOverdue / total) * 100
    let currentPercentage = (row.TotalDue / total) * 100

      return (
          <div className="student-percentage">
              {total > 0 &&
              <>
                  <div className="sp-handed-in bar"
                      onMouseEnter={(e) => this.props.onMouseEnter(e, { name: 'Handed In', percentage: handedInPercentage, total: row.HandedInOnTime, color: '#90ed7d' })}
                      onMouseOut={() => this.props.onMouseOut()}
                      style={{ width: handedInPercentage + '%' }}
                  />
                  <div className="sp-handed-late bar"
                      onMouseEnter={(e) => this.props.onMouseEnter(e, { name: 'Handed In Late', percentage: latePercentage, total: row.HandedInLate, color: '#f7a35c' })}
                      onMouseOut={() => this.props.onMouseOut()}
                      style={{ width: latePercentage + '%' }}
                  />
                  <div className="sp-handed-not-handed-in bar"
                      onMouseEnter={(e) => this.props.onMouseEnter(e, { name: 'Not Handed In', percentage: overDuePercentage, total: row.TotalOverdue, color: '#f45b5b' })}
                      onMouseOut={() => this.props.onMouseOut()}
                      style={{ width: overDuePercentage + '%' }}
                  />
                  <div className="sp-handed-current bar"
                      onMouseEnter={(e) => this.props.onMouseEnter(e, { name: 'Due', percentage: currentPercentage, total: row.TotalDue, color: '#7cb5ec' })}
                      onMouseOut={() => this.props.onMouseOut()}
                      style={{ width: currentPercentage + '%' }}
                  />
              </>
              }

          </div>)
  }
}

StudentPercentage.propTypes = {
  onMouseEnter: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired
}

export default StudentPercentage
