import APIService from './APIService'
import Configuration from './ConfigurationService'

const engagementProductName = 'sims-engagement'
const schoolAdminProductName = 'schooladmin'
const optionsProductName = 'options'

class RoleService {
  constructor () {
      this.instance = APIService.create(Configuration.SchoolAdminApiUrl)
      this.state = {
      rolesPromise: null,
      lastUpdated: null
    }
  }

  getProductList () {
    return this.getCurrentUserRoles().then(result => {
      return result.data
    })
  }

  hasEngagementRole () {
    return this.getCurrentUserRoles().then(result => {
      let productRoles = result.data
      let engagementRole = 1
      return productRoles.hwAny(p => p.ProductCode === engagementProductName && (p.Roles & engagementRole) === engagementRole)
    })
  }

  hasSchoolAdminRole () {
    return this.getCurrentUserRoles().then(result => {
      let productRoles = result.data
      let schoolAdminRole = 1
      return productRoles.hwAny(p => p.ProductCode === schoolAdminProductName && (p.Roles & schoolAdminRole) === schoolAdminRole)
    })
  }

  hasOptionsRole () {
    return this.getCurrentUserRoles().then(result => {
      let productRoles = result.data
      let optionsRole = 1
      return productRoles.hwAny(p => p.ProductCode === optionsProductName && (p.Roles & optionsRole) === optionsRole)
    })
  }

  hasStandaloneHomeworkRole () {
    return this.getCurrentUserRoles().then(result => {
      let productRoles = result.data
      let standaloneHomeworkRole = 1
      return productRoles.hwAny(p => p.ProductCode === 'standalonehomework' && (p.Roles & standaloneHomeworkRole) === standaloneHomeworkRole)
    })
  }

  getCurrentUserRoles () {
    let now = new Date()
    let expiryTime = 60 * 1000
    let lastUpdated = this.state.lastUpdated
    let promise = this.state.rolesPromise
    if (!promise || !lastUpdated || now - lastUpdated > expiryTime) {
      this.state.rolesPromise = this.instance.get('v1/Role/CurrentUser')
      this.state.lastUpdated = now
    }
    return this.state.rolesPromise
  }
}

export let roleService = new RoleService()
