import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/lib/Button'
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup'
import GroupsGrid from './GroupsGrid'
import StudentsGrid from './StudentsGrid'
import LoDash from 'lodash'
import { groupService } from '../../../../../Services/GroupService'
import { studentService } from '../../../../../Services/StudentService'
import Promise from 'bluebird'

class StudentSelectorPopout extends Component {
  promises = []

  selectionType = {
    Groups: 'Groups',
    Students: 'Students'
  }

  groupTypes = {
    SubjectClasses: 'Subject Classes',
    RegGroups: 'Reg groups',
    Years: 'Years',
    UserDefined: 'User Defined'
  }

  state = {
    selectionType: this.selectionType.Groups,
    groupType: '[init]',
    selectedStudents: [],
    selectedGroups: [],
    searchFilter: null,
    allYears: [],
    allHouses: [],
    allUserDefined: [],
    allRegistration: [],
    allSubjectClasses: [],
    allPreAdmissionGroups: [],
    currentGroups: [],
    studentsLoaded: false,
    groupsLoaded: false,
    allCalculatedStudents: [],
    calculatingStudents: false
  }

    componentDidMount () {
      if (this.props.includePreAdmission) {
        this.groupTypes = {
          ...this.groupTypes,
          PreAdmission: 'Pre Admission'
        }
      }

      this._debouncedSearchFilterChange = LoDash.debounce((value) => {
        this.setState({ searchFilter: value })
      }, 250)

      this.promises.push(Promise.all([groupService.getYears(), groupService.getHouses(), groupService.getUserDefinedGroups(), groupService.getRegistrationGroups(), groupService.getSubjectClasses(), groupService.getPreAdmissionGroups()])
        .then(() => {
          this.setState({
            groupsLoaded: true
          })
        }))

      studentService.all().then(() => {
        this.setState({
          studentsLoaded: true
        })
      })

      this.promises.push(Promise.all([groupService.getYears(), groupService.getHouses(), groupService.getUserDefinedGroups(), groupService.getRegistrationGroups(), groupService.getSubjectClasses(), groupService.getPreAdmissionGroups()])
        .then((allGroups) => {
          this.setState({
            allYears: allGroups[0],
            allHouses: allGroups[1],
            allUserDefined: allGroups[2],
            allRegistration: allGroups[3],
            allSubjectClasses: allGroups[4],
            allPreAdmissionGroups: allGroups[5],
            currentGroups: allGroups[4]
          })

          this._reCalculateTotals(newSelection)
        }))

      let newSelection = {
        selectedStudents: this.props.selectedStudents,
        selectedGroups: this.props.selectedGroups
      }

      this.setState(newSelection)

      this._changeGroupType(this.groupTypes.SubjectClasses)

      this.inputSearch = null
    }

    componentWillUnmount () {
      this.promises.forEach(promise => {
        promise.cancel()
      })
      studentService.cancelRequest('all')
    }

    _changeSelectionType = (selectionType) => {
      if (selectionType !== this.state.selectionType) {
        this.setState({ selectionType: selectionType, searchFilter: null })
        if (this.inputSearch) {
          this.inputSearch.value = ''
        }
      }
    }

    _changeGroupType = (groupType) => {
      if (groupType !== this.state.groupType) {
        let currentGroups = null

        if (groupType === this.groupTypes.Years) {
          currentGroups = this.state.allYears
        } else if (groupType === this.groupTypes.UserDefined) {
          currentGroups = this.state.allUserDefined
        } else if (groupType === this.groupTypes.RegGroups) {
          currentGroups = this.state.allRegistration
        } else if (groupType === this.groupTypes.SubjectClasses) {
          currentGroups = this.state.allSubjectClasses
        } else if (groupType === this.groupTypes.PreAdmission) {
          currentGroups = this.state.allPreAdmissionGroups
        }

        this.setState({
          groupType: groupType,
          currentGroups: currentGroups
        })
      }
    }

    _setSelectedStudents = (selectedStudents) => {
      this.setState({ selectedStudents: selectedStudents })
      this._triggerSelectionChanged({
        selectedStudents: selectedStudents,
        selectedGroups: this.state.selectedGroups
      })
    }

    _setSelectedGroups = (selectedGroups) => {
      this.setState({ selectedGroups: selectedGroups })
      this._triggerSelectionChanged({
        selectedGroups: selectedGroups,
        selectedStudents: this.state.selectedStudents
      })
    }

    _triggerSelectionChanged = (newSelection) => {
      this._reCalculateTotals(newSelection)
      this.props.selectionChanged(newSelection)
    }

     _reCalculateTotals = (newSelection) => {
       let latestGroupsJoined = LoDash.join(newSelection.selectedGroups)
       let latestStudentsJoined = LoDash.join(newSelection.selectedStudents)
       if ((!this.joinedGroups || this.joinedGroups !== latestGroupsJoined) ||
        (!this.joinedStudents || this.joinedStudents !== latestStudentsJoined)) {
         this.setState({ calculatingStudents: true })
         this.joinedGroups = latestGroupsJoined
         this.joinedStudents = latestStudentsJoined

         let selectedPreAdmissionsGroups = newSelection.selectedGroups.hwWhere(x => { return this.state.allPreAdmissionGroups.hwAny(y => y.id === x) })

         this.promises.push(Promise.all([
           groupService.getStudentsInGroups(newSelection.selectedGroups),
           groupService.getPreAdmissionStudentsInGroups(selectedPreAdmissionsGroups)
         ]).then(
           (members) => {
             let allMembers = LoDash.union(members[0], members[1])
             allMembers = LoDash.union(allMembers, newSelection.selectedStudents)

             this.setState({
               allCalculatedStudents: allMembers,
               calculatingStudents: false
             })
           }
         ))
       }
     }

    _onSearchFilterChanged = (e) => {
      this._debouncedSearchFilterChange(e.target.value)
    }

    _clearAll = () => {
      this.setState({ selectedStudents: [], selectedGroups: [] })
      this._triggerSelectionChanged({
        selectedGroups: [],
        selectedStudents: []
      })

      // Not a great solution, however its difficult to do in any other way
      let allCheck = document.getElementById('select-all-checkbox')
      if (allCheck) {
        allCheck.checked = false
      }
    }

    _done = (e) => {
      this.props.closePopout()
      e.stopPropagation()
    }

    render () {
      return (
          <div className="student-selector-popout">
              <div className="student-selector-popout-top">
                  <ButtonGroup bsSize="small">
                      {
                        Object.keys(this.selectionType).map((key) => {
                          return (<Button key={this.selectionType[key]} id={this.selectionType[key]}

                              onClick={() => { this._changeSelectionType(this.selectionType[key]) }}
                              active={this.state.selectionType === this.selectionType[key]}
                              onChange={() => { }}
                                  >
                              {this.selectionType[key]}
                          </Button>)
                        })
                      }
                  </ButtonGroup>

                  <span className="student-selector-search-box -right">

                      <input type="Text"
                          placeholder="Search"
                          className="form-control input-sm"
                          onChange={this._onSearchFilterChanged}
                          ref={el => { this.inputSearch = el }}
                      />
                      <i className="fa fa-search search-icon" aria-hidden="true" />
                  </span>
              </div>

              {(this.state.selectionType === this.selectionType.Groups) &&
              <div className="student-selector-popout-mid">
                  <ButtonGroup bsSize="small">
                      {
                        Object.keys(this.groupTypes).map((key) => {
                          return (
                              <Button
                                  id={this.groupTypes[key]}
                                  key={this.groupTypes[key]}
                                  onClick={() => { this._changeGroupType(this.groupTypes[key]) }}
                                  active={this.state.groupType === this.groupTypes[key]}
                                  onChange={() => { }}
                                  disabled={(this.state.selectionType !== this.selectionType.Groups)}
                              >
                                  {this.groupTypes[key]}
                              </Button>
                          )
                        })
                      }
                  </ButtonGroup>
              </div>
              }

              {(this.state.selectionType === this.selectionType.Groups) &&
              <GroupsGrid onSelectionChanged={this._setSelectedGroups} selectedGroups={this.props.selectedGroups} allGroups={this.state.currentGroups} searchFilter={this.state.searchFilter} />
              }

              {(this.state.selectionType === this.selectionType.Students) &&
              <StudentsGrid onSelectionChanged={this._setSelectedStudents} selectedStudents={this.state.selectedStudents} searchFilter={this.state.searchFilter} />
              }

              {!this.state.groupsLoaded && (this.state.selectionType === this.selectionType.Groups) &&
              <div>
                  <div className="student-selector-loady" />
                  <span className="student-selector-loady-text">Loading groups ...</span>
              </div>
              }
              {!this.state.studentsLoaded && (this.state.selectionType === this.selectionType.Students) &&
              <div>
                  <div className="student-selector-loady" />
                  <span className="student-selector-loady-text">Loading students ...</span>
              </div>
              }

              <div className="student-selector-popout-preview-container">
                  {this.state.calculatingStudents === true &&
                  <b>...</b>
                  }
                  {this.state.calculatingStudents === false &&
                  <b>{this.state.allCalculatedStudents.length}</b>
                  }
                    &nbsp;students selected in total
              </div>

              <div className="student-selector-popout-button-container">
                  <Button onClick={this._clearAll}><i className="fa fa-trash" aria-hidden="true" />&nbsp;Clear All</Button>
                  <Button className="pull-right" onClick={this._done}>Done</Button>
              </div>
          </div>
      )
    }
}

StudentSelectorPopout.props = {
  selectionChanged: PropTypes.func,
  closePopout: PropTypes.func,
  selectedStudents: PropTypes.array,
  selectedGroups: PropTypes.array,
  includePreAdmission: PropTypes.bool
}

export default StudentSelectorPopout
