import React, { Component } from 'react'
import { routeConfiguration } from '../../../../Services/RouteConfigurationService'

class BreadCrumbs extends Component {
  state = {
    outerRouteTitle: '',
    innerRouteTitle: ''
  };

  componentDidMount () {
    routeConfiguration.subscribeToCurrentPathChange((page) => {
      this.setState({
        outerRouteTitle: page.outerRouteTitle,
        innerRouteTitle: page.innerRouteTitle
      })
    })

    let currentPage = routeConfiguration.getCurrentPageInfo()
    this.setState({
      outerRouteTitle: currentPage.outerRouteTitle,
      innerRouteTitle: currentPage.innerRouteTitle
    })
  }
  render () {
    if (this.state.innerRouteTitle) {
      return (
          <div className="pull-left breadcrumbs">
              <span className="ng-scope">
                  <span className="crumb-text ng-binding">{this.state.outerRouteTitle}</span>
                  <i className="fa fa-angle-double-right breadcrumb-angle ng-hide" />
              </span>
              <span className="ng-scope">
                  <span className="crumb-text ng-binding">{this.state.innerRouteTitle}</span>
              </span>
          </div>
      )
    }
    return (
        <div className="pull-left breadcrumbs">
            <span className="ng-scope">
                <span className="crumb-text ng-binding">{this.state.outerRouteTitle}</span>
            </span>
        </div>
    )
  }
}

export default BreadCrumbs
