import APIService from './APIService'
import Configuration from './ConfigurationService'
import sscache from 'session-storage-cache'

class ParentService {
  constructor () {
    this.instance = APIService.create(Configuration.PeopleApiUrl)
  }

  all (useCache = true) {
    const cacheKey = 'parent-service/v2/Parent/All'
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult && useCache) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v2/Parent/all/').then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  allPreAdmission (useCache = true) {
    const cacheKey = 'parent-service/v2/Parent/AllPreAdmission'
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult && useCache) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v2/Parent/AllPreAdmission/').then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }
}

export let parentService = new ParentService()
