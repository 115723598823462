import APIService from './APIService'
import Configuration from './ConfigurationService'
import sscache from 'session-storage-cache'
import LoDash from 'lodash'

class StudentService {
  constructor () {
    this.instance = APIService.create(Configuration.PeopleApiUrl)
  }

  cancelTokens = {
    allStudents: {
      id: 'all',
      cancelTokenSource: null
    }
  };

  cancelRequest (requestName) {
    let cancelToken = LoDash.find(this.cancelTokens, {
      'id': requestName
    })
    if (cancelToken && cancelToken.cancelTokenSource !== null) {
      cancelToken.cancelTokenSource.cancel('Cancelled by user')
    }
  }

  _allStudentMemoryCache = null;
  all (useCache = true) {
    let cancelTokenSource = APIService.getCancelTokenSource()
    this.cancelTokens.allStudents.cancelTokenSource = cancelTokenSource

    const cacheKey = 'student-service/v2/Student/All'
    const cacheTimeInMinutes = 10

    let cachedResult = this._allStudentMemoryCache || sscache.get(cacheKey)
    if (cachedResult && useCache) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v2/Student/All', {
        cancelToken: cancelTokenSource.token
      }).then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        this._allStudentMemoryCache = response.data
        return response.data
      })
    }
  }

  allPreAdmissions (useCache = true) {
    const cacheKey = 'student-service/v2/Student/PreAdmission'
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult && useCache) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('/v2/Student/PreAdmission').then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  getSingle (id) {
    return this.instance.get('v2/Student/Single/' + id).then((response) => response.data)
  }
}

export let studentService = new StudentService()
