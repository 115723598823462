import APIService from './APIService'
import Configuration from './ConfigurationService'
import sscache from 'session-storage-cache'
import * as Constants from '../Constants/constants'

class MessageService {
  constructor () {
    this.instance = APIService.create(Configuration.MessagesUrl, 600 * 1000)
    this.messageAttachmentInstance = APIService.create(Configuration.MessagesUrl, 60 * 1000, 'multipart/form-data')
  }

  send (recipients, subject, message, attachments) {
    let notification = { subject: subject, messageBody: message, attachments: attachments }

    return this.instance.post('/Notification/Notify', {
      Recipients: recipients,
      Message: notification
    })
      .then(result => {
        return result.data
      })
  }

  getAllowedAttachmentExtensions () {
    const address = '/v1/MessageAttachment/GetValidAttachmentFileTypes'
    const cacheKey = 'message-service/' + address
    const cacheTimeInMinutes = 30
    let cachedResult = sscache.get(cacheKey)
    if (cachedResult) {
      return Promise.resolve(cachedResult)
    } else {
      return this.messageAttachmentInstance.get(address).then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  async isFileExtensionValid (fileExtension) {
    var validFileExtensions = await this.getAllowedAttachmentExtensions()
    validFileExtensions = validFileExtensions.hwSelect(x => x.toLowerCase())
    return validFileExtensions.hwContains(fileExtension.toLowerCase())
  }
  getMaxFileSize () {
    const oneMeg = 1048576
    const maxFileSize = Constants.MESSAGEFILESIZE * oneMeg
    return maxFileSize
  }
  checkFileSize (file) {
    let maxFileSize = this.getMaxFileSize()
    if (file.size > maxFileSize) {
      return true
    }
    return false
  }
  upload (formData, config) {
    return this.messageAttachmentInstance.post('/v1/MessageAttachment/UploadAttachment', formData, config)
  }
}

export let messageService = new MessageService()
