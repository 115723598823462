import { homeworkService } from './HomeworkService'
import { studentService } from '../StudentService'
import { StudentHomeworkStatus } from './Enums'
import LoDash from 'lodash'
import HomeworkTeacherViewStore from '../../Stores/HomeworkTeacherViewStore'

class HomeworkTeacherViewService {
  GetStudentHomework (studentId, homeworkId) {
    return homeworkService.getStudentHomework(studentId, homeworkId)
  }

  CanIncrementStudent (direction) {
    let currentState = HomeworkTeacherViewStore.getState()

    if (!currentState.students) {
      return false
    }

    let students = currentState.students
    let activeStudent = currentState.activeStudent

    let studentsInActiveView = students.hwWhere(s => s.Status === currentState.activeView)
    let maximumIndexOfStudentInActiveView = studentsInActiveView.length - 1
    let indexOfCurrentStudent = studentsInActiveView.hwIndexForFirst(s => s === activeStudent)

    if (indexOfCurrentStudent + direction > maximumIndexOfStudentInActiveView || indexOfCurrentStudent + direction < 0) {
      return false
    }
    return true
  }

  IncrementToStudent (direction, newStatus) {
    let currentState = HomeworkTeacherViewStore.getState()
    if (this.CanIncrementStudent(direction)) {
      let students = currentState.students
      let activeStudent = currentState.activeStudent
      let studentsInActiveView = students.hwWhere(s => s.Status === currentState.activeView)
      let indexOfCurrentStudent = studentsInActiveView.hwIndexForFirst(s => s === activeStudent)
      let newStudent = studentsInActiveView[indexOfCurrentStudent + direction]

      HomeworkTeacherViewStore.dispatch({
        type: 'SET_ACTIVE_STUDENT',
        activeStudent: newStudent
      })
    } else {
      if (this.CanIncrementStudent(-direction)) {
        this.IncrementToStudent(-direction)
      } else {
        let activeStudent = currentState.activeStudent
        // if you cant move to another student, then stay on the same student (but trigger redux, so that the list updates)
        if (newStatus) {
          HomeworkTeacherViewStore.dispatch({
            type: 'SET_ACTIVE_VIEW',
            activeView: newStatus
          })
        }

        HomeworkTeacherViewStore.dispatch({
          type: 'SET_ACTIVE_STUDENT',
          activeStudent: activeStudent
        })
      }
    }
  }

  SaveHomeworkMark (studentId, homeworkId, result, comments) {
    return homeworkService.saveHomeworkMark(studentId, homeworkId, result, comments)
  }

  sendHomeworkMarked (feedbackDetail, schoolName, recipientsInfo) {
    return homeworkService.sendHomeworkMarked(feedbackDetail, schoolName, recipientsInfo)
  }

  GetStudents (homeworkId) {
    return new Promise((resolve, reject) => {
      this.promise = Promise.all([homeworkService.getHomeworkStudents(homeworkId), studentService.all(), homeworkService.getHomework(homeworkId)])
        .then(result => {
          let homeworkStudentLinks = result[0]
          let studentRecords = result[1]
          let homeworkDetail = result[2]

          let allStudentIds = studentRecords.map(x => x.Id)
          let filteredHomeworkStudentLinks = homeworkStudentLinks.Students
            .filter(student => allStudentIds.indexOf(student.StudentId) !== -1) // To exclude students who are no longer current.

          filteredHomeworkStudentLinks.forEach(studentLink => {
            let mappedStudent = LoDash.find(studentRecords, { 'Id': studentLink.StudentId })
            studentLink.DisplayForename = mappedStudent.DisplayForename
            studentLink.DisplaySurname = mappedStudent.DisplaySurname
            studentLink.CurrentYearGroup = mappedStudent.CurrentYearGroup
            studentLink.CurrentRegGroup = mappedStudent.CurrentRegGroup
            studentLink.PhotoId = mappedStudent.PhotoId
            studentLink.Email = mappedStudent.Email

            studentLink.Title = homeworkDetail.Title
            studentLink.Description = homeworkDetail.Description
            studentLink.SubjectId = homeworkDetail.SubjectId
            studentLink.Subject = homeworkDetail.Subject
            studentLink.CreatedById = homeworkDetail.CreatedById
            studentLink.CreatedBy = homeworkDetail.CreatedBy
            studentLink.SetById = homeworkDetail.SetById
            studentLink.SetBy = homeworkDetail.SetBy

            studentLink.Status = StudentHomeworkStatus.NOT_HANDED_IN

            if (studentLink.HandedInDate) {
              studentLink.Status = StudentHomeworkStatus.HANDED_IN

              if (studentLink.Result || studentLink.Comments) {
                studentLink.Status = StudentHomeworkStatus.MARKED
              }
            }
          })

          this._sortStudentsByName(filteredHomeworkStudentLinks, true)
          resolve(filteredHomeworkStudentLinks)
        })
        .catch((error) => {
          let errorMessage = 'Failed to load homework. ' + error.message
          reject(errorMessage)
        })
    })
  }

  handInHomework (homeworkId, activeStudent, date) {
    let activeStudentClone = {
      ...activeStudent
    }

    activeStudentClone.HandedInDate = date

    return new Promise((resolve, reject) => {
      homeworkService.saveStudentLinks(homeworkId, [activeStudentClone]).then(() => {
        resolve(activeStudent)
      }, reject)
    })
  }

  CanBrowserOpenPdf () {
    let hasAcrobatInstalled = () => {
      let getActiveXObject = (name) => {
        try { return new window.ActiveXObject(name) } catch (e) { }
      }

      return getActiveXObject('AcroPDF.PDF') || getActiveXObject('PDF.PdfCtrl')
    }

    let isIos = () => {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    }

    let isFirefox = () => {
      return navigator.userAgent.toLowerCase().indexOf('firefox') > -1
    }

    return navigator.mimeTypes['application/pdf'] || hasAcrobatInstalled() || isIos() || isFirefox()
  }

  _sortStudentsByName (homeworkStudents) {
    homeworkStudents.sort(function (a, b) {
      let compareA, compareB
      compareA = (a.DisplaySurname + a.DisplayForename).toLowerCase()
      compareB = (b.DisplaySurname + b.DisplayForename).toLowerCase()

      if (compareA < compareB) {
        return -1
      }
      if (compareA > compareB) {
        return 1
      }
      return 0
    })
  }
}

export default HomeworkTeacherViewService
