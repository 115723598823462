import APIService from '../APIService'
import Configuration from '../ConfigurationService'
import Moment from 'moment'

class StudentHomeworkOverviewService {
  constructor () {
    this.instance = APIService.create(Configuration.HomeworkUrl)
  }

  async getStudentOverview (studentId, startDate, endDate) {
    let startDateFormatted = encodeURIComponent(Moment(startDate).format('YYYY-MM-DD'))
    let endDateFormatted = encodeURIComponent(Moment(endDate).format('YYYY-MM-DD'))

    return this.instance.get(`/v3/ManagementDashboard/GetStudentHomeworkOverview/?studentId=${studentId}&timeRangeStartDate=${startDateFormatted}&timeRangeEndDate=${endDateFormatted}`).then((response) => {
        return response.data
      })
    }
}

export default StudentHomeworkOverviewService
