import React, { Component } from 'react'
import './homework-teacher-view-student-list.scss'
import HomeworkTeacherViewStore from '../../../../Stores/HomeworkTeacherViewStore'
import { HomeworkTeacherViewService } from '../../../../Services/Homework/Bundle'
import { StudentHomeworkStatus } from '../../../../Services/Homework/Enums'
import HomewordEmptyList from './HomeworkEmptyList'
import Loady from '../../../Common/Loady'

const nonExpandedHeight = 630

class HomeworkStudentList extends Component {
    state = {
      isFullScreenMode: false,
      maxHeight: nonExpandedHeight,
      students: [],
      activeStudentId: null,
      hasSetInitialStudent: false,
      activeView: StudentHomeworkStatus.HANDED_IN,
      isLoading: false,
      notHandedInCount: 0,
      handedInCount: 0,
      markedCount: 0
    }

    studentRefs = {};

    componentDidMount () {
      let adjustHeightToFullScreen = () => {
        var c = this
        setTimeout(() => {
          let elHeight = document.getElementsByClassName('homework-teacher-expandable-viewer')[0].clientHeight
          c.setState({
            maxHeight: (elHeight - 43)
          })
        }, 0)
      }

      let adjustHeight = () => {
        if (HomeworkTeacherViewStore.getState().isFullScreenMode) {
          adjustHeightToFullScreen()
        } else {
          this.setState({
            maxHeight: nonExpandedHeight
          })
        }
      }

      HomeworkTeacherViewStore.subscribe(() => {
        let currentState = HomeworkTeacherViewStore.getState()

        adjustHeight()

        this.setState({
          homeworkId: currentState.homeworkId,
          activeStudent: currentState.activeStudent,
          activeView: currentState.activeView
        })

        if (currentState.activeStudent) {
          let currentStudentElement = this.refs[currentState.activeStudent.StudentId]
          if (currentStudentElement) {
            currentStudentElement.scrollIntoView({ block: 'center', behavior: 'smooth' })
          }
        }
      })

      this._mount()
    }

    _mount () {
      let s = new HomeworkTeacherViewService()
      let currentState = HomeworkTeacherViewStore.getState()
      if (this.state.students !== currentState.students) {
        this.setState({
          isLoading: true
        })

        s.GetStudents(currentState.homeworkId).then((students) => {
          if (!this.state.hasSetInitialStudent) {
            let hasNotHandedInStudents = students.hwAny(s => s.Status === StudentHomeworkStatus.NOT_HANDED_IN)
            let hasHandedInStudents = students.hwAny(s => s.Status === StudentHomeworkStatus.HANDED_IN)
            let hasMarkedStudents = students.hwAny(s => s.Status === StudentHomeworkStatus.MARKED)

            let newActiveView = StudentHomeworkStatus.HANDED_IN
            if (!hasHandedInStudents) {
              if (hasNotHandedInStudents) {
                newActiveView = StudentHomeworkStatus.NOT_HANDED_IN
              } else if (hasMarkedStudents) {
                newActiveView = StudentHomeworkStatus.MARKED
              }
            }

            let firstStudent = students.hwWhere(s => s.Status === newActiveView).hwFirstOrDefault()
            if (firstStudent) {
              if (currentState.activeStudent !== firstStudent) {
                this._setActiveStudent(firstStudent)
              }
            }

            if (this.props.initalStudentId) {
              let initialStudent = students.hwFirstOrDefault(x => x.StudentId === this.props.initalStudentId)
              newActiveView = initialStudent.Status
              this._setActiveStudent(initialStudent)
            }

            this.setState({
              students: students,
              isLoading: false,
              hasSetInitialStudent: true,
              activeView: newActiveView
            })

            HomeworkTeacherViewStore.dispatch({
              type: 'SET_STUDENTS',
              students: students
            })

            HomeworkTeacherViewStore.dispatch({
              type: 'SET_ACTIVE_VIEW',
              activeView: newActiveView
            })
          }
        })
      }
    }

    _setActiveStudent (student) {
      HomeworkTeacherViewStore.dispatch({
        type: 'SET_ACTIVE_STUDENT',
        activeStudent: student
      })
    }

    _setActiveView (activeView) {
      HomeworkTeacherViewStore.dispatch({
        type: 'SET_ACTIVE_VIEW',
        activeView: activeView
      })
    }

    _refresh () {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.hasSetInitialStudent = false
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.students = []
      this._mount()
    }

    render () {
      let getDiscriminator = (student) => {
        if (student.CurrentYearGroup && student.CurrentRegGroup) {
          return 'Year ' + student.CurrentYearGroup + '|' + student.CurrentRegGroup
        }

        if (student.CurrentYearGroup && !student.CurrentRegGroup) {
          return 'Year ' + student.CurrentYearGroup
        }

        if (student.CurrentRegGroup && !student.CurrentYearGroup) {
          return student.CurrentRegGroup
        }

        return null
      }

      return (
          <>
              <Loady isLoading={this.state.isLoading} isOpaque />
              <div id="homework-teacher-view-student-list">

                  <div className="student-list-refresh" onClick={() => { this._refresh() }}><i className="fa fa-refresh" aria-hidden="true" /></div>

                  <div className="tabs">
                      <a href="Not Handed In" onClick={($event) => { this._setActiveView(StudentHomeworkStatus.NOT_HANDED_IN); $event.preventDefault() }}>
                          <div className={(this.state.activeView === StudentHomeworkStatus.NOT_HANDED_IN) ? 'tab active' : 'tab'}>
                              Not Handed In
                              {this.state.students.hwCount((s) => s.Status === StudentHomeworkStatus.NOT_HANDED_IN) > 0 &&
                              <span className="num">{this.state.students.hwCount((s) => s.Status === StudentHomeworkStatus.NOT_HANDED_IN)}</span>
                              }
                          </div>
                      </a>
                      <a href="Handed In" onClick={($event) => { this._setActiveView(StudentHomeworkStatus.HANDED_IN); $event.preventDefault() }}>
                          <div className={(this.state.activeView === StudentHomeworkStatus.HANDED_IN) ? 'tab active' : 'tab'}>
                              Handed In
                              {this.state.students.hwCount((s) => s.Status === StudentHomeworkStatus.HANDED_IN) > 0 &&
                              <span className="num">{this.state.students.hwCount((s) => s.Status === StudentHomeworkStatus.HANDED_IN)}</span>
                              }
                          </div>
                      </a>
                      <a href="Marked" onClick={($event) => { this._setActiveView(StudentHomeworkStatus.MARKED); $event.preventDefault() }}>
                          <div className={(this.state.activeView === StudentHomeworkStatus.MARKED ? 'tab active' : 'tab')}>
                              Marked
                              {this.state.students.hwCount((s) => s.Status === StudentHomeworkStatus.MARKED) > 0 &&
                              <span className="num">{this.state.students.hwCount((s) => s.Status === StudentHomeworkStatus.MARKED)}</span>
                              }
                          </div>
                      </a>
                  </div>

                  <div className="students" style={{ height: this.state.maxHeight }}>

                      {this.state.students.hwAll((s) => s.Status !== this.state.activeView) &&
                      <HomewordEmptyList activeView={this.state.activeView} />
                      }

                      {this.state.students && this.state.students.map(s => {
                        if (s.Status !== this.state.activeView) {
                          return null
                        }
                        return (
                            <a href={'#' + s.DisplayForename} key={s.StudentId} ref={s.StudentId} className="student-item-anchor active" onClick={($event) => { this._setActiveStudent(s); $event.preventDefault() }}>
                                <div className={this.state.activeStudent === s ? 'student-item active' : 'student-item'}>

                                    {this.state.activeStudent === s &&
                                    <div className="active-arrow" />
                                    }
                                    <span className="student-item-name">{s.DisplayForename} {s.DisplaySurname}</span>
                                    <span className="student-item-status">{s.Status}</span>
                                    <span className="student-item-year">{getDiscriminator(s)}</span>
                                </div>
                            </a>
                        )
                      })}

                  </div>
              </div>
          </>

      )
    }
}

export default HomeworkStudentList
