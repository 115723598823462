import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './studentPercentageTooltip.scss'

class StudentPercentageToolTip extends Component {
  render () {
      return (
          <div className="student-percentage-tooltip"
              style={{
        top: this.props.top + 'px',
        left: this.props.left + 'px',
        display: (this.props.isVisible ? 'block' : 'none'),
        border: 'solid 1px ' + this.props.color }}
          >
              <b>{this.props.title}</b><br/>
              {this.props.percentage}%<br/>
              {this.props.total} in total
          </div>
       )
  }
}

StudentPercentageToolTip.propTypes = {
  color: PropTypes.string,
  isVisible: PropTypes.bool,
  left: PropTypes.number,
  percentage: PropTypes.number,
  title: PropTypes.string,
  top: PropTypes.number,
  total: PropTypes.number
}

export default StudentPercentageToolTip
