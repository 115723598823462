import React, { Component } from 'react'
import AuthStore from '../../../Stores/AuthStore'

class SignedOut extends Component {
  componentDidMount () {
    AuthStore.dispatch({
      type: 'SET_UNAUTHENTICATED'
    })
    sessionStorage.clear()
  }

  render () {
    return (
        <div className="panel panel-primary panel-default">
            <div className="panel-heading">Signed out</div>
            <div className="panel-body">
          You are now signed out of the system.
            </div>
        </div>
    )
  }
}

export default SignedOut
