import React, { Component } from 'react'
import './empty-list.scss'

class EmptyList extends Component {
  render () {
    return (
        <div className="empty-list-component">
            No data
        </div>
    )
  }
}

export default EmptyList
