import React, { Component } from 'react'
import LeftNavItemInner from '../LeftNavItemInner'
import PropTypes from 'prop-types'
import { routeConfiguration } from '../../../../Services/RouteConfigurationService'
import { appInsightsService } from '../../../../Services/AppInsightsService'
import { schoolService } from '../../../../Services/SchoolService'

const blockStyle = {
  display: 'block'
}
class LeftNavItem extends Component {
  state = {
    isOpen: false
  }

  constructor (props) {
    super(props)
    routeConfiguration.subscribeToCurrentPathChange((page) => {
      this.setState({ isOpen: (this.props.item.path === page.outerRoutePath) })
    })
  }

  toggleOpen () {
    this.setState({ isOpen: !this.state.isOpen })
  }

  anchorClick (e) {
    let schoolId = schoolService.getSchoolId()
    let innertext = e.target.innerText
      Promise.all([schoolId]).then(function (results) {
        schoolId = results[0]
        appInsightsService.trackEvent('Clicked From ' + innertext, { ActiveSchoolId: schoolId })
      })
    if (this.props.item.children && this.props.item.children.length) {
      e.preventDefault()
    }
  }

  componentDidMount () {
    this.setState({ isOpen: (this.props.item.path === this.props.activePage.outerRoutePath) })
  }

  render () {
    function ChildrenItems (props) {
      if (!props.isOpen) {
        return null
      }

      if (props.children) {
        return (
            <ul className="slide-down left-nav-list tier2" style={blockStyle}>
                {props.children.map((child) => <LeftNavItemInner item={child} key={child.path} activePage={props.activePage} />)}
            </ul>
        )
      }
      return null
    }

    function Chevrons (props) {
      if (!props.children || !props.children.length) {
        return null
      }

      if (props.isOpen) {
        return <i className="fa fa-chevron-up up visible-md-inline visible-lg-inline" />
      }
      return <i className="fa fa-chevron-down down visible-md-inline visible-lg-inline" aria-hidden="true" />
    }

    function HoverBead (props) {
      if ((!props.item.children) || (!props.item.children.length)) {
        return <div className="left-nav-li-hover-bead" />
      }
      return null
    }

    let CurrentBead = function (props) {
      if (props.item.path === props.activePage.path) {
        return <div className="left-nav-li-current" />
      }
      return null
    }

    return (
        <li className="expanded active" onClick={() => { this.toggleOpen() }}>
            <div className="li-inner outer-hide">

                <div ng-show="tier1Item.items" className="left-nav-li-hover-bar" />

                <HoverBead {...this.props} />
                <CurrentBead {...this.props} />
                <a id="mnu-Security" href={(this.props.item.useUrl) ? this.props.item.path : '/#' + this.props.item.path} target= {this.props.item.target} onClick={(e) => { this.anchorClick(e) }} className="">
                    <i className={this.props.item.icon} aria-hidden="true" />
                    <span className="menu-item-parent visible-md-inline visible-lg-inline ng-binding">{this.props.item.title}</span>
                    <Chevrons children={this.props.item.children} isOpen={this.state.isOpen} />
                </a>

                <ChildrenItems children={this.props.item.children} isOpen={this.state.isOpen} activePage={this.props.activePage} />
            </div>
        </li>
    )
  }
}

LeftNavItem.propTypes = {
  activePage: PropTypes.object,
  item: PropTypes.object
}

export default LeftNavItem
