import React, { Component } from 'react'
import './homework-select-all.css'

class HomeworkSelectAll extends Component {
  render () {
    return (
        <div className="home-work-select-all">
            <div className="home-work-select-all-inner">
                <input type="checkbox" onChange={this.props.onChange}/>
            </div>
        </div>
    )
  }
}

export default HomeworkSelectAll
