import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import StudentPreview from './StudentPreview'
import ParentPreview from './ParentPreview'
import { ToRecipientType } from '../../../Stores/MessagesStore'
import { parentService } from '../../../Services/ParentService'
import { schoolAdminUserInviteService } from '../../../Services/SchoolAdminUserInviteService'
import { studentService } from '../../../Services/StudentService'

class SelectionPreview extends Component {
  state = {
    show: false,
    selectedRecipientType: null,
    selectedStudents: [],
    selectedGroups: []
  }

  componentDidMount () {
    // Preload resources so that the preview is snappy
    parentService.all(true)
    parentService.allPreAdmission(true)
    studentService.all(true)
    studentService.allPreAdmissions(true)

    schoolAdminUserInviteService.allRegisteredParents(true)
    schoolAdminUserInviteService.allRegisteredPreAdmissionParents(true)
    schoolAdminUserInviteService.allRegisteredStudents(true)
    schoolAdminUserInviteService.allRegisteredPreAdmissionStudents(true)
  }

  _onPreviewClick (e, selectedRecipientType, selectedStudents, selectedGroups) {
    e.preventDefault()

    this._handleShow(selectedRecipientType, selectedStudents, selectedGroups)
  }

  _handleClose = () => {
    this.setState({ show: false })
  }

  _handleShow = (selectedRecipientType, selectedStudents, selectedGroups) => {
    this.setState({
      show: true,
      selectedRecipientType: selectedRecipientType,
      selectedStudents: selectedStudents,
      selectedGroups: selectedGroups
    })
  }

  _tabChange = (key) => {
    this.setState({ currentTab: key })
  }

  render () {
    const selectedRecipientType = this.state.selectedRecipientType

    let studentPreview = null
    if (selectedRecipientType === ToRecipientType.STUDENTS || selectedRecipientType === ToRecipientType.STUDENTANDPARENTS) {
      studentPreview = <StudentPreview selectedStudents={this.state.selectedStudents} selectedGroups={this.state.selectedGroups} />
    }

    let parentPreview = null
    if (selectedRecipientType === ToRecipientType.PARENTS || selectedRecipientType === ToRecipientType.STUDENTANDPARENTS) {
      parentPreview = <ParentPreview selectedStudents={this.state.selectedStudents} selectedGroups={this.state.selectedGroups} />
    }

    let showPreview = ((this.props.selectedStudents && this.props.selectedStudents.hwAny()) || (this.props.selectedGroups && this.props.selectedGroups.hwAny()))

    return (
        <span>
            {showPreview &&
            <a href="preview" className="preview-link" onClick={(e) => this._onPreviewClick(e, this.props.selectedRecipientType, this.props.selectedStudents, this.props.selectedGroups)}>{this.props.previewLinkText}</a>
            }

            {this.state.show &&
            <Modal show={this.state.show} onHide={this._handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {studentPreview}

                    {studentPreview && parentPreview &&
                    <hr />
                    }

                    {parentPreview}

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this._handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            }
        </span>
    )
  }
}

export default SelectionPreview
