import APIService from './APIService'
import Configuration from './ConfigurationService'
import sscache from 'session-storage-cache'

class SchoolAdminUserInviteService {
  constructor () {
    this.instance = APIService.create(Configuration.SchoolAdminApiUrl)
  }

  allRegisteredStudents (useCache = true) {
    const cacheKey = 'user-invite/v1/UserInvite/AcceptedForStaff/ALLSTUDENTS'
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult && useCache) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v1/UserInvite/AcceptedForStaff/ALLSTUDENTS').then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  allRegisteredPreAdmissionStudents (useCache = true) {
    const cacheKey = 'user-invite/v1/UserInvite/AcceptedForStaff/ANYPREADMISSION'
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult && useCache) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v1/UserInvite/AcceptedForStaff/ANYPREADMISSION').then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  allRegisteredPreAdmissionParents (useCache = true) {
    const cacheKey = 'user-invite/v1/UserInvite/AcceptedForStaff/ALLPREADMISSIONPARENTS'
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult && useCache) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v1/UserInvite/AcceptedForStaff/ALLPREADMISSIONPARENTS').then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }

  allRegisteredParents (useCache = true) {
    const cacheKey = 'user-invite/v1/UserInvite/AcceptedForStaff/ALLPARENTS'
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult && useCache) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get('v1/UserInvite/AcceptedForStaff/ALLPARENTS').then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }
}

export let schoolAdminUserInviteService = new SchoolAdminUserInviteService()
