import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StudentHomeworkStatus } from '../../../../../Services/Homework/Enums'
import './homework-empty-list.scss'

class HomeworkEmptyList extends Component {
  render () {
    return (
        <div id="homework-teacher-view-student-list">
            {this.props.activeView === StudentHomeworkStatus.NOT_HANDED_IN &&
            <div className="empty-list">
                <span className="empty-list-text">All homework handed in</span>
                <br/><i className="fa fa-smile-o" aria-hidden="true" />
            </div>
            }
            {this.props.activeView === StudentHomeworkStatus.HANDED_IN &&
            <div className="empty-list">
                <span className="empty-list-text">No homework to mark</span>
                <br/><i className="fa fa-meh-o" aria-hidden="true" />
            </div>
            }
            {this.props.activeView === StudentHomeworkStatus.MARKED &&
            <div className="empty-list">
                <span className="empty-list-text">No homework marked</span>
                <br/><i className="fa fa-meh-o" aria-hidden="true" />
            </div>
            }
        </div>
    )
  }
}

export default HomeworkEmptyList

HomeworkEmptyList.propTypes = {
  activeView: PropTypes.string.isRequired
}
