import React, { Component } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { ToRecipientType } from '../../../Stores/MessagesStore'

class RecipientSelector extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedRecipientType: ToRecipientType.PARENTS
    }

    this.props.onSelectedChanged(ToRecipientType.PARENTS)
  }

  _onSelectedRecipientTypeChange (selectedRecipientType) {
    this.setState({
      selectedRecipientType: selectedRecipientType
    })

    this.props.onSelectedChanged(selectedRecipientType)
  }

  render () {
    return (
        <ButtonGroup>
            {
              Object.keys(ToRecipientType).map((key) => {
                return (<Button key={ToRecipientType[key]}
                    value={ToRecipientType[key]}
                    onClick={(e) => this._onSelectedRecipientTypeChange(ToRecipientType[key])}
                    active={this.state.selectedRecipientType === ToRecipientType[key]}
                        >
                    {ToRecipientType[key]}
                </Button>)
              })
            }
        </ButtonGroup>
    )
  }
}

export default RecipientSelector
