import React, { Component } from 'react'
import AttachmentInfo from '../AttachmentInfo'
import LoDash from 'lodash'
import { attachmentsService } from '../../../Services/AttachmentsService'
import FileHelper from '../../../Services/Utilities/FileHelper'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import './attachments.css'

class Attachments extends Component {
  state = {
    isUploading: false,
    attachments: [],
    validationErrors: []
  }

  _handleUploadingStatusChange = (isUploading) => {
    this.setState({
      isUploading: isUploading
    })

    this.props.onUploadingStatusChange(isUploading)
  }

  _assignBlobReference (data) {
    let attachments = this.props.attachments
    attachments.forEach(attachment => {
      if (attachment.FileName === data.FileName) {
        attachment.Id = data.BlobReference
      }
    })

    this._updateAttachments(attachments)
  }

  _assignAttachmentTitle (fileName, title) {
    let attachments = this.props.attachments
    attachments.forEach(attachment => {
      if (attachment.FileName === fileName) {
        attachment.Title = title
      }
    })

    this._updateAttachments(attachments)
  }

  _assignAttachmentDescription (fileName, description) {
    let attachments = this.props.attachments
    attachments.forEach(attachment => {
      if (attachment.FileName === fileName) {
        attachment.Description = description
      }
    })

    this._updateAttachments(attachments)
  }

  _trackProgress (thisAttachment) {
    let config = {
      onUploadProgress: event => {
        let attachments = this.props.attachments
        attachments.forEach(attachment => {
          if (attachment.FileName === thisAttachment.FileName) {
            attachment.UploadProgress = Math.round((event.loaded * 100) / event.total)
          }
        })

        this._updateAttachments(attachments)
      },
      timeout: 3600000
    }
    return config
  }

  async _onDrop (files) {
    this._onAttachmentDrop()
    let newAttachmentsToAdd = []
    let consumerService = attachmentsService.getConsumerComponent(this.props.consumerName)
    let maxQuantityReached = false
    if (attachmentsService.checkNoOfFiles(this.props.attachments, files, this.props.consumerName)) {
      this._onAttachmentUploadFailed(`Only three attachments can be attached of size ${FileHelper.getHumanReadableFileSize(consumerService.getMaxFileSize())} each`)
      maxQuantityReached = true
    }
    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      if (maxQuantityReached === true) {
        continue
      }
      let fileExtension = FileHelper.getFileExtensionsFromFileName(file.name)
      if (!await consumerService.isFileExtensionValid(fileExtension)) {
        this._onAttachmentUploadFailed(`"${file.name}" is not supported`)
        continue
      }
      if (consumerService.checkFileSize(file)) {
        this._onAttachmentUploadFailed(`Size of "${file.name}" must be less than ${FileHelper.getHumanReadableFileSize(consumerService.getMaxFileSize())}`)
        continue
      }

      if (!this.props.attachments.hwAny(x => x.FileName === file.name)) {
        let attachment = {
          Id: '',
          Title: '',
          Description: '',
          FileName: file.name,
          FileSize: file.size,

          UploadProgress: 0
        }

        this._handleUploadingStatusChange(true)
        newAttachmentsToAdd.push(attachment)
        attachmentsService.upload(file, this._trackProgress(attachment), this.props.consumerName).then(result => {
          this._assignBlobReference(result.data)
        }).catch(error => {
          this._onAttachmentUploadFailed(`Upload of "${attachment.FileName}" attachment failed`)
          this._removeAttachment(attachment.FileName)
          console.log(error)
        }).then(() => {
          this._handleUploadingStatusChange(false)
        })
      }
    }

    let allAttachments = LoDash.union(newAttachmentsToAdd, this.props.attachments)
    this._updateAttachments(allAttachments)
  }

  _removeAttachment = (fileName) => {
    let attachments = this.props.attachments.hwRemove(x => x.FileName === fileName)

    this._updateAttachments(attachments)
    this._onValidationErrorsChange([])
  }

  _updateAttachments (attachments) {
    this.props.onChange(attachments)
  }

  _onAttachmentUploadFailed = (message) => {
    let validationErrors = [...this.state.validationErrors, message]
    this.setState({
      ...this,
      validationErrors: validationErrors
    })

    this._onValidationErrorsChange(validationErrors)
  }

  _onAttachmentDrop = () => {
    if (this.state.validationErrors.length === 0) {
      return
    }

    this.setState({
      ...this,
      validationErrors: []
    })

    this._onValidationErrorsChange([])
  }

  _onValidationErrorsChange = (validationErrors) => {
    this.props.onValidationErrorsChange(validationErrors)
  }

  render () {
    return (
        <Dropzone ref={(el) => { this.dropzone = el }} className="ignore" disableClick onDrop={(files) => this._onDrop(files)} activeClassName="dragActive">
            <div className="attachment-container">
                {this.props.attachments.length === 0 &&
                <span className="attachment-container-text">Drag files to upload</span>
          }
                <div className="attachment-container-scroll-container">
                    {this.props.attachments.map(attachment =>
              (
                  <AttachmentInfo key={attachment.FileName}
                      attachment={{
                    extension: FileHelper.getFileExtensionsFromFileName(attachment.FileName),
                    fileName: attachment.FileName,
                    name: attachment.Title || attachment.FileName.substring(0, attachment.FileName.lastIndexOf('.')),
                    id: attachment.Id,
                    description: attachment.Description,
                    fileSize: attachment.FileSize,
                    uploadProgress: attachment.UploadProgress || 100
                  }}
                      onTitleChanged={(f, t) => this._assignAttachmentTitle(f, t)}
                      onDescriptionChanged={(f, d) => this._assignAttachmentDescription(f, d)}
                      onClose={this._removeAttachment}
                  />
              ))
            }
                </div>
                <button type="button" onClick={() => this.dropzone.open()} className="btn btn-default upload-attachment" disabled={this.isUploading}>
                    <i className="fa fa-cloud-upload" aria-hidden="true" /> Upload Attachment
                </button>

            </div>
        </Dropzone>
    )
  }
}

Attachments.defaultProps = {
  onUploadingStatusChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidationErrorsChange: PropTypes.func.isRequired,
  attachments: [],
  consumerName: PropTypes.string.isRequired
}

Attachments.props = {
  onUploadingStatusChange: PropTypes.func,
  onChange: PropTypes.func,
  onValidationErrorsChange: PropTypes.func,
  attachments: PropTypes.array,
  consumerName: PropTypes.string
}

export default Attachments
