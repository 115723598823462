import { Component } from 'react'
import { routeConfiguration } from '../../../Services/RouteConfigurationService'
import ReactGA from 'react-ga'
import Configuration from '../../../Services/ConfigurationService'

class GoogleAnalytics extends Component {
  componentDidMount () {
    routeConfiguration.subscribeToCurrentPathChange((page) => {
      let currentPage = page.path

      if (currentPage) {
        let skipUrlList = Configuration.skipAnalyticsUrls.split(',')

        // checks path to skip in analytics records
        for (let cnt = 0; cnt < skipUrlList.length; cnt++) {
          var result = (currentPage.indexOf(skipUrlList[cnt]) > -1)
          if (result === true) {
            break
          }
        }

        if (!result) {
          ReactGA.initialize(Configuration.TransactionCode)

          /* eslint-disable */
              var URL = currentPage.replace(/(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/g, '');
              URL = URL.replace(/[0-9]/g, '');;
              URL = URL.replace(/([^:]\/)\/+/g, "$1");
              /* eslint-enable */

          ReactGA.pageview(URL)
        }
      }
    })
  }

  render () {
    return null
  }
}

export default GoogleAnalytics
