import { createStore, compose, applyMiddleware } from 'redux'
import logger from 'redux-logger'
let initialState = {
  path: '/'
}

const enhancer = compose(
  applyMiddleware(logger)
)

function setPath (state, action) {
  switch (action.type) {
  case 'SET_NEWPATH':
    return {
      ...state,
      path: action.path
    }
  default:
    return state
  }
}

let store = createStore(setPath, initialState, enhancer)

export default store
