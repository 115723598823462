import React, { Component } from 'react'
import { connect } from 'react-redux'
import { StudentSelector, FieldValiationError, LinkToolbarButton } from '../Common/Forms/bundle'
import './messages.css'
import SelectionPreview from '../Common/Preview'
import SendPreview from '../Common/Preview/SendPreview'
import Alert from '../Common/Alerts/Alert.js'
import RecipientSelector from './RecipientSelector'
import { MessagesStore, ActionTypes, ToRecipientType, MessagesStatus } from '../../Stores/MessagesStore'
import { appInsightsService } from '../../Services/AppInsightsService'
import { messageService } from '../../Services/MessageService'
import { groupService } from '../../Services/GroupService'
import { parentService } from '../../Services/ParentService'
import LoDash from 'lodash'
import Attachments from '../Attachment/Attachments'
import * as Constants from '../../Constants/constants'
import { userContextService } from '../../Services/UserContextService'
import { schoolService } from '../../Services/SchoolService'

class Messages extends Component {
  state = {
    focusedElement: 'messageSubject',
    validationErrors: [],
    attachments: [],
    loading: undefined
  };
  constructor (props) {
    super(props)
    this.featureToggleOn()
  }
  async featureToggleOn () {
    setTimeout(() => {
      let ft = userContextService.checkFeatureToggle(Constants.MESSAGEFEATURETOGGLE)
      this.setState({ loading: ft })
    }, 500)
  }

  componentDidMount () {
    this.props.clearAll()
    this.featureToggleOn()
  }
  componentDidUpdate () {
    if (this.state.loading === undefined) {
      this.featureToggleOn()
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.focusedElement === 'messageTextArea' && nextProps.message !== undefined) {
      this.messageTextArea.value = nextProps.message
      this.messageTextArea.selectionStart = nextProps.textAreaSelectionStart
      this.messageTextArea.selectionEnd = nextProps.textAreaSelectionEnd
      this.messageTextArea.focus()
    }

    if (nextProps.focusedElement === 'messageSubject' && nextProps.subject !== undefined) {
      this.messageSubject.value = nextProps.subject
      this.messageSubject.selectionStart = nextProps.subjectSelectionStart
      this.messageSubject.selectionEnd = nextProps.subjectSelectionEnd
      this.messageSubject.focus()
    }

    this.setState({
      ...this.state,
      validationErrors: nextProps.validationErrors
    })
  }

  _onFocus (currentTarget) {
    this.setState({
      ...this.state,
      focusedElement: currentTarget.id
    })
  }

  _onChange (currentTarget) {
    let validations = this.state.validationErrors.hwWhere(x => x.id !== currentTarget.id)
    this.setState({
      ...this.state,
      validationErrors: validations
    })
  }

  _getValidationMessage (id) {
    let error = this.state.validationErrors.hwFirstOrDefault(x => { return x.id === id })
    if (error) {
      return (
          <FieldValiationError message={error ? error.errorMessage : null} />
      )
    }
    return null
  }
  _getValidationMessages (id) {
    let errors = this.state.validationErrors.hwWhere(x => { return x.id === id })
    if (errors.length) {
      return errors.map(error => {
        return (
            <FieldValiationError message={error ? error.errorMessage : null} />)
      })
    }
    return null
  }

  _hasFormGroupValidationError = (id) => {
    return this.state.validationErrors.hwFirstOrDefault(x => {
      return x.id === id
    }) ? 'form-group has-error' : 'form-group'
  }

  _onUploadingAttachmentsStatusChange = (isUploading) => {
    this.setState({ isUploadingAttachments: isUploading })
  }

  onAttachmentValidationErrorsChange = (validationErrors) => {
    const fieldId = 'message-attachments'

    let filteredErrors = LoDash.reject(this.state.validationErrors, { 'id': fieldId })
    var mappedErrors = validationErrors.map(message => ({ id: fieldId, errorMessage: message }))

    this.setState({
      validationErrors: [...filteredErrors, ...mappedErrors]
    })
  }

  _onAttachmentsChange = (attachments) => {
    // only successfully uploaded attachments are updated.  Errors are reported seperately
    this.setState({ attachments: attachments })
  }
  _addLink = (url, descriptionElement) => {
    let textContent = this.messageTextArea.value
    let descriptionWithLink = textContent.substring(0, descriptionElement.selectionStart) +
          url +
          textContent.substring(descriptionElement.selectionEnd)

    descriptionElement.focus()

     this.messageTextArea.value = descriptionWithLink
  }

  render () {
    if (this.state.loading === undefined) { return null }
    return (
        <div className="messages">
            <Alert status={this.props.status} statusMessage={this.props.statusMessage} />

            <div className="form-horizontal">
                <div className={this._hasFormGroupValidationError('messagesStudentSelector')}>
                    <label htmlFor="messagesStudentSelector" className="col-sm-1 control-label">Students</label>
                    <div className="col-sm-3">
                        <StudentSelector id="messages-student-selector" ref={el => { this.messagesStudentSelector = el }}
                            onSelectedChanged={this.props.studentSelectorSelectionChanged} includePreAdmission students={this.props.to.students} groups={this.props.to.groups}
                        />
                        {this._getValidationMessage('messagesStudentSelector')}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="inputEmail3" className="col-sm-1 control-label">Send to</label>
                    <div className="col-sm-8">
                        <RecipientSelector onSelectedChanged={(e) => { this.props.selectedRecipientChanged(e, this.messageSubject, this.messageTextArea) }} />
                        <span className="message-preview-link-align">
                            <SelectionPreview
                                title="Recipient Preview"
                                previewLinkText="Preview Selection"
                                selectedRecipientType={this.props.selectedReceipientType}
                                selectedStudents={this.props.to.students}
                                selectedGroups={this.props.to.groups}
                            />
                        </span>
                    </div>
                </div>

                <div className={this._hasFormGroupValidationError('messageSubject')}>
                    <label htmlFor="inputEmail3" className="col-sm-1 control-label">Subject</label>
                    <div className="col-sm-6">
                        <input id="messageSubject" type="text" className="form-control" placeholder="Subject"
                            onFocus={e => this._onFocus(e.currentTarget)}
                            onChange={e => this._onChange(e.currentTarget)}
                            ref={el => { this.messageSubject = el }}
                        />
                        {this._getValidationMessage('messageSubject')}
                    </div>
                </div>

                <div className="form-group" style={{ display: this.state.loading ? 'block' : 'none' }}>
                    <label htmlFor="message-resources" className="col-sm-1 control-label">Attachments</label>
                    <div className="col-sm-6">
                        <div className="message-table attachment-table">
                            <Attachments
                                ref={(el) => { this.attachmentComponent = el }}
                                attachments={this.state.attachments}
                                onChange={this._onAttachmentsChange}
                                onValidationErrorsChange={this.onAttachmentValidationErrorsChange}
                                onUploadingStatusChange={this._onUploadingAttachmentsStatusChange}
                                consumerName = {Constants.MESSAGECOMPONENT}
                            />
                            {this._getValidationMessages('message-attachments')}
                        </div>
                    </div>
                </div>

                <div className={this._hasFormGroupValidationError('messageTextArea')}>
                    <label htmlFor="inputEmail3" className="col-sm-1 control-label">Message</label>
                    <div className="col-sm-6">
                        <div className="toolbar" style={{ display: this.state.loading ? 'block' : 'none' }}>
                            <LinkToolbarButton bindTo={this.messageTextArea} linkGenerated={(url) => this._addLink(url, this.messageTextArea)} />
                        </div>
                        <div className="message-table">
                            <div className="message-table-raw">
                                <div className="message-table-cell">
                                    <textarea id="messageTextArea" className="form-control message-textarea" rows="10"
                                        onFocus={e => this._onFocus(e.currentTarget)}
                                        onChange={e => this._onChange(e.currentTarget)}
                                        ref={el => { this.messageTextArea = el }}
                                    />
                                </div>
                                <div className="message-table-cell-tags">
                                    <div className="message-topbox">
                                        <label className="message-tag-label">Student merge tags</label><br />
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«Student Forename» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>Forename</span>
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«Student Surname» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>Surname</span>
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«he/she» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>he/she</span>
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«He/She» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>He/She</span>
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«his/her» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>his/her</span>
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«His/Her» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>His/Her</span>
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«him/her» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>him/her</span>
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«son/daughter» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>son/daughter</span>
                                    </div>
                                    <div className="message-bottombox">
                                        <label className="message-tag-label">Recipient merge tags</label><br />
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«Recipient Salutation» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>Salutation</span>
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«Recipient Title» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>Title</span>
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«Recipient Forename» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>Forename</span>
                                        <span className="message-tag" onClick={() => this.props.onTagClick('«Recipient Surname» ', this.state.focusedElement, this.messageTextArea, this.messageSubject)}>Surname</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this._getValidationMessage('messageTextArea')}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="messagesStudentSelector" className="col-sm-1 control-label" />
                    <div className="col-sm-6">
                        <div className="save-button-container">
                            <button className="btn btn-primary" disabled={this.props.status === MessagesStatus.SENDING || this.state.isUploadingAttachments}
                                onClick={() => this.props.sendMessage(this.messageTextArea, this.messageSubject, this.messagesStudentSelector, this.state.attachments, this._onAttachmentsChange)}
                            >{this.props.status === MessagesStatus.SENDING ? 'Sending' : 'Send'}
                              <SendPreview
                                selectedRecipientType={this.props.selectedReceipientType}
                                selectedStudents={this.props.to.students}
                                selectedGroups={this.props.to.groups}
                              />
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
  }
}

let getParentRecipients = async (store, allStudents) => {
  if (store.selectedReceipientType === ToRecipientType.PARENTS || store.selectedReceipientType === ToRecipientType.STUDENTANDPARENTS) {
    let allParents = Promise.all([parentService.all(), parentService.allPreAdmission()]).then(parentLists => {
      return LoDash.concat(parentLists[0], parentLists[1])
    })

    return allParents.then(parents => {
      let recipients = []
      let parentIds = []
      let filteredParents = parents.filter(parent => parent.Children.some(child => allStudents.indexOf(child) >= 0))
      filteredParents.forEach(function (parent) {
        if (!parentIds.includes(parent.Id)) {
          parentIds.push(parent.Id)
          parent.Children.forEach(child => {
            if (allStudents.hwFirstOrDefault(x => { return x === child })) {
              recipients.push({ recipientId: parent.Id, personType: 4, studentId: child })
            }
          })
        }
      })
      return recipients
    })
  } else {
    Promise.resolve([])
  }
}

function mapStateToProps (state) {
  return {
    to: state.to,

    selectedReceipientType: state.selectedReceipientType,

    focusedElement: state.focusedElement,
    message: state.message,
    textAreaSelectionStart: state.textAreaSelectionStart,
    textAreaSelectionEnd: state.textAreaSelectionEnd,
    subject: state.subject,
    subjectSelectionStart: state.subjectSelectionStart,
    subjectSelectionEnd: state.subjectSelectionEnd,

    isSending: state.isSending,
    validationErrors: state.validationErrors,
    status: state.status,
    statusMessage: state.statusMessage
  }
}

function buildMessagesSentResult (result) {
  let successMessage = 'Message sent successfully.'
  if (result) {
    let totalMessageCount = result.length
    let totalSuccessfulMessageCount = result.filter(x => x.Status === 0).length
    if (totalMessageCount === totalSuccessfulMessageCount) {
      successMessage = 'All messages sent successfully.'
    } else {
      successMessage = totalSuccessfulMessageCount + ' of ' + totalMessageCount + ' messages sent successfully.'
    }
  }
  return successMessage
}

function mapDispatchToProps (dispatch) {
  return {
    studentSelectorSelectionChanged: (newSelection) => {
      const action = {
        type: ActionTypes.STUDENT_SELECTION_CHANGED,
        validationErrors: [],
        to: {
          groups: newSelection.groups,
          students: newSelection.students
        }
      }
      dispatch(action)
    },

    selectedRecipientChanged: (selectedReceipientType, subject, textArea) => {
      const action = {
        type: ActionTypes.RECIPIENT_CHANGED,
        selectedReceipientType: selectedReceipientType,
        subject: subject ? subject.value : '',
        message: textArea ? textArea.value : ''
      }
      dispatch(action)
    },

    onTagClick: (tag, focusedElement, textArea, subject) => {
      const action = {
        type: ActionTypes.ON_TAG_CLICK,
        tag: tag,
        message: textArea.value,
        textAreaSelectionStart: textArea.selectionStart,
        textAreaSelectionEnd: textArea.selectionEnd,
        subject: subject.value,
        subjectSelectionStart: subject.selectionStart,
        subjectSelectionEnd: subject.selectionEnd,
        focusedElement: focusedElement,
        validationErrors: []
      }
      dispatch(action)
    },

    clearAll: () => {
      dispatch({
        type: ActionTypes.MESSAGE_CLEAR_ALL
      })
    },

    sendMessage: async (textArea, subjectText, messagesStudentSelector, attachments, onAttachmentsChange) => {
      dispatch({ type: ActionTypes.MESSAGE_SENDING })

      let store = MessagesStore.getState()
      let subject = subjectText.value
      let message = textArea.value

      let validationErrors = []
      if (store.to.groups.length <= 0 && store.to.students.length <= 0) validationErrors.push({ id: 'messagesStudentSelector', errorMessage: 'No recipients selected.' })
      if (subject.trim() === '') validationErrors.push({ id: 'messageSubject', errorMessage: 'Subject can not be empty.' })
      if (message.trim() === '') validationErrors.push({ id: 'messageTextArea', errorMessage: 'Message body can not be empty.' })
      if (validationErrors.length > 0) {
        dispatch({
          type: ActionTypes.MESSAGE_VALIDATION_ERROR,
          validationErrors: validationErrors,
          subject: subject,
          message: message,
          attachments: attachments
        })
        return
      }

      let members = await groupService.getStudentsInGroups(store.to.groups)
      // let preAdmissionMembers
      let allStudents = LoDash.union(members, store.to.students)
      if (allStudents.length <= 0) {
        validationErrors.push({ id: 'messagesStudentSelector', errorMessage: 'No recipients selected.' })
      }

      if (validationErrors.length > 0) {
        dispatch({
          type: ActionTypes.MESSAGE_VALIDATION_ERROR,
          validationErrors: validationErrors
        })
        return
      }

      let recipients = []
      if (store.selectedReceipientType === ToRecipientType.STUDENTS || store.selectedReceipientType === ToRecipientType.STUDENTANDPARENTS) {
        allStudents.forEach(function (studentId) {
          recipients.push({ recipientId: studentId, personType: 1, studentId: studentId })
        })
      }
      let schoolId = schoolService.getSchoolId()
      let parentRecipients = await getParentRecipients(store, allStudents)
      recipients = LoDash.union(recipients, parentRecipients)
      if (recipients.length > 0) {
        Promise.all([schoolId]).then(function (results) {
          schoolId = results[0]
          if (attachments.length > 0) {
            appInsightsService.trackEvent('Message With Attachment', { ActiveSchoolId: schoolId })
           } else {
            appInsightsService.trackEvent('Message Without Attachment', { ActiveSchoolId: schoolId })
           }
        })
        messageService.send(recipients, subject, message, attachments).then(result => {
          subjectText.value = ''
          textArea.value = ''
          messagesStudentSelector.instanceRef.clear()
          attachments = []
          let successMessage = buildMessagesSentResult(result)
          appInsightsService.logInfor(successMessage)
          appInsightsService.trackEvent('Message Sent', { ActiveSchoolId: schoolId })
          onAttachmentsChange(attachments)

          dispatch({
            type: ActionTypes.MESSAGE_SENT_SUCCESS,
            statusMessage: successMessage
          })
        })
          .catch(result => {
            let errorMessage = 'Message sending failed. ' + result.message
            appInsightsService.logError(errorMessage)

            dispatch({
              type: ActionTypes.MESSAGE_SENT_FAILURE,
              statusMessage: errorMessage
            })
          })
      } else {
        dispatch({
          type: ActionTypes.MESSAGE_VALIDATION_ERROR,
          validationErrors: [{ id: 'messagesStudentSelector', errorMessage: 'Please select recipients.' }]
        })
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages)
