import APIService from './APIService'
import Configuration from './ConfigurationService'
import sscache from 'session-storage-cache'

class TeacherService {
  constructor () {
    this.instance = APIService.create(Configuration.PeopleApiUrl)
  }

  all (useCache = true) {
    const address = 'v2/Teacher/All'
    const cacheKey = 'teacher-service/' + address
    const cacheTimeInMinutes = 10

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult && useCache) {
      return Promise.resolve(cachedResult)
    } else {
      return this.instance.get(address).then((response) => {
        sscache.set(cacheKey, response.data, cacheTimeInMinutes)
        return response.data
      })
    }
  }
}

export let teacherService = new TeacherService()
