import React, { Component } from 'react'
import './no-attachments.scss'

class NoAttachments extends Component {
    state = {
    }

    render () {
      return (
          <div id="no-attachments">
              <span className="no-attachments-title">
                  No attachments uploaded by student
              </span>
          </div>
      )
    }
}

export default NoAttachments
