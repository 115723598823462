import React, { Component } from 'react'
import LeftNavItem from './LeftNavItem'
import { routeConfiguration } from '../../../Services/RouteConfigurationService'
import { userContextService } from '../../../Services/UserContextService'
import { roleService } from '../../../Services/RoleService'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import * as Constants from '../../../Constants/constants'
import { teacherService } from '../../../Services/TeacherService'
import LoDash from 'lodash'

const portalRole = 1
const attendanceOfficerRole = 2
const parentAndAttendanceOfficerRole = 3
const parentProduct = 'parent'
const studentProduct = 'student'
const experimentalfeaturesProduct = 'experimentalfeatures'
const extracurricularProduct = 'extracurricular'
const standalonehomeworkProduct = 'standalonehomework'
const engagementProduct = 'sims-engagement'
const schoolAdminProduct = 'schooladmin'
const parentLiteProduct = 'parent-lite'

class LeftNav extends Component {
  state = {
    routes: [],
    redirectUrl: ''
  };

  componentDidMount () {
    let roleList = roleService.getProductList()
    let userContextServicePromise = userContextService.get()
    let routes = routeConfiguration.getMenuConfiguration()
    let teachers = teacherService.all()
    Promise.all([userContextServicePromise, roleList, teachers]).then(result => {
    this.setLeftNav(result[0], result[1], routes, result[2])
    })
  }

  setLeftNav (userContext, roleList, routes, teachers) {
    // checks if school has product licence
    let parentstudentEngagementEnabled = userContext.products.hwAny((p) => p.Name === parentProduct) || userContext.products.hwAny((p) => p.Name === studentProduct)
    let experimentalFeaturesEnabled = userContext.products.hwAny((p) => p.Name === experimentalfeaturesProduct)
    let activitiesEnabled = userContext.products.hwAny((p) => p.Name === extracurricularProduct)
    let standaloneHomeworkEnabled = userContext.products.hwAny((p) => p.Name === standalonehomeworkProduct)
    let parentLiteEnabled = userContext.products.hwAny((p) => p.Name === parentLiteProduct)

    // checks if user has particular product role
    let hasEngagementRole = parentstudentEngagementEnabled ? roleList.hwAny(p => p.ProductCode === engagementProduct && (p.Roles === portalRole || p.Roles === parentAndAttendanceOfficerRole)) : false
    let hasAttendanceRole = parentstudentEngagementEnabled ? roleList.hwAny(p => p.ProductCode === engagementProduct && (p.Roles === attendanceOfficerRole || p.Roles === parentAndAttendanceOfficerRole)) : false
    let hasStandaloneHomeworkRole = standaloneHomeworkEnabled ? roleList.hwAny(p => p.ProductCode === standalonehomeworkProduct && p.Roles === portalRole) : false
    let hasSchoolAdminRole = roleList.hwAny((p) => p.ProductCode === schoolAdminProduct && p.Roles === portalRole)
    let hasNoRelevanceLicences = !parentstudentEngagementEnabled && !experimentalFeaturesEnabled && !activitiesEnabled && !standaloneHomeworkEnabled && !parentLiteEnabled
    let isMultipleProduct = (parentstudentEngagementEnabled && hasSchoolAdminRole) || hasEngagementRole || activitiesEnabled || experimentalFeaturesEnabled

    let hasParentLiteAttendanceRole = parentLiteEnabled ? roleList.hwAny(p => p.ProductCode === engagementProduct && (p.Roles === attendanceOfficerRole || p.Roles === parentAndAttendanceOfficerRole)) : false
    let hasParentLiteSchoolAdminRole = parentLiteEnabled ? roleList.hwAny((p) => p.ProductCode === schoolAdminProduct && p.Roles === portalRole) : false
    let hasValidParentLiteLicenceAndRole = hasParentLiteAttendanceRole || hasParentLiteSchoolAdminRole
    let featureToggleEnabledForSickness = userContextService.checkFeatureToggle(Constants.SICKNESSFEATURETOGGLE)

    let isTeacher = !!LoDash.find(teachers, { 'Id': userContext.userId })

    if (hasNoRelevanceLicences) {
      this.setState({ routes: [] })
      routeConfiguration.redirectToRoute('noLicenses')
      return
    }

    if (!hasEngagementRole && !hasSchoolAdminRole && !hasStandaloneHomeworkRole && !hasValidParentLiteLicenceAndRole) {
      this.setState({ routes: [] })
      routeConfiguration.redirectToRoute('unauthorised')
      return
    }

    let currentPage = routeConfiguration.getCurrentPageInfo()

    if (currentPage.path === '/messages' && !parentstudentEngagementEnabled) {
      this.setState({ routes: [] })
      routeConfiguration.redirectToRoute('unauthorised')
    }

    routeConfiguration.subscribeToCurrentPathChange((currentPage) => {
      this.setState({ currentPage: currentPage })
    })

    let predicate = (routeItem) => {
      switch (routeItem.title) {
        case 'Messages':
          routeItem.showInMenu = (hasSchoolAdminRole && parentstudentEngagementEnabled) || hasEngagementRole
          return routeItem.showInMenu
        case 'Homework':
        case 'Dashboard':
        case 'All Homework':
        case 'Create':
        case 'Edit':
        case 'Submissions':
        case 'Help':
          routeItem.showInMenu = (hasEngagementRole || hasStandaloneHomeworkRole || (hasSchoolAdminRole && (parentstudentEngagementEnabled || standaloneHomeworkEnabled))) && isTeacher
          return routeItem.showInMenu
        case 'Activities':
          routeItem.showInMenu = activitiesEnabled
          return routeItem.showInMenu
        case 'Sickness':
            routeItem.showInMenu = featureToggleEnabledForSickness && (hasAttendanceRole || hasValidParentLiteLicenceAndRole || (hasSchoolAdminRole && parentstudentEngagementEnabled))
          return routeItem.showInMenu
        default :
          routeItem.showInMenu = false
          return routeItem.showInMenu
      }
    }

    let routesToBind = this._filterRoutes(routes, predicate, isMultipleProduct)
    let url = this._filterUrl(routesToBind, isMultipleProduct)

    this.setState({ routes: routesToBind, currentPage: currentPage, redirectUrl: url })
  }

  _filterRoutes (routes, predicate, isMultipleProduct) {
      let filteredRoutes = []

      for (let i = 0; i < routes.length; i++) {
        let route = routes[i]
        if (predicate(route)) {
          let newRoute = {
            ...route
          }
          if (newRoute.children && newRoute.children.length) {
            let newChildRoutes = []
            for (let o = 0; o < newRoute.children.length; o++) {
              let childRoute = newRoute.children[o]
              if (predicate(childRoute)) {
                newChildRoutes.push(childRoute)
              }
            }
            newRoute.children = newChildRoutes
            if (!isMultipleProduct) {
              Array.prototype.push.apply(filteredRoutes, newRoute.children)
              continue
            }
          }
          filteredRoutes.push(newRoute)
        }
      }
      return filteredRoutes
  }

  _filterUrl (routesToBind, isMultipleProduct) {
    let url = ''
    if (routesToBind !== null && routesToBind.length > 0) {
      switch (routesToBind[0].title) {
       case 'Homework':
         url = isMultipleProduct ? routesToBind[0].children[0].path : routesToBind[0].path
         break
       case 'Activities':
         break
       default :
         url = routesToBind[0].path
      }
    }
    return url
  }

  render () {
    return (

        <div id="left-panel">
            <div>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to={this.state.redirectUrl} />
                        </Route>
                    </Switch>
                </Router>
            </div>
            <sa-left-nav>
                <nav>
                    <ul className="left-nav-list">
                        {this.state.routes.map((route) => <LeftNavItem item={route} key={route.path} activePage={this.state.currentPage} />)}
                    </ul>
                </nav>
            </sa-left-nav>
        </div>

  )
  }
}

export default LeftNav
