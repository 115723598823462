import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Alerts.css'
import scrollToComponent from 'react-scroll-to-component'

class DismissableInformation extends Component {
  constructor (props) {
    super(props)
    this.myRef = React.createRef()
  }

    state ={
      isDismissed: false
    }

    componentDidUpdate () {
      if (this.props.scrollOnShow) {
        if (this.myRef.current) {
          scrollToComponent(this.myRef.current, { offset: 0, align: 'middle', duration: 500, ease: 'inCirc' })
        }
      }
    }

    _dismiss = () => {
      this.setState({ isDismissed: true })
    }

    render () {
      if (this.state.isDismissed || !this.props.message) {
        return null
      }

      return (
          <div ref={this.myRef} className="alert alert-info alert-transition" >
              <button type="button" className="close" aria-label="Close" onClick={() => { this._dismiss() }}>
                  <span aria-hidden="true">&times;</span>
              </button>
              {this.props.message}
          </div>
      )
    }
}

DismissableInformation.defaultProps = {
  message: PropTypes.string,
  scrollOnShow: PropTypes.bool
}

export default DismissableInformation
