import APIService from './APIService'
import Configuration from './ConfigurationService'

class SchoolService {
  constructor () {
    this.instance = APIService.create(Configuration.MessagesUrl)
  }

  getSchoolId () {
    return this.instance.get('v1/School/Get').then((response) => response.data.Id)
  }
}

export let schoolService = new SchoolService()
