import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './attachment-info.css'
import FileHelper from '../../../Services/Utilities/FileHelper'
import HomeworkIconService from '../../../Services/Homework/HomeworkIconService'

class AttachmentInfo extends Component {
  componentDidMount () {
    this._titleChange(this.props.attachment.name)
    this._descriptionChange(this.props.attachment.description)
  }

  state = {
    title: '',
    description: ''
  }

  _getIconForExtension (extension) {
    let service = new HomeworkIconService()
    return service.GetIcon(extension)
  }

  _titleChange (value) {
    this.setState({
      title: value
    })
    this.props.onTitleChanged(this.props.attachment.fileName, value)
  }

  _descriptionChange (value) {
    this.setState({
      description: value
    })
    this.props.onDescriptionChanged(this.props.attachment.fileName, value)
  }

  render () {
    return (
        <div className="attachment-info">
            <div className="attachement-icon-row">
                <div className="attachement-icon">
                    {(this.props.attachment.uploadProgress < 100) &&
                    <div className="attachement-icon-percentage">
                        <div className="attachement-icon-percentage-overlay" />
                        <div className="attachement-icon-percentage-text">
                            <span>{this.props.attachment.uploadProgress}</span>%
                        </div>
                    </div>
                    }
                    <img src={this._getIconForExtension(this.props.attachment.extension)} alt={this.props.attachment.fileName} height="70px" />
                </div>
                <div className="attachment-info-form">
                    <div className="attachment-close" onClick={() => this.props.onClose(this.props.attachment.fileName)}>
                        <i className="fa fa-times" aria-hidden="true" />
                    </div>
                    <span className="attachment-info-file-size">{FileHelper.getHumanReadableFileSize(this.props.attachment.fileSize)}</span>
                    <input value={this.state.title} onChange={(e) => this._titleChange(e.currentTarget.value)} className="form-control attachment-title" /><i className="fa fa-pencil attachement-editable" aria-hidden="true" />
                    <div>
                        <input value={this.state.description} maxLength="128" onChange={(e) => this._descriptionChange(e.currentTarget.value)} className="form-control attachment-description" placeholder="Description ..." /><i className="fa fa-pencil attachement-editable" aria-hidden="true" />
                    </div>

                </div>
            </div>
            {/* this is the attachment
        {JSON.stringify(this.props.file, null, 2)  } */}
        </div>
    )
  }
}

AttachmentInfo.defaultProps = {
  attachment: PropTypes.object.isRequired
}

AttachmentInfo.props = {
  onTitleChanged: PropTypes.func,
  onDescriptionChanged: PropTypes.func,
  onClose: PropTypes.func
}

export default AttachmentInfo
