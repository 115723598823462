import APIService from './APIService'
import Configuration from './ConfigurationService'
import Promise from 'bluebird'
import sscache from 'session-storage-cache'

function _imageEncode (arrayBuffer) {
  let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), function (p, c) { return p + String.fromCharCode(c) }, ''))
  let mimetype = 'image/png'
  return 'data:' + mimetype + ';base64,' + b64encoded
}

class ImageService {
  constructor () {
    this.instance = APIService.create(Configuration.ImageApiUrl)
  }

  getTeacher (schoolId, personId, photoId, size) {
    let cacheKey = `image-service/v2/GetTeacher/${schoolId}/${personId}/${photoId}/${size}`
    const cacheTimeInMinutes = 20
    const NoImage = 'no-image'

    let cachedResult = sscache.get(cacheKey)

    if (cachedResult) {
      if (cachedResult === NoImage) {
        return Promise.resolve(null)
      }
      return Promise.resolve(cachedResult)
    } else {
      return new Promise((resolve, reject) => {
        return this.instance.get('v1/TeacherPhoto/' + schoolId + '/' + personId + '/' + photoId + '/' + size, {
          responseType: 'arraybuffer'
        })
          .then(blob => {
            let image = _imageEncode(blob.data)
            sscache.set(cacheKey, image, cacheTimeInMinutes)
            resolve(image)
          }, () => {
            sscache.set(cacheKey, NoImage, cacheTimeInMinutes)
            reject(new Error('unable to resolve teacher photo'))
          })
      })
    }
  }

  getStudent (schoolId, personId, photoId, size) {
    let cacheKey = `image-service/v2/GetStudent/${schoolId}/${personId}/${photoId}/${size}`
    const cacheTimeInMinutes = 50
    const NoImage = 'no-image'

    let cachedResult = sscache.get(cacheKey)
    if (cachedResult) {
      if (cachedResult === NoImage) {
        return Promise.resolve(null)
      }
      return Promise.resolve(cachedResult)
    } else {
      return new Promise((resolve, reject) => {
        return this.instance.get('v1/StudentPhoto/' + schoolId + '/' + personId + '/' + photoId + '/' + size, {
          responseType: 'arraybuffer'
        }).then(blob => {
          let image = _imageEncode(blob.data)
          sscache.set(cacheKey, image, cacheTimeInMinutes)
          resolve(image)
        }, () => {
          sscache.set(cacheKey, NoImage, cacheTimeInMinutes)
          reject(new Error('unable to resolve student photo'))
        })
      })
    }
  }
}

export let imageService = new ImageService()
