import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './dropster.css'
import onClickOutside from 'react-onclickoutside'

class Dropster extends Component {
    state = {
      isDropDownOpen: false,
      currentSortOrder: null,
      selectedOption: null
    }

    handleClickOutside = () => {
      this.setState({ isDropDownOpen: false })
    };

    _toggleOpen = () => {
      this.setState({ isDropDownOpen: !this.state.isDropDownOpen })
    };

    _onChange= (option) => {
      this.setState({
        selectedOption: option
      })
      this.props.onChange(option.code)
      this._toggleOpen()
    }

    componentWillReceiveProps (newProps) {
      if (!this.state.selectedOption) {
        this.setState({
          selectedOption: newProps.selectedOption ? newProps.selectedOption : newProps.options.hwFirstOrDefault()
        })
      }
    }

    render () {
      if (this.state.selectedOption) {
        return (
            <div className="dropster">
                <div className="dropster-header" onClick={this._toggleOpen}>
                    <span className="order-by">{this.props.narativeText}:</span>
                    <span className="title">{this.state.selectedOption.title}</span>
                    <i className="fa fa-chevron-down dropster-icon" aria-hidden="true" />
                </div>
                {this.state.isDropDownOpen &&
                <div className="dropster-drop-down">
                    {this.props.options.map(x =>
                    (<div key={x.code} className={(x.isChild) ? 'dropster-drop-down-item dropster-drop-down-item-child' : 'dropster-drop-down-item'} onClick={() => { this._onChange(x) }}>
                        <span className="title">
                            {x.title}
                        </span>
                        <span className="help-text">
                            {x.helpText}
                        </span>
                    </div>)
                  )}
                </div>
              }
            </div>
      )
    }
      return null
    }
}

Dropster.propTypes = {
  narativeText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.object
}

export default onClickOutside(Dropster)
