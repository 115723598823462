import React, { Component } from 'react'
import HomeworkByDimensionWidget from './HomeworkByDimensionWidget'
import HandInStatusByDimension from './HandInStatusByDimensionWidget'
import StudentView from './StudentViewWidget'
import './homework-dashboard.scss'

class HomeworkDashboard extends Component {
    render () {
      return (
          <div>
              <div className="dashboard-row-2">
                  <div className="dashboard-row-item">
                      <HandInStatusByDimension fullReportClick={() => {}} showFullReport />
                  </div>
                  <div className="dashboard-row-item">
                      <HomeworkByDimensionWidget fullReportClick={() => {}} showFullReport />
                  </div>
              </div>
              <div className="dashboard-row-1">
                  <div className="dashboard-row-item">
                      <StudentView title="Students"/>
                  </div>
              </div>
          </div>
      )
    }
}

export default HomeworkDashboard
