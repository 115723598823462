import React, { Component } from 'react'
import './fieldValidationError.css'
import PropTypes from 'prop-types'

class FieldValidationError extends Component {
  render () {
    return (
        <span className="field-validation-message">{this.props.message}</span>
    )
  }
}

FieldValidationError.defaultProps = {
  message: PropTypes.string
}

export default FieldValidationError
