import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../homework-widget.scss'
import Dropster from '../../../../Components/Common/Dropster'
import { HomeworkDashboardService } from '../../../../Services/Homework/Bundle'
import { HomeworkTotalsDimensions, DashboardTimeFrame } from '../../../../Services/Homework/Enums'
import ExportButton from '../ExportButton'

import { DropsterDimensions, DropsterTimeframes, Chart, Loading, Error, ExpandButton, ChartHelp } from '../widgetComponents'

class HomeworkByDimension extends Component {
    homeworkDashboardService = new HomeworkDashboardService()

    state = {
        options: {
            credits: {
              enabled: false
            },
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                 text: null
            },
            tooltip: {
                headerFormat: '<b>{point.key}</b> <br/>',
                pointFormat: '<b>{point.percentage:.1f}%</b> <br/> <b>{point.y}</b> in total'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: '',
                colorByPoint: true,
                data: null
            }]

        },
        isLoading: true,
        showChart: false,
        hasErrored: false,
        isExpanded: false
    }

currentTimeFrame = DashboardTimeFrame.THIS_TERM
currentDimension = HomeworkTotalsDimensions.SUBJECT

  componentDidMount () {
    this._getData(this.currentTimeFrame, this.currentDimension)
  }
  componentWillUnmount () {
    this.homeworkDashboardService.cancelAll()
  }

  _getData (timeframe, dimension) {
      this.setState({
        isLoading: true,
        showChart: false,
        hasErrored: false
      })

    this.homeworkDashboardService.getHomeworkByDimension(timeframe, dimension).then(data => {
        let options = this.state.options
        let series = []
        for (let i = 0; i < data.length; i++) {
            (function () {
                let thing = i
                let item = data[thing]
                series.push({
                    name: item.Name || 'Unknown',
                    y: item.Count
                })
            })()
        }
        options.series[0].data = series

        this.setState({ options: options, showChart: true, isLoading: false, hasErrored: false, chartData: data })
    }, () => {
      this.setState({ showChart: false, isLoading: false, hasErrored: true })
    })
  }

  _dimensionChange = (dimension) => {
    this.currentDimension = dimension
    this._getData(this.currentTimeFrame, this.currentDimension)
  }

  _timeframeChange = (timeframe) => {
    this.currentTimeFrame = timeframe
    this._getData(this.currentTimeFrame, this.currentDimension)
  }

  _toggleExpand = () => {
    let newIsExpanded = !this.state.isExpanded

    let newOptions = this.state.options
    newOptions.chart.height = newIsExpanded ? '800px' : '400px;'
    this.setState({
        isExpanded: newIsExpanded,
        options: newOptions
    })
    this._getData(this.currentTimeFrame, this.currentDimension)
}

_helpText () {
    return (
        <div>
            Analyses the total amount of homework given to students where the "available from" date falls within the selected timeframe.
        </div>
    )
}

  render () {
      return (
          <div className="widget-container">
              <div className={this.state.isExpanded ? 'widget expanded' : 'widget'}>
                  <div className="widget-header">
                      <div className="widget-drops">
                          <div className="widget-header-cell widget-title">
                              Total Homework
                              <ChartHelp helpText={this._helpText} />
                              <ExportButton filename="TotalHomework" data={this.state.chartData} timeframe={this.currentTimeFrame} dimension={this.currentDimension} disabled={(this.state.isLoading || this.state.hasErrored)} />
                          </div>
                          <div className="widget-header-cell widget-drop">
                              <Dropster options={DropsterDimensions} narativeText="Slice by" onChange={this._dimensionChange} />
                          </div>
                          <div className="widget-header-cell widget-drop">
                              <Dropster options={DropsterTimeframes} narativeText="Timeframe" onChange={this._timeframeChange} />
                          </div>
                      </div>
                  </div>
                  <div className="widget-content">
                      <Chart options={this.state.options} showChart={this.state.showChart} />
                      <Loading isLoading={this.state.isLoading} />
                      <Error hasErrored={this.state.hasErrored}/>
                      <ExpandButton isExpanded={this.state.isExpanded} toggleExpand={() => { this._toggleExpand() }} />
                  </div>
              </div>
          </div>
      )
    }
}

HomeworkByDimension.propTypes = {
  fullReportClick: PropTypes.func.isRequired,
  showFullReport: PropTypes.bool.isRequired
}

export default HomeworkByDimension
