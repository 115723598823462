const UrlHelper = {
  getParams (url) {
    let regex = /[#?&]([^=#]+)=([^&#]*)/g; let params = {}; let match
    /* eslint-disable */
      while (match = regex.exec(url)) {
        params[match[1]] = match[2];
      }
    /* eslint-enable */

    return params
  }
}

export default UrlHelper
