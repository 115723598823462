import { homeworkService } from './Homework/HomeworkService'
import { messageService } from './MessageService'
import * as Constants from '../Constants/constants'
class AttachmentsService {
  upload (file, config, consumerName) {
    let formData = new FormData()
    formData.append(file.type, file)
    let consumerService = this.getConsumerComponent(consumerName)
    return consumerService.upload(formData, config)
  }

  getConsumerComponent = (consumerName) => {
    if (consumerName === Constants.HOMEWORKCOMPONENT) {
      return homeworkService
    } else {
      return messageService
    }
  }
  checkNoOfFiles (existingAttachment, newAttachment, consumerName) {
    if (consumerName === Constants.MESSAGECOMPONENT && (existingAttachment.length + newAttachment.length) > 3) {
      return true
    }
    return false
  }
}

export let attachmentsService = new AttachmentsService()
