import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import Promise from 'bluebird'

Promise.config({
  cancellation: true
})

ReactDOM.render(<App />, document.getElementById('root'))
registerServiceWorker()
