/* eslint-disable react/no-multi-comp */
import React from 'react'
import spinner from './spinner.svg'
import { HomeworkTotalsDimensions, DashboardTimeFrame } from '../../../Services/Homework/Enums'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const DropsterDimensionsGetHelpText = (rep) => {
   return `In groups of ${rep}`
}

const DropsterDimensions = [{
   code: HomeworkTotalsDimensions.SUBJECT,
   title: 'Subject',
   helpText: DropsterDimensionsGetHelpText('Subjects')
 },
 {
   code: HomeworkTotalsDimensions.SUBJECT_BY_YEAR,
   title: 'Year/Subject',
   helpText: DropsterDimensionsGetHelpText('Year/Subject')
 },
 {
   code: HomeworkTotalsDimensions.TEACHER,
   title: 'Teachers',
   helpText: DropsterDimensionsGetHelpText('Teachers')
 },
 {
   code: HomeworkTotalsDimensions.YEAR,
   title: 'Year',
   helpText: DropsterDimensionsGetHelpText('Years')
 },
 {
   code: HomeworkTotalsDimensions.REG_GROUP,
   title: 'Registration Group',
   helpText: DropsterDimensionsGetHelpText('Registration Groups')
 },
 {
   code: HomeworkTotalsDimensions.GENDER,
   title: 'Gender',
   helpText: DropsterDimensionsGetHelpText('Genders')
 },
 {
   code: HomeworkTotalsDimensions.HOUSE,
   title: 'House',
   helpText: DropsterDimensionsGetHelpText('Houses')
 }
]

const DropsterTimeframes = [
   {
     code: DashboardTimeFrame.THIS_TERM,
     title: 'This Term',
     helpText: 'The current term'
   },
   {
     code: DashboardTimeFrame.THIS_NC_YEAR,
     title: 'This Year',
     helpText: 'This academic year'
   }
 ]

const Chart = (props) => {
return (<>
    {props.showChart &&
    <HighchartsReact
        highcharts={Highcharts}
        options={props.options}
    />}
</>)
}

const Loading = (props) => {
   return (<>
       {props.isLoading &&
       <img src={spinner} className="widget-spinner" />
   }
   </>)
}

const Error = (props) => {
   return (<>
       {props.hasErrored &&
       <>
           <div className="widget-error">
               Oops, something has gone wrong
               <br/>
               <i className="icon icon-not-handed-in" />
           </div>
       </>
      }
   </>)
}

const ExpandButton = (props) => {
    return (
        <a className="expand-button" href="#" onClick={(e) => { e.preventDefault(); props.toggleExpand() }}>
            {props.isExpanded &&
            <>
                <i className="fa fa-compress" aria-hidden="true" />&nbsp; Collapse
            </>
                                }
            {!props.isExpanded &&
            <>
                <i className="fa fa-expand" aria-hidden="true" />&nbsp; Expand
            </>
                                }
        </a>
    )
}

const ChartHelp = (props) => {
    return (<div className="chart-help">
        <span className="chart-help-indicator">
            <i className="fa fa-question-circle-o" aria-hidden="true" />
        </span>
        <div className="chart-help-text"><props.helpText /></div>
    </div>)
}

export {
   DropsterDimensions,
   DropsterTimeframes,
   Chart,
   Loading,
   Error,
   ExpandButton,
   ChartHelp
}
