import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './sort-order-selector.css'
import onClickOutside from 'react-onclickoutside'

class SortOrderSelector extends Component {
    sortOrders = this.props.options;

    state = {
      isDropDownOpen: false,
      currentSortOrder: this.getDefaultSortOrder()
    }

    handleClickOutside = () => {
      this.setState({ isDropDownOpen: false })
    };

    _toggleOpen = () => {
      this.setState({ isDropDownOpen: !this.state.isDropDownOpen })
    };

    _onChange= (code) => {
      let newSortOrder = this.sortOrders.hwFirstOrDefault((x) => { return x.code === code })
      this.setState({
        isDropDownOpen: false,
        currentSortOrder: newSortOrder
      })
      this.props.onChange(code)
    }

    getDefaultSortOrder () {
      let sortOrder = this.props.defaultSortOrder !== undefined ? this.props.defaultSortOrder : 'status'
      return this.sortOrders.hwFirstOrDefault((x) => { return x.code === sortOrder })
    }

    render () {
      return (
          <div className="sort-order-selector">
              <div className="sort-order-selector-header" onClick={this._toggleOpen}>
                  <span className="order-by">Order by:</span>
                  <span className="title">{this.state.currentSortOrder.title}</span>
                  <span className="help-text">{this.state.currentSortOrder.helpText}</span>
                  <i className="fa fa-chevron-down sort-order-selector-icon" aria-hidden="true" />
              </div>
              {this.state.isDropDownOpen &&
              <div className="sort-order-selector-drop-down">
                  {this.sortOrders.map(x =>
                    (<div key={x.code} className="sort-order-selector-drop-down-item" onClick={() => { this._onChange(x.code) }}>
                        <span className="title">
                            {x.title}
                        </span>
                        <span className="help-text">
                            {x.helpText}
                        </span>
                    </div>)
                  )}
              </div>
              }
          </div>
      )
    }
}

SortOrderSelector.props = {
  onChange: PropTypes.func
}

export default onClickOutside(SortOrderSelector)
