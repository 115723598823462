/* eslint-disable react/no-multi-comp */
import 'es6-promise/lib/es6-promise.auto.js'
import './Components/Common/Polyfill/startsWith.js'
import './Components/Common/Polyfill/endsWith.js'
import './Components/Common/Polyfill/assign.js'
import React from 'react'
import Header from './Components/Common/Header'
import Contents from './Components/Common/Contents'
import './App.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { SilentTokenRenewalAuthenticationCallback, AuthenticationCallback, AuthenticationRouteCheck, AuthenticationRolesAndClaimsCheck, SilentTokenRenewalAuthenticationFailedCallback } from './Components/Auth/bundle'
import '@babel/polyfill'
import Footer from './Components/Common/Footer'

const NormalSite = () => {
  return (
      <div>
          <Header />
          <Contents />
          <AuthenticationRouteCheck />
          <AuthenticationRolesAndClaimsCheck />
          <Footer />
      </div>
  )
}

const App = () => (
    <div className="App">
        <Router>
            <Switch>
                <Route path="/code=*">
                    <AuthenticationCallback />
                </Route>
                <Route exact path="/silent-token-renew">
                    {/* Running in a iFrame */}
                    <SilentTokenRenewalAuthenticationCallback />
                </Route>
                <Route path="/error=login_required*">
                    {/* Running in a iFrame */}
                    <SilentTokenRenewalAuthenticationFailedCallback />
                </Route>
                <NormalSite />
            </Switch>
        </Router>
    </div>
)

export default App
