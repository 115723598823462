/* eslint-disable react/no-multi-comp */
import LoDash from 'lodash'
import React, { Component } from 'react'
import { groupService } from '../../../../Services/GroupService'
import { studentService } from '../../../../Services/StudentService'
import { parentService } from '../../../../Services/ParentService'
import { schoolAdminUserInviteService } from '../../../../Services/SchoolAdminUserInviteService'
import ReactDataGrid from 'react-data-grid'
import './parentPreview.css'
import PropTypes from 'prop-types'

class FornameAndSurnameFormatter extends React.Component {
    static propTypes = {
      value: PropTypes.object.isRequired
    };

    render () {
      const forename = this.props.value.forename
      const surname = this.props.value.surname
      const isRegistered = this.props.value.isRegistered
      return (
          <div>
              {forename} {surname}

              {isRegistered &&
              <div className="registered">Registered</div>
              }
              {!isRegistered &&
              <div className="not-registered">Not Registered</div>
              }
          </div>)
    }
}

class ParentPreview extends Component {
    state = {
      students: [],
      parents: [],
      isLoading: false
    }

    _columns = this._columns = [
      {
        key: 'forenameAndSurnameAndIsRegistered',
        name: 'Name',
        formatter: FornameAndSurnameFormatter
      },
      {
        key: 'studentNames',
        name: 'Student Names'
      }
    ];

    componentDidMount () {
      this.setState({
        isLoading: true
      })

      groupService.getPreAdmissionGroups().then(allPreAdmissionGroups => {
        let selectedPreAdmissionsGroups = this.props.selectedGroups.hwWhere(x => { return allPreAdmissionGroups.hwAny(y => y.id === x) })

        let studentsInSelectedGroups = Promise.all([
          groupService.getStudentsInGroups(this.props.selectedGroups),
          groupService.getPreAdmissionStudentsInGroups(selectedPreAdmissionsGroups)])
          .then(result => {
            let studentsInGroups = result[0]
            let preAdmissionStudentsInGroups = result[1]

            let allStudentsInGroups = LoDash.union(studentsInGroups, preAdmissionStudentsInGroups)
            return allStudentsInGroups
          })

        let allStudents = Promise.all([
          studentService.all(),
          studentService.allPreAdmissions()
        ]).then(result => {
          let students = result[0]
          let preAdmissionStudents = result[1]

          let allStudents = LoDash.union(students, preAdmissionStudents)

          return allStudents
        })

        let registeredParents = Promise.all([
          parentService.all(),
          parentService.allPreAdmission(),
          schoolAdminUserInviteService.allRegisteredParents(),
          schoolAdminUserInviteService.allRegisteredPreAdmissionParents()
        ]).then(result => {
          let parents = result[0]
          let preAdmissionParents = result[1]
          let registeredParents = LoDash.unionBy(result[2], result[3], "ExternalUserId")

          let allParents = LoDash.unionBy(parents, preAdmissionParents, "Id")

          for (let i = 0; i < allParents.length; i++) {
            let parent = allParents[i]
            parent.isRegistered = registeredParents.hwAny(x => x.ExternalUserId === parent.Id)
          }

          return allParents
        })

        Promise.all([
          studentsInSelectedGroups,
          allStudents,
          registeredParents
        ]).then(result => {
          let studentsInSelectedGroups = result[0]
          let allStudents = result[1]
          let registeredParents = result[2]

          let allSelectedStudents = LoDash.union(studentsInSelectedGroups, this.props.selectedStudents)

          let students = allStudents.hwJoin(allSelectedStudents, (a, b) => a.Id === b, (a, b) => { return { ...a } })

          let getStudentNameForParent = (children, students) => {
            let childStudents = students.hwWhere(x => children.hwContains(x.Id))
            let childStudentNames = childStudents.map(child => child.DisplayForename + ' ' + child.DisplaySurname)
            return LoDash.join(childStudentNames, ', ')
          }

          let parentsWithSelectedChildren = registeredParents.hwWhere(x => x.Children.hwAny(y => allSelectedStudents.hwContains(y)))
          let projectedParents = parentsWithSelectedChildren.hwSelect(parent => {
            return {
              forename: parent.DisplayForename,
              surname: parent.DisplaySurname,
              forenameAndSurnameAndIsRegistered: {
                forename: parent.DisplayForename,
                surname: parent.DisplaySurname,
                isRegistered: parent.isRegistered
              },
              studentNames: getStudentNameForParent(parent.Children, students)
            }
          })

          projectedParents = LoDash.orderBy(projectedParents, ['surname', 'forename'])

          this.setState({
            parents: projectedParents,
            isLoading: false
          })
        })
      })
    }

    _rowGetter = (i) => {
      return this.state.parents[i]
    };

    render () {
      return (
          <div className="parent-preview">

              {this.state.isLoading &&
              <div>
                  <div className="parent-preview-loady" />
                  <span className="parent-preview-loady-text">Loading</span>
              </div>
              }

              Parents ({this.state.parents.length})
              <ReactDataGrid
                  columns={this._columns}
                  rowGetter={this._rowGetter}
                  rowsCount={this.state.parents.length}
                  headerRowHeight={30}
                  minHeight={200}
                  rowHeight={26}
              />

          </div>
      )
    }
}

export default ParentPreview
