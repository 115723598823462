import { createStore, compose, applyMiddleware } from 'redux'
import logger from 'redux-logger'

let initialState = {
  filters: null
}

const enhancer = compose(
  applyMiddleware(logger)
)

function reducer (state, action) {
  switch (action.type) {
  case 'SET_FILTERS':
    return {
      ...state,
      filters: action.filters
    }
  default:
    return state
  }
}

let store = createStore(reducer, initialState, enhancer)

export default store
