import React from 'react'
import PropTypes from 'prop-types'
import { InputGroup, FormControl, Button, Glyphicon } from 'react-bootstrap'
import './customDataPickerInput.css'

class CustomDatePickerInput extends React.Component {
  render () {
    return (
        <InputGroup>
            <FormControl
                onClick={this.props.onClick}
              // onFocus={this.props.onClick}
                value={this.props.value}
                onChange={this.props.onChange}
                disabled={this.props.disabled}
                readOnly
                className="custom-date-picker-input"
            />
            <InputGroup.Button>
                <Button onClick={this.props.onClick} disabled={this.props.disabled}><Glyphicon glyph="calendar" /></Button>
            </InputGroup.Button>
        </InputGroup>
    )
  }
}

CustomDatePickerInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}

export default CustomDatePickerInput
