import React, { Component } from 'react'
import './image-viewer.scss'

class ImageViewer extends Component {
    state = {
    }

    render () {
      return (
          <div id="image-viewer">
              <img src={this.props.file.UrlWithSasToken} alt="Image uploaded by student"/>

              <div>
                  <a className="btn btn-default file-downloader-anchor" href={this.props.file.UrlWithSasToken} download>Download image</a>
              </div>
          </div>
      )
    }
}

export default ImageViewer
