import React, { Component } from 'react'
import LoDash from 'lodash'
import { ToRecipientType } from '../../../../Stores/MessagesStore'
import { parentService } from '../../../../Services/ParentService'
import { studentService } from '../../../../Services/StudentService'
import { groupService } from '../../../../Services/GroupService'

class SendPreview extends Component {
  state = {
    isLoading: false,
    parentsCount: 0,
    studentsCount: 0
  }

  previous = {
    selectedStudents: [],
    selectedGroups: []
  }

  componentDidMount() {
    // Preload resources so that the preview is snappy
    parentService.all(true)
    parentService.allPreAdmission(true)
    studentService.all(true)
    studentService.allPreAdmissions(true)
  }

  componentDidUpdate() {
    if (
      !LoDash.isEqual(this.previous.selectedStudents, this.props.selectedStudents) ||
      !LoDash.isEqual(this.previous.selectedGroups, this.props.selectedGroups)
    ) {
      this.previous.selectedStudents = this.props.selectedStudents.slice()
      this.previous.selectedGroups = this.props.selectedGroups.slice()
      this.loadData();
    }
  }

  loadData() {
    this.setState({
      isLoading: true
    })

    groupService.getPreAdmissionGroups().then(allPreAdmissionGroups => {
      let selectedPreAdmissionsGroups = this.props.selectedGroups.hwWhere(x => { return allPreAdmissionGroups.hwAny(y => y.id === x) })

      Promise.all([
        groupService.getStudentsInGroups(this.props.selectedGroups),
        groupService.getPreAdmissionStudentsInGroups(selectedPreAdmissionsGroups),
        studentService.all(),
        studentService.allPreAdmissions(),
        parentService.all(),
        parentService.allPreAdmission()
      ]).then(result => {
        let studentsInSelectedGroups = LoDash.union(result[0], result[1])
        let allStudents = LoDash.union(result[2], result[3])
        let allParents = LoDash.unionBy(result[4], result[5], "Id")

        let allSelectedStudents = LoDash.union(studentsInSelectedGroups, this.props.selectedStudents)

        let students = allStudents.hwJoin(allSelectedStudents, (a, b) => a.Id === b, (a, b) => {
          return {...a}
        })

        let parentsWithSelectedChildren = allParents.hwWhere(x => x.Children.hwAny(y => allSelectedStudents.hwContains(y)))

        this.setState({
          studentsCount: students.length,
          parentsCount: parentsWithSelectedChildren.length,
          isLoading: false
        })
      })
    })
  }

  render() {
    let studentsCount = this.state.isLoading ? "..." : this.state.studentsCount
    let parentsCount = this.state.isLoading ? "..." : this.state.parentsCount

    return (
        <span>&nbsp;to&nbsp;
          {this.props.selectedRecipientType !== ToRecipientType.PARENTS ? this.count(studentsCount, "Student") : ""}
          {this.props.selectedRecipientType === ToRecipientType.STUDENTANDPARENTS ? " and " : ""}
          {this.props.selectedRecipientType !== ToRecipientType.STUDENTS ? this.count(parentsCount, "Parent") : ""}
        </span>
    )
  }

  count(value, type) {
    return value.toLocaleString() + " " + type + (value === 1 ? "" : "s")
  }
}

export default SendPreview
