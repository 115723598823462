import React, { Component } from 'react'
import './grade-and-comment.scss'
import PropTypes from 'prop-types'

class GradeAndComment extends Component {
    state = {
    }

    render () {
      return (
          <div id="grade-and-comment">
              {this.props.grade &&
              <div>
                  <span className="grade-label">
                      Grade:
                  </span>
                  <span className="grade">
                      {this.props.grade}
                  </span>
              </div>
              }
              {this.props.comment &&
              <div>
                  <span className="comment-label">
                      Comment:
                  </span>
                  <span className="comment">
                      {this.props.comment}
                  </span>
              </div>
              }
          </div>
      )
    }
}

export default GradeAndComment

GradeAndComment.props = {
  grade: PropTypes.string,
  comment: PropTypes.string
}
