import APIService from './APIService'
import Configuration from './ConfigurationService'
import sscache from 'session-storage-cache'
import LoDash from 'lodash'
import Hashes from 'jshashes'

class GroupService {
  constructor () {
    this.instance = APIService.create(Configuration.GroupsAPIUrl)
  }

    cacheKey = 'groups-service';

    getYears (useCache = true) {
      const path = 'v1/Group/Year'

      let cacheKey = `${this.cacheKey}${path}`
      const cacheTimeInMinutes = 20
      let cachedResult = sscache.get(cacheKey)
      if (cachedResult && useCache) {
        return Promise.resolve(cachedResult)
      } else {
        return this.instance.get(path).then((response) => {
          let result = response.data.hwSelect(x => {
            return {
              id: x.Id,
              name: x.Name
            }
          })
          sscache.set(cacheKey, result, cacheTimeInMinutes)
          return result
        })
      }
    }

    getHouses (useCache = true) {
      const path = 'v1/Group/house'

      let cacheKey = `${this.cacheKey}${path}`
      const cacheTimeInMinutes = 20
      let cachedResult = sscache.get(cacheKey)
      if (cachedResult && useCache) {
        return Promise.resolve(cachedResult)
      } else {
        return this.instance.get(path).then((response) => {
          let result = response.data.hwSelect(x => {
            return {
              id: x.Id,
              name: x.Name
            }
          })
          sscache.set(cacheKey, result, cacheTimeInMinutes)
          return result
        })
      }
    }

    getUserDefinedGroups (useCache = true) {
      const path = 'v1/Group/UserDefined'

      let cacheKey = `${this.cacheKey}${path}`
      const cacheTimeInMinutes = 20
      let cachedResult = sscache.get(cacheKey)
      if (cachedResult && useCache) {
        return Promise.resolve(cachedResult)
      } else {
        return this.instance.get(path).then((response) => {
          let result = response.data.hwSelect(x => {
            return {
              id: x.Id,
              name: x.Name
            }
          })
          sscache.set(cacheKey, result, cacheTimeInMinutes)
          return result
        })
      }
    }

    getSubjectClasses (useCache = true) {
      const path = 'v1/Group/SubjectClass'

      let cacheKey = `${this.cacheKey}${path}`
      const cacheTimeInMinutes = 20
      let cachedResult = sscache.get(cacheKey)
      if (cachedResult && useCache) {
        return Promise.resolve(cachedResult)
      } else {
        return this.instance.get(path).then((response) => {
          let result = response.data.hwSelect(x => {
            return {
              id: x.Id,
              name: x.Name
            }
          })
          sscache.set(cacheKey, result, cacheTimeInMinutes)
          return result
        })
      }
    }

    getRegistrationGroups (useCache = true) {
      const path = 'v1/Group/Registration'

      let cacheKey = `${this.cacheKey}${path}`
      const cacheTimeInMinutes = 20
      let cachedResult = sscache.get(cacheKey)
      if (cachedResult && useCache) {
        return Promise.resolve(cachedResult)
      } else {
        return this.instance.get(path).then((response) => {
          let result = response.data.hwSelect(x => {
            return {
              id: x.Id,
              name: x.Name
            }
          })
          sscache.set(cacheKey, result, cacheTimeInMinutes)
          return result
        })
      }
    }

    getPreAdmissionGroups (useCache = true) {
      const path = 'v1/Group/PreAdmission'

      let cacheKey = `${this.cacheKey}${path}`
      const cacheTimeInMinutes = 20
      let cachedResult = sscache.get(cacheKey)
      if (cachedResult && useCache) {
        return Promise.resolve(cachedResult)
      } else {
        return this.instance.get(path).then((response) => {
          let result = response.data.hwSelect(x => {
            return {
              id: x.Id,
              name: x.Name
            }
          })
          sscache.set(cacheKey, result, cacheTimeInMinutes)
          return result
        })
      }
    }

    async getPreAdmissionStudentsInGroups (groups) {
      var allRequests = []
      for (let i = 0; i < groups.length; i++) {
        let groupId = groups[i]

        let path = `v1/PreAdmission/Members/${groupId}`
        let promise = this.instance.get(path).then((response) => {
          return response.data
        })

        allRequests.push(promise)
      }

      return Promise.all(allRequests).then((results) => {
        return LoDash.flatten(results)
      })
    }

    getAllGroupsMap () {
      return Promise.all([this.getYears(), this.getHouses(), this.getUserDefinedGroups(), this.getUserDefinedGroups(), this.getRegistrationGroups(), this.getPreAdmissionGroups(), this.getSubjectClasses()])
        .then((allGroupsAsArrays) => {
          // special case for years, as group service will return "7" instead of "Year 7"
          let reformattedYears = allGroupsAsArrays[0].hwSelect((obj) => {
            return {
              id: obj.id,
              name: 'Year ' + obj.name
            }
          })

          let allGroups = LoDash.unionBy(reformattedYears, allGroupsAsArrays[1], allGroupsAsArrays[2], allGroupsAsArrays[3], allGroupsAsArrays[4], allGroupsAsArrays[5], allGroupsAsArrays[6], (o) => {
            return o.id
          })
          let allGroupsForMap = allGroups.hwSelect(obj => {
            return [obj.id.toLowerCase(), obj.name]
          })

          return new Map(allGroupsForMap)
        })
    }

    _getCacheKeyFromGroups (array) {
      array.sort()
      let md5 = new Hashes.MD5()
      let hash = md5.hex(JSON.stringify(array))
      return hash
    }

    getStudentsInGroups (groups) {
      const path = 'v1/Membership/GroupMembers'
      let cacheKey = `${this._getCacheKeyFromGroups(groups)}${path}`
      const cacheTimeInMinutes = 2
      let cachedResult = sscache.get(cacheKey)
      if (cachedResult) {
        return Promise.resolve(cachedResult)
      } else {
        return this.instance.post(path, groups).then((response) => {
          sscache.set(cacheKey, response.data, cacheTimeInMinutes)
          return response.data
        })
      }
    }
}

export let groupService = new GroupService()
