import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { schoolService } from '../../../../Services/SchoolService'
import { appInsightsService } from '../../../../Services/AppInsightsService'

class LeftNavItemInner extends Component {
  anchorClick (e) {
    let schoolId = schoolService.getSchoolId()
    let innertext = e.target.innerText
      Promise.all([schoolId]).then(function (results) {
        schoolId = results[0]
        appInsightsService.trackEvent('Clicked From ' + innertext, { ActiveSchoolId: schoolId })
      })
      if (this.props.item) {
        e.stopPropagation()
      }
    }
  render () {
    let CurrentBead = function (props) {
      if (props.item.compiledRegex.exec(props.activePage.path)) {
        return <div className="left-nav-li-current" />
      }
      return null
    }

    return (
        <li className="active">
            <div className="li-inner">
                <div className="left-nav-li-hover-bead" />
                <a id="mnu-SIMS Link" href={(this.props.item.useUrl) ? this.props.item.path : '/#' + (this.props.item.directLink || this.props.item.path)} onClick={(e) => this.anchorClick(e)} target= {this.props.item.target}>

                    <CurrentBead {...this.props} />

                    <i className={this.props.item.icon} aria-hidden="true" />
                    <span className="menu-item-parent visible-md-inline visible-lg-inline ng-binding">{this.props.item.title}</span>
                </a>
            </div>
        </li>
    )
  }
}

LeftNavItemInner.propTypes = {
  activePage: PropTypes.object,
  item: PropTypes.object
}

export default LeftNavItemInner
