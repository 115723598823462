import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './loady.css'

class Loady extends Component {
    state ={
      isLoading: false,
      isOpaque: false
    }

    componentWillReceiveProps (nextProps) {
      this.setState({
        isLoading: nextProps.isLoading,
        isOpaque: nextProps.isOpaque
      })
    }

    render () {
      if (!this.state.isLoading) {
        return null
      }
      return (
          <div>
              <div className={this.state.isOpaque === true ? 'loady opaque' : 'loady'} />
              <div className="loady-text">
                  <div className="loady-middle">
                        Loading ...
                  </div>
              </div>
          </div>
      )
    }
}

Loady.defaultProps = {
  isLoading: PropTypes.bool.isRequired,
  isOpaque: PropTypes.bool
}

export default Loady
